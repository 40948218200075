import React from "react";
import "./Acad_main.css";
import Acad1 from "./Acad_1/index";
import Acad2 from "./Acad_2/index";
import Navbar from "../../components/UI/Navbar/navbar";
import Footer from "../../components/UI/Footer";

const Index = () => {
  return (
    <div>
      <Navbar />
      <Acad1 />
      <Acad2 />
      <Footer />
    </div>
  );
};

export default Index;
