import React from "react";
import "./Blog1.css";

const Index = () => {
  return (
    <div className="main_blog_sec1">
      <h1>Welcome To TurkAfrica B2B Blog</h1>
      <p>Your guide to achieving ultimate success.™</p>
    </div>
  );
};

export default Index;
