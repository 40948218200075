import { useState, useEffect, useRef, useContext } from "react";
import "./nav.css";
import logo from "../../../assets/Logo.png";
import logo2 from "../../../assets/Logo2.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Fade as Hamburger } from "hamburger-react";
import { GlobalContext } from "../../../Globalcontext";
import { Button } from "react-bootstrap";
import { Downloadmodal, LogoutModel } from "../Modals";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
const Navbar = () => {
  const {
    FiveModalShow,
    setFiveModalShow,
    lang,
    setLang,
    LogoutModalshow,
    setLogoutModalshow,
  } = useContext(GlobalContext);

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const Userdata = JSON.parse(localStorage.getItem("Userdata"));
  const location = useLocation();
  const navigate = useNavigate();
  const menuref = useRef();
  const [open, setIsOpen] = useState(false);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  // Handle language change and navigate
  const handleLanguageChange = (e) => {
    const selectedLang = e.target.value;
    setLang(selectedLang);
    navigate(selectedLang);
  };

  // Scroll effect
  useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.substring(1);
      const section = document.getElementById(sectionId);
      if (section) {
        const yOffset = -100;
        const y =
          section.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [location]);

  // Update lang based on pathname (only for language routes)
  useEffect(() => {
    const langPaths = [
      "/Turkish",
      "/French",
      "/Portuguese",
      "/Swahili",
      "/Spanish",
    ];
    if (langPaths.includes(location.pathname)) {
      setLang(location.pathname);
    }
  }, [location.pathname]);

  const toggleMenu = () => {
    setIsOpen(!open);
  };

  // Translations for navbar items
  const translations = {
    "/Turkish": {
      about: "Hakkında",
      download: "Uygulamayı İndir",
      investors: "Yatırımcılar",
      contact: "İletişim",
      support: "Destek",
    },
    "/French": {
      about: "À Propos",
      download: "Télécharger l'App",
      investors: "Investisseurs",
      contact: "Contact",
      support: "Support",
    },
    "/Portuguese": {
      about: "Sobre",
      download: "Baixar App",
      investors: "Investidores",
      contact: "Contato",
      support: "Suporte",
    },
    "/Swahili": {
      about: "Kuhusu",
      download: "Pakua Programu",
      investors: "Wawekezaji",
      contact: "Wasiliana",
      support: "Msaada",
    },
    "/Spanish": {
      about: "Acerca de",
      download: "Descargar App",
      investors: "Inversionistas",
      contact: "Contacto",
      support: "Apoyo",
    },
    "/": {
      about: "About",
      download: "Download App",
      investors: "Investors",
      contact: "Contact",
      support: "Support",
      Blogs: "Blog",
      Academy: "Academy",
      Login: "Log in",
    },
  };
  const logout = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      id="profileico"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17_4182)">
        <path
          d="M5 22C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V6H18V4H6V20H18V18H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5ZM18 16V13H11V11H18V8L23 12L18 16Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_17_4182">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <div ref={menuref} className="Main_navbar">
      <Downloadmodal
        show={FiveModalShow}
        handelClose={() => setFiveModalShow(false)}
      />
      <LogoutModel
        show={LogoutModalshow}
        handelClose={() => setLogoutModalshow(false)}
      />
      <div className="mainnav">
        <nav id="navbar">
          <NavLink
            to={{
              pathname: lang,
              hash: "#Aboutus",
            }}
            className="logo">
            <img src={logo} alt="Logo" style={{ width: "100%" }} />
          </NavLink>
          <NavLink
            to={{
              pathname: lang,
              hash: "#Aboutus",
            }}
            className="logo2">
            <img src={logo2} alt="" style={{ width: "70%" }} />
          </NavLink>

          <ul className={open ? "navlinks mobilemenu" : "navlinks"}>
            <li>
              <NavLink
                to={{ pathname: lang, hash: "#Aboutus" }}
                onClick={toggleMenu}>
                {translations[lang]?.about}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={{ pathname: lang, hash: "#Download" }}
                onClick={toggleMenu}>
                {translations[lang]?.download}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={{ pathname: lang, hash: "#Investors" }}
                onClick={toggleMenu}>
                {translations[lang]?.investors}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={{ pathname: lang, hash: "#Contact" }}
                onClick={toggleMenu}>
                {translations[lang]?.contact}
              </NavLink>
            </li>
            <li>
              <NavLink to="/Support" onClick={toggleMenu}>
                {translations["/"]?.support}
              </NavLink>
            </li>
            <li>
              <NavLink to="/Blog" onClick={toggleMenu}>
                {translations["/"]?.Blogs}
              </NavLink>
            </li>
            <li>
              <NavLink to="/Academy" onClick={toggleMenu}>
                {translations["/"]?.Academy}
              </NavLink>
            </li>
            <select
              name="Lang"
              id="Lang"
              value={lang}
              onChange={handleLanguageChange}>
              <option value="/">English</option>
              <option value="/Turkish">Turkish</option>
              <option value="/French">French</option>
              <option value="/Portuguese">Portuguese</option>
              <option value="/Spanish">Spanish</option>
              <option value="/Swahili">Swahili</option>
            </select>
            {Userdata ? (
              ""
            ) : (
              <li>
                <button className="loginbtn">
                  <NavLink to="/Login" onClick={toggleMenu}>
                    {translations["/"]?.Login}
                  </NavLink>
                </button>
              </li>
            )}
          </ul>
          {Userdata ? (
            <MDBDropdown group>
              <MDBDropdownToggle className="main_dropdown_div">
                <div className="profile_pic_box">
                  <img src={Userdata.detail.image} alt="" />
                </div>
                <span className="USer_name">
                  {Userdata.detail.firstname + Userdata.detail.lastname}
                </span>
              </MDBDropdownToggle>
              <MDBDropdownMenu>
                <MDBDropdownItem link>
                  <span className="inner_item" onClick={setLogoutModalshow}>
                    {logout}
                    Log out
                  </span>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          ) : (
            ""
          )}
          {/* <button
            onClick={() => {
              setFiveModalShow(true);
              console.log(FiveModalShow, "testing");
            }}
            className="loginbtn">
            clcik
          </button> */}

          <div className="menubtn">
            <Hamburger onToggle={toggleMenu} toggled={open} />
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
