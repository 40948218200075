import * as Yup from "yup";

export const ContactusSchemas = Yup.object().shape({
  FullName: Yup.string().required("Please Enter Your Full Name"),
  LastName: Yup.string().required("Please Enter Your Last Name"),
  Email: Yup.string().required("Please Enter Your Email"),
  Number: Yup.string().required("Please Enter Your Mobile Number"),
  Business: Yup.string().required("Please Enter Your Business name"),
  Position: Yup.string().required(
    "Please Enter Your Position in the Organisation"
  ),
  Country: Yup.string().required("Please Enter Your Country Name"),
  Message: Yup.string().required("Please Enter Your Message"),
});
export const ContactusTASchemas = Yup.object().shape({
  FullName: Yup.string().required("Lütfen Tam Adınızı Giriniz"),
  LastName: Yup.string().required(" Lütfen Soyadınızı Giriniz"),
  Email: Yup.string().required("Lütfen E-posta Adresinizi Giriniz"),
  Number: Yup.string().required("Lütfen Telefon Numaranızı Giriniz"),
  Business: Yup.string().required("Lütfen İşletme Adınızı Giriniz"),
  Position: Yup.string().required(
    "Lütfen Organizasyondaki Pozisyonunuzu Girin"
  ),
  Country: Yup.string().required("Lütfen Ülke Adınızı Girin"),
  Message: Yup.string().required("Lütfen Mesajınızı Giriniz"),
});
export const ContactusFRSchemas = Yup.object().shape({
  FullName: Yup.string().required("Veuillez saisir votre nom complet"),
  LastName: Yup.string().required("Veuillez saisir votre nom de famille"),
  Email: Yup.string().required("Veuillez saisir votre adresse e-mail"),
  Number: Yup.string().required("Veuillez saisir votre numéro de téléphone"),
  Business: Yup.string().required("Veuillez saisir le nom de votre entreprise"),
  Position: Yup.string().required(
    "Veuillez entrer votre poste dans l'organisation"
  ),
  Country: Yup.string().required("Veuillez entrer le nom de votre pays"),
  Message: Yup.string().required("Veuillez saisir votre message"),
});
export const ContactusPTSchemas = Yup.object().shape({
  FullName: Yup.string().required("Por favor, insira o seu nome completo"),
  LastName: Yup.string().required("Por favor, insira o seu sobrenome"),
  Email: Yup.string().required("Por favor, insira o seu e-mail"),
  Number: Yup.string().required("Por favor, insira o seu número de telefone"),
  Business: Yup.string().required("Por favor, insira o nome da sua empresa"),
  Message: Yup.string().required("Por favor, insira a sua mensagem"),
  Position: Yup.string().required(
    "Por favor, insira sua posição na organização"
  ),
  Country: Yup.string().required("Por favor, insira o nome do seu país"),
});
export const ContactusSPSchemas = Yup.object().shape({
  FullName: Yup.string().required("Por favor, introduzca su nombre completo"),
  LastName: Yup.string().required("Por favor, introduzca su apellido"),
  Email: Yup.string().required("Por favor, introduzca su correo electrónico"),
  Number: Yup.string().required("Por favor, introduzca su número de teléfono"),
  Business: Yup.string().required(
    "Por favor, introduzca el nombre de su empresa"
  ),
  Message: Yup.string().required("Por favor, introduzca su mensaje"),
  Position: Yup.string().required(
    "Por favor, introduzca su posición en la organización"
  ),
  Country: Yup.string().required("Por favor, introduzca el nombre de su país"),
});

export const ContactusSWSchemas = Yup.object().shape({
  FullName: Yup.string().required("Tafadhali weka jina lako kamili"),
  LastName: Yup.string().required("Tafadhali weka jina lako la mwisho"),
  Email: Yup.string().required("Tafadhali weka anwani yako ya barua pepe"),
  Number: Yup.string().required("Tafadhali weka namba yako ya simu"),
  Business: Yup.string().required("Tafadhali weka jina la biashara yako"),
  Message: Yup.string().required("Tafadhali weka ujumbe wako"),
  Position: Yup.string().required("Tafadhali Weka Nafasi Yako Katika Shirika"),
  Country: Yup.string().required("Tafadhali Weka Jina la Nchi Yako"),
});
// ? supports
export const SupportSchemas = Yup.object().shape({
  Subject: Yup.string().required("Please select a subject"),
  FullName: Yup.string().required("Please Enter Your Full Name"),
  LastName: Yup.string().required("Please Enter Your Last Name"),
  Email: Yup.string().required("Please Enter Your Email"),
  Number: Yup.string().required("Please Enter Your Mobile Number"),
  Business: Yup.string().required("Please Enter Your Business name"),
  Position: Yup.string().required(
    "Please Enter Your Position in the Organisation"
  ),
  Country: Yup.string().required("Please Enter Your Country Name"),
  Message: Yup.string().required("Please Enter Your Message"),
});

export const LoginSchemas = Yup.object().shape({
  Email: Yup.string()
    .email("Invalid email address, please add @ or .com")
    .required("Please Enter Your Email"),
  Password: Yup.string().required("Please Enter Your Password"),
});
