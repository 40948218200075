import React, { useState, useEffect, useContext } from "react";
import "./Blog_2.css";
import blog2img from "../../../assets/Blog_sec2.png";
// import { format, subYears } from "date-fns";
import BlogData from "./BlogData";
import { NavLink } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import
{
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import axios from "../../../comman/API/index";
import { Backdrop, CircularProgress } from "@mui/material";
import { GlobalContext } from "../../../Globalcontext";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Index = () =>
{
  const { lang, setLang, setFiveModalShow } = useContext(GlobalContext);

  const navigate = useNavigate();

  const [dates, setDates] = useState([]);
  const [loading, setloading] = useState(false);
  // const [lang, setlang] = useState();

  function truncateDescription(description)
  {
    const words = description?.split(" ");
    return words.length > 12 ? words.slice(0, 12).join(" ") + "..." : description;
  }
  function truncateTitle(title)
  {
    const titleWords = title?.split(" ");
    return titleWords.length > 8 ? titleWords.slice(0, 8).join(" ") + "..." : title;
  }

  const RightArrow = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_549_116)">
        <path
          d="M21.1675 5.58965L29.4673 13.8192L29.5367 13.8836C29.8144 14.159 29.976 14.5221 30 14.9512L29.9986 15.0955C29.9785 15.4354 29.8486 15.7601 29.5926 16.0511L29.4957 16.1511L21.1675 24.4104C20.5516 25.0211 19.5557 25.0211 18.9398 24.4104C18.3202 23.796 18.3202 22.7971 18.9398 22.1827L24.6902 16.4794L1.57853 16.48C0.708208 16.48 0 15.7777 0 14.9081C0 14.0384 0.708216 13.3361 1.57851 13.3361L24.5046 13.3356L18.9398 7.81737C18.3202 7.20297 18.3202 6.20405 18.9398 5.58965C19.5557 4.9789 20.5516 4.9789 21.1675 5.58965Z"
          fill="#E30A17"
        />
      </g>
      <defs>
        <clipPath id="clip0_549_116">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const LeftArrow = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_549_109)">
        <path
          d="M8.83253 5.58965L0.532715 13.8192L0.463328 13.8836C0.18562 14.159 0.0240463 14.5221 -1.07288e-06 14.9512L0.00137222 15.0955C0.021518 15.4354 0.151422 15.7601 0.407409 16.0511L0.504262 16.1511L8.83253 24.4104C9.44843 25.0211 10.4443 25.0211 11.0602 24.4104C11.6798 23.796 11.6798 22.7971 11.0602 22.1827L5.30979 16.4794L28.4215 16.48C29.2918 16.48 30 15.7777 30 14.9081C30 14.0384 29.2918 13.3361 28.4215 13.3361L5.49536 13.3356L11.0602 7.81737C11.6798 7.20297 11.6798 6.20405 11.0602 5.58965C10.4443 4.9789 9.44843 4.9789 8.83253 5.58965Z"
          fill="#E30A17"
        />
      </g>
      <defs>
        <clipPath id="clip0_549_109">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  // const pathname = useLocation();
  // useEffect(() => {
  //   setlang(pathname.pathname);
  // }, [pathname]);
  const Subscriptioncheck = JSON.parse(
    localStorage.getItem("checkSubscription")
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [BloglistData, setBloglistData] = useState([]);
  const [filterdata, setfilterdata] = useState("Random");

  const [Blogdata, setBlogData] = useState();
  // const handlePageClick = (pageNumber) => () =>
  // {
  //   setActivePage(pageNumber);
  // };
  const handleckecked = (e) =>
  {
    const value = e.target.value;
    console.log("🚀 ~ value:", value)
    setfilterdata(value)

  }
  const handleInputChange = (e) =>
  {
    const value = e.target.value;
    setSearchTerm(value);
  };
  const ico = (
    <svg
      width="18"
      height="18"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z"
        fill="#fff"
      />
    </svg>
  );
  const blogList = async (search) =>
  {
    setloading(true);
    try {
      const Respons = await axios.post("/blog/get-for-web", {
        page: activePage.toString(),
        keyword: search,
        type: "1",
        filter_by: filterdata,
        language:
          lang === "/Turkish"
            ? "Turkish"
            : lang === "/French"
              ? "French"
              : lang === "/Portuguese"
                ? "Portuguese"
                : lang === "/Swahili"
                  ? "Swahili"
                  : lang === "/Spanish"
                    ? "Spanish"
                    : "English",
      });
      console.log("🚀 ~ blogList ~ Respons:", Respons);
      setloading(false);
      setBloglistData(Respons?.data?.data);
      setBlogData(Respons?.data);
    } catch (error) {
      console.log("🚀 ~ blogList ~ error:", error);
      setloading(false);
    }
  };
  useEffect(() =>
  {
    blogList();
  }, []);
  useEffect(() =>
  {
    blogList();
  }, [activePage, filterdata]);
  useEffect(() =>
  {
    if (searchTerm.length > 2 || searchTerm.length === 0) {
      const delayDebounceFn = setTimeout(() =>
      {
        blogList(searchTerm);
      });

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchTerm]);

  // ?
  const [startPage, setStartPage] = useState(1);
  const pageWindowSize = 5;

  const handlePageClick = (pageNumber) => (e) =>
  {
    e.preventDefault();
    if (pageNumber < 1 || pageNumber > Blogdata?.totalPages) return;

    setActivePage(pageNumber);

    // Adjust the start page of the pagination window
    if (pageNumber > startPage + pageWindowSize - 1) {
      setStartPage(startPage + 1);
    } else if (pageNumber < startPage) {
      setStartPage(startPage - 1);
    }
  };

  const pagesToShow = Array.from(
    { length: Math.min(pageWindowSize, Blogdata?.totalPages - startPage + 1) },
    (_, index) => startPage + index
  );
  // ?

  return (
    <>
      <div className="main_blog_sec2_div">
        <div className="sub_main_blog_sec2_div">
          <div className="heding_div">
            <div className="heding_text">
              <h1>Blog List</h1>
            </div>
            <div className="div_switch_button">
              <div className="radio_input">
                <div className="tooltip-container" style={{ width: "auto" }}>
                  <span className="tooltip">Randomly listed articles.</span>
                  <label>
                    <input type="radio" id="value-1" name="value-radio" value="Random" onChange={handleckecked} />
                    <span className="text">✿</span>
                  </label>
                </div>
                <div className="tooltip-container" style={{ width: "auto" }}>
                  <span className="tooltip">Listed in accordance with the latest published articles</span>
                  <label>
                    <input type="radio" id="value-2" name="value-radio" value="Byid" onChange={handleckecked} />
                    <span>▲</span>
                  </label>
                </div>

              </div>
            </div>
            <div className="search_main_div">
              <div className="input_box">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleInputChange}
                />
              </div>
              <NavLink>{ico}</NavLink>
            </div>
          </div>
          <div className="blog_box_of_sec2">
            {BloglistData?.map((item, index) =>
            {
              return (
                <div className="blog_no1_box" key={index}>
                  <div className="main_img_div_of_blogsec2">
                    <div className="image_box_of_blog_div_sec2">
                      <LazyLoadImage
                        src={item?.image} // the source of the image
                        alt={item?.title} // alternative text for the image
                        effect="blur" // effect to use before the image loads (optional)
                        height="100%"
                        width="100%"
                        className="img_container_academy"
                      />
                    </div>
                    {/* <button className="blog_btn">{item?.blog_title}</button> */}
                  </div>
                  <div className="text_of_blog_no1">
                    <h1>{truncateTitle(item.blog_title)}</h1>
                    <p dangerouslySetInnerHTML={{ __html: truncateDescription(item?.blog_description) }}></p>
                    <button
                      className="btn2"
                      onClick={() =>
                        Subscriptioncheck?.plan_name === "Premium"
                          ? navigate(`/Blogdetails/${item.blog_id}`)
                          : setFiveModalShow(true)
                      }
                    // onClick={() => navigate(`/Blogdetails/${item.blog_id}`)}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="Pagination_main_div">
            <MDBPagination end className="mb-0">
              <MDBPaginationItem disabled={activePage === 1}>
                <MDBPaginationLink
                  href="#"
                  onClick={handlePageClick(activePage - 1)}
                  aria-disabled="true">
                  {LeftArrow}
                </MDBPaginationLink>
              </MDBPaginationItem>

              {pagesToShow.map((pageNumber) => (
                <MDBPaginationItem
                  key={pageNumber}
                  active={pageNumber === activePage}>
                  <MDBPaginationLink
                    href="#"
                    onClick={handlePageClick(pageNumber)}>
                    {pageNumber}
                  </MDBPaginationLink>
                </MDBPaginationItem>
              ))}

              <MDBPaginationItem disabled={activePage === Blogdata?.totalPages}>
                <MDBPaginationLink
                  href="#"
                  onClick={handlePageClick(activePage + 1)}>
                  {RightArrow}
                </MDBPaginationLink>
              </MDBPaginationItem>
            </MDBPagination>
          </div>

        </div>
      </div>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}>
            {/* <CircularProgress color="inherit" /> */}
            <span class="loader"></span>
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Index;
