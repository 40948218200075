import React, { useState, useEffect, useContext } from "react";
import "./BlogDetails.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Navbar from "../../components/UI/Navbar/navbar";
import Footer from "../../components/UI/Footer/index";
import axios from "../../comman/API/index";
import { Backdrop, CircularProgress } from "@mui/material";
import { GlobalContext } from "../../Globalcontext";
const Index = () => {
  const { lang, setLang } = useContext(GlobalContext);
  const { blogId } = useParams();
  console.log("🚀 ~ Index ~ blogId:", blogId);
  const [loading, setloading] = useState(false);
  const [blogDetailsdata, setblogDetailsdata] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const Blogitem = location.state;
  const blodDetails = async () => {
    setloading(true);
    try {
      const response = await axios.post("blog/get-detail", {
        type: "1",
        language:
          lang === "/Turkish"
            ? "Turkish"
            : lang === "/French"
            ? "French"
            : lang === "/Portuguese"
            ? "Portuguese"
            : lang === "/Swahili"
            ? "Swahili"
            : lang === "/Spanish"
            ? "Spanish"
            : "English",
        id: blogId,
      });
      setloading(false);
      // console.log("🚀 ~ blodDetails ~ response:", response);
      setblogDetailsdata(response?.data?.data);
    } catch (error) {
      setloading(false);
      console.log("🚀 ~ blodDetails ~ error:", error);
    }
  };
  useEffect(() => {
    blodDetails();
  }, []);
  const Userdata = JSON.parse(localStorage.getItem("Userdata"));
  return (
    <>
      <Navbar />
      <>
        {!Userdata ? (
          <div className="main_ditails_of_blog_no_data">
            <div className="sub_main_div_of_blog_no_data">
              <h1>
                Welcome! Please log in to access your personalized experience
              </h1>
              <button
                className="loginbtn_no_data"
                onClick={() => navigate("/Login")}>
                Login
              </button>
            </div>
          </div>
        ) : (
          <div className="main_ditails_of_blog">
            <div className="sub_main_div_of_blog">
              <div className="main_heding_div">
                <div className="backbtn_div" onClick={() => navigate(-1)}>
                  <span>&larr;</span>
                </div>
                <h1>{blogDetailsdata?.blog_title}</h1>
              </div>
              <div className="main_image_div">
                <div className="image_box_blog">
                  <img src={blogDetailsdata?.image} alt="" />
                </div>
              </div>

              <div className="main_text_div">
                <p
                  style={{ whiteSpace: "pre-wrap" }}
                  dangerouslySetInnerHTML={{
                    __html: blogDetailsdata?.blog_description,
                  }}></p>
              </div>
            </div>
          </div>
        )}
        {!loading && <div></div>}
        {loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}>
              {/* <CircularProgress color="inherit" /> */}
              <span class="loader"></span>
            </Backdrop>
          </div>
        )}
      </>
      <Footer />
    </>
  );
};

export default Index;
