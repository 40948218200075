import React, { useState, useContext, useEffect } from "react";
import "./Login.css";
import { toast, Toaster } from "react-hot-toast";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import { LoginSchemas } from "../../schemas/index";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Logo.png";
import { NavLink } from "react-router-dom";
import { GlobalContext } from "../../Globalcontext";
import axios from "../../comman/API/index";
import { Backdrop, CircularProgress } from "@mui/material";
import { auth, GoogleProvider } from "../../comman/Firebase/firebase";
import { signInWithPopup } from "firebase/auth";
import { Downloadmodal } from "../../components/UI/Modals";
const Index = () => {
  const { FiveModalShow, setFiveModalShow } =
    useContext(GlobalContext);
  const initialValues = {
    Email: "",
    Password: "",
  };
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordShown((passwordShown) => !passwordShown);
  };
  const MYDeviceToken = JSON.parse(localStorage.getItem("DeviceToken"));
  const GetMYtoken = async () => {
    try {
      const Response = await axios.post("/auth/getToken", {
        device_id: MYDeviceToken,
        device_type: "A",
      });
      localStorage.setItem("MYToken", JSON.stringify(Response.data.data.token));
    } catch (error) {
      console.log("🚀 ~ GetMYtoken ~ error:", error);
    }
  };
  useEffect(() => {
    GetMYtoken();
  }, []);

  const MYToken = JSON.parse(localStorage.getItem("MYToken"));

  const checkSubscription = async () => {
    try {
      const respons = await axios.get("user/checkSubscribe", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${MYToken}`,
        },
      });
      console.log("🚀 ~ checkSubscription ~ respons:", respons);
      localStorage.setItem(
        "checkSubscription",
        JSON.stringify(respons.data.data)
      );
    } catch (error) {
      console.log("🚀 ~ checkSubscription ~ error:", error);
    }
  };

  const onSubmit = async (values, { resetForm }) => {
    setloading(true);
    try {
      const Response = await axios.post(
        "auth/signIn",
        { email: values.Email, password: values.Password },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MYToken}`,
          },
        }
      );
      setloading(false);
      if (Response?.data?.status === false) {
        toast.error(Response?.data?.message);
        setFiveModalShow(true)
      }
      if (Response?.data?.status === true) {
        toast.success(Response?.data?.message);
        resetForm();
        navigate("/");
        checkSubscription();
        localStorage.setItem("Userdata", JSON.stringify(Response.data.data));
      }
      console.log("🚀 ~ onSubmit ~ Response:", Response);
      setloading(false);
    } catch (error) {
      setloading(false);
      setFiveModalShow(true)
      toast.error(error?.response?.data?.message);
      console.log("🚀 ~ onSubmit ~ error:", error);
    }
  };
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: LoginSchemas,
    onSubmit,
  });

  const Googleicon = (
    <svg
      width="75"
      height="75"
      viewBox="0 0 109 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_594_102)">
        <rect x="4.43262" y="4" width="100" height="100" rx="50" fill="white" />
        <path
          d="M91.653 55.2549C91.653 52.6454 91.4392 50.025 90.9897 47.4594H54.5884V62.2337H75.4314C75.0014 64.598 74.0961 66.8504 72.7702 68.8547C71.4444 70.859 69.7257 72.5734 67.7181 73.8941V83.4879H80.157C87.4592 76.7997 91.653 66.9318 91.653 55.2549Z"
          fill="#4285F4"
        />
        <path
          d="M54.5934 92.7472C64.9985 92.7472 73.7753 89.3483 80.1675 83.4824L67.734 73.8942C64.2748 76.2351 59.8069 77.5617 54.6043 77.5617C44.5392 77.5617 36.0035 70.8078 32.939 61.7294H20.1108V71.6136C23.3431 77.9796 28.2769 83.3249 34.3642 87.0557C40.4515 90.7864 47.4538 92.7566 54.5934 92.7472Z"
          fill="#34A853"
        />
        <path
          d="M32.9226 61.7293C31.306 56.9653 31.306 51.8012 32.9226 47.0372V37.153H20.1054C17.4062 42.4956 16 48.3975 16 54.3833C16 60.369 17.4062 66.271 20.1054 71.6136L32.9226 61.7293Z"
          fill="#FBBC04"
        />
        <path
          d="M54.5934 31.1829C60.0899 31.0938 65.4029 33.16 69.3951 36.9391L80.4142 25.9804C73.4111 19.457 64.1633 15.8835 54.5934 16.0029C47.4519 15.9938 40.4478 17.9659 34.3601 21.6998C28.2725 25.4337 23.3398 30.7831 20.1108 37.1529L32.9171 47.0262C35.9651 37.9369 44.5172 31.1829 54.5824 31.1829H54.5934Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_594_102"
          x="0.432617"
          y="0"
          width="108"
          height="108"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_594_102"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_594_102"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );

  const handleGoogleLogin = async () => {
    setloading(true);
    try {
      const result = await signInWithPopup(auth, GoogleProvider);
      const user = result.user;

      // Extract the necessary Google user ID
      const googleId = user.providerData.find(
        (provider) => provider.providerId === "google.com"
      ).uid;

      const apiResponse = await axios.post("auth/websocialLogin", {
        social_type: "Google",
        social_id: googleId,
      });

      if (apiResponse.status === 200) {
        navigate("/");
        toast.success("Logged in successfully!");
      } else {
        throw new Error("Failed to authenticate with our server.");
      }
    } catch (error) {
      console.error("Login failed:", error);
      if (error?.response?.status === 401) {
        setFiveModalShow(true)
        toast.error("user not found");
      }
    } finally {
      setloading(false);
    }
  };

  return (
    <>
      <Downloadmodal
        show={FiveModalShow}
        handelClose={() => setFiveModalShow(false)}
      />
      <Toaster position="top-right" reverseOrder={false} />
      <div className="main_login_page_div">
        <div className="back_btn_div_auth" onClick={() => navigate(-1)}>
          <span>&larr;</span>
        </div>
        <div className="sub_main_div_of_login">
          <div className="main_login_card">
            <div className="main_form_div">
              <div className="div_of_logo" onClick={() => navigate("/")}>
                <img src={logo} alt="" loading="lazy" />
                <p>Please! Log in with your App Credentials</p>
              </div>
              <form onSubmit={handleSubmit} className="login_form">
                <div className="Email_box_login">
                  <label htmlFor="email">Email</label>
                  <div className="input_div_login">
                    <input
                      type="email"
                      id="email"
                      name="Email"
                      value={values.Email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.Email && touched.Email ? (
                    <p className="errors_msg_login">{errors.Email}</p>
                  ) : null}
                </div>
                <div className="Email_box_login">
                  <label htmlFor="Password">Password</label>
                  <div className="input_div_pass">
                    <input
                      type={passwordShown ? "text" : "password"}
                      id="Password"
                      name="Password"
                      value={values.Password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <i
                      onClick={togglePasswordVisibility}
                      style={{ cursor: "pointer" }}>
                      <FontAwesomeIcon
                        icon={passwordShown ? faEyeSlash : faEye}
                        style={{ color: "white" }}
                      />
                    </i>
                  </div>
                  {errors.Password && touched.Password ? (
                    <p className="errors_msg_login">{errors.Password}</p>
                  ) : null}
                </div>
                <div className="div_of_button_">
                  <button
                    type="submit"
                    className="btn_primry"
                    style={{ textAlign: "center" }}>
                    Login
                  </button>
                  {/* <div
                    className="div_forgot_password"
                    onClick={() => setFiveModalShow(true)}>
                    <p>Forgot password?</p>
                  </div> */}
                </div>
              </form>
            </div>
            {/* <div className="Social_login_btn">
              <h5>OR Login With</h5>
              <span onClick={() => handleGoogleLogin()}>{Googleicon}</span>
            </div> */}
          </div>
        </div>
      </div>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}>
            {/* <CircularProgress color="inherit" /> */}
            <span class="loader"></span>
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Index;
