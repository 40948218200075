import { useState, useContext, useEffect } from "react";
import "./modal.css";
import { GlobalContext } from "../../../Globalcontext";
import { Link } from "react-scroll";
import Button from "react-bootstrap/Button";

import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

import QRcode from "../../../assets/download/Qrcode.png";
import applelogo from "../../../assets/Apple_store.png";
import playlogo from "../../../assets/G_play.png";
export const Termsofusemodel = () => {
  const { FirstModalShow, setFirstModalShow } = useContext(GlobalContext);
  const [lang, setlang] = useState();
  const pathname = useLocation();
  useEffect(() => {
    setlang(pathname.pathname);
  }, [pathname]);
  return (
    <Modal show={FirstModalShow} onHide={setFirstModalShow} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="newtitle">
          <h3 style={{ textAlign: "center" }}>
            {lang == "/Turkish"
              ? "TürkAfrica.Market Kullanım Koşulları"
              : lang == "/French"
              ? "Conditions d'Utilisation de TürkAfrica.Market"
              : lang == "/Portuguese"
              ? "Termos de Uso da TürkAfrica.Market"
              : lang == "/Swahili"
              ? "Vigezo vya Matumizi ya TürkAfrica.Market"
              : lang == "/Spanish"
              ? "Términos de Uso de TürkAfrica.Market"
              : "TürkAfrica.Market Terms of Use"}
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="Modalbody">
        {lang == "/Turkish" ? (
          <>
            <div className="sub_title_heading">
              <h6>22 Aralık 2023 tarihinde güncellendi</h6>
            </div>
            <div className="text_div">
              <h1>1. Giriş</h1>
              <p>
                TürkAfrica.Market'a hoş geldiniz. Bu Kullanım Koşulları, B2B ve
                B2C ağ oluşturma ve e-ticaret platformumuzu kullanımınızı ve
                erişiminizi yönetir. Hizmetlerimizi kullanarak veya erişerek, bu
                şartları kabul etmiş olursunuz.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Uygunluk </h1>
              <p>
                Platformumuzu kullanmak için: <br />
                En az 18 yaşında olmalısınız. <br />
                Bağlayıcı sözleşmeler yapma yasal kapasitesine sahip
                olmalısınız.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Hesap Kaydı</h1>
              <p>
                Hesap Oluşturma: Kullanıcılar doğru ve eksiksiz bilgi
                sağlamalıdır. Hesap Sorumlulukları: Kullanıcılar, hesaplarının
                ve şifrelerinin gizliliğini korumak ve hesapları altında
                gerçekleşen tüm aktivitelerden sorumlu olmalıdır.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Hizmetlerin Kullanımı</h1>
              <p>
                Uyumluluk: Kullanıcılar, tüm geçerli yasalara, bu sözleşmenin
                şartlarına ve tüm platform politikalarına uymalıdır. <br />
                Yasaklanmış Davranışlar: Kullanıcılar hizmetlerimizi kötüye
                kullanmamalıdır, bu, sahtekarlık faaliyetlerine katılmayı, fikri
                mülkiyeti ihlal etmeyi veya zararlı veya yasa dışı içerik
                yaymayı içerir.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Fikri Mülkiyet</h1>
              <p>
                Sahiplik: TürkAfrica.Market'teki tüm içerikler, metinler,
                grafikler, logolar ve yazılımlar TürkAfrica.Market'in veya
                lisans verenlerinin mülkiyetindedir ve İngiltere ve uluslararası
                fikri mülkiyet yasaları tarafından korunmaktadır. <br />
                İçeriğin Kullanımı: Kullanıcılar, platformumuzdan herhangi bir
                içeriği önceden yazılı izin olmadan kullanamaz.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Üçüncü Taraf Bağlantılar ve Hizmetler</h1>
              <p>
                Dış Bağlantılar: Platformumuz, üçüncü taraf web sitelerine veya
                hizmetlerine bağlantılar içerebilir. Bu sitelerin içeriği,
                gizlilik politikaları veya uygulamalarından sorumlu değiliz.
                <br />
                Üçüncü Taraf Hizmetleri: Platformumuz aracılığıyla üçüncü taraf
                hizmetlerinin kullanımı tamamen kullanıcı ve üçüncü taraf
                arasındadır.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Sonlandırma</h1>
              <p>
                Hesap Sonlandırma: Herhangi bir Kullanım Koşullarını ihlal
                durumunda, önceden haber vermeden veya sorumluluk almadan
                platforma erişimi derhal sonlandırabilir veya askıya alabiliriz.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Feragatnameler ve Sorumluluk Sınırlaması</h1>
              <p>
                Garantiler Yok: Hizmetlerimiz "olduğu gibi" herhangi bir garanti
                olmaksızın sunulmaktadır. Sorumluluk Sınırlaması:
                TürkAfrica.Market, hizmetlerimizin kullanımından kaynaklanan
                dolaylı, tesadüfi, özel, sonuçsal veya cezai zararlardan sorumlu
                olmayacaktır.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Tazminat</h1>
              <p>
                Kullanıcılar, platformumuzun kullanımından kaynaklanan herhangi
                bir iddia, zarar, kayıp, sorumluluk ve masraftan
                TürkAfrica.Market'i, yetkililerini, yöneticilerini,
                çalışanlarını ve acentelerini tazmin etmeyi ve zararsız tutmayı
                kabul eder.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Yönetmelik Hukuku</h1>
              <p>
                Bu Koşullar, Birleşik Krallık yasalarına göre yönetilecek ve
                yorumlanacaktır.
              </p>
            </div>
            <div className="text_div">
              <h1>11.Kullanım Koşullarındaki Değişiklikler</h1>
              <p>
                Bu şartları herhangi bir zamanda değiştirme hakkını saklı
                tutarız. Bu değişiklikler hakkında, platformda revize edilmiş
                şartları yayınlayarak bildirimde bulunacağız.
              </p>
            </div>
            <div className="text_div">
              <h1>12. İletişim Bilgileri</h1>
              <p>
                Bu Kullanım Koşulları hakkında herhangi bir sorunuz varsa,
                lütfen bize [
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setFirstModalShow(false)}>
                  Buraya Tıklayın
                </Link>
                ] adresinden ulaşın.
              </p>
            </div>
          </>
        ) : lang == "/French" ? (
          <>
            <div className="sub_title_heading">
              <h6>Mis à jour le 22 décembre 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                Bienvenue sur TürkAfrica.Market. Ces Conditions d'Utilisation
                régissent votre accès et utilisation de notre plateforme de
                réseau et de commerce électronique B2B et B2C. En accédant ou en
                utilisant nos services, vous acceptez ces termes.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Admissibilité </h1>
              <p>
                Pour utiliser notre plateforme, vous devez : <br />
                Avoir au moins 18 ans. <br />
                Avoir la capacité juridique de conclure des contrats
                contraignants.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Inscription du Compte</h1>
              <p>
                Création de Compte : Les utilisateurs doivent fournir des
                informations exactes et complètes. <br />
                Responsabilités du Compte : Les utilisateurs sont responsables
                de la confidentialité de leur compte et mot de passe et de
                toutes les activités qui se produisent sous leur compte.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Utilisation des Services</h1>
              <p>
                Conformité : Les utilisateurs doivent se conformer à toutes les
                lois applicables, aux termes de cet accord et à toutes les
                politiques de la plateforme. <br />
                Conduite Interdite : Les utilisateurs ne doivent pas abuser de
                nos services, y compris en se livrant à des activités
                frauduleuses, en portant atteinte à la propriété intellectuelle,
                ou en diffusant du contenu nuisible ou illégal.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Propriété Intellectuelle</h1>
              <p>
                Propriété : Tout le contenu sur TürkAfrica.Market, y compris les
                textes, graphiques, logos et logiciels, est la propriété de
                TürkAfrica.Market ou de ses concédants et est protégé par les
                lois britanniques et internationales sur la propriété
                intellectuelle. <br />
                Utilisation du Contenu : Les utilisateurs ne peuvent pas
                utiliser de contenu de notre plateforme sans autorisation écrite
                préalable.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Liens et Services Tiers</h1>
              <p>
                Liens Externes : Notre plateforme peut contenir des liens vers
                des sites Web ou services tiers. Nous ne sommes pas responsables
                du contenu, des politiques de confidentialité ou des pratiques
                de ces sites.
                <br />
                Services Tiers : Toute utilisation de services tiers via notre
                plateforme se fait uniquement entre l'utilisateur et le tiers.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Résiliation</h1>
              <p>
                • Résiliation du Compte : Nous pouvons résilier ou suspendre
                l'accès à notre plateforme immédiatement, sans préavis ni
                responsabilité, pour toute violation de ces Conditions.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Avertissements et Limitation de Responsabilité</h1>
              <p>
                Aucune Garantie : Nos services sont fournis "tels quels" sans
                aucune garantie, expresse ou implicite. <br />
                Limitation de Responsabilité : TürkAfrica.Market ne sera pas
                responsable des dommages indirects, accessoires, spéciaux,
                consécutifs ou punitifs résultant de l'utilisation de nos
                services.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Indemnisation</h1>
              <p>
                Les utilisateurs acceptent d'indemniser et de dégager de toute
                responsabilité TürkAfrica.Market, ses dirigeants, directeurs,
                employés et agents contre toute réclamation, dommage, perte,
                responsabilité et dépense découlant de leur utilisation de la
                plateforme.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Loi Applicable</h1>
              <p>
                Ces Conditions seront régies et interprétées conformément aux
                lois du Royaume-Uni.
              </p>
            </div>
            <div className="text_div">
              <h1>11. Modifications des Conditions d'Utilisation</h1>
              <p>
                Nous nous réservons le droit de modifier ces termes à tout
                moment. Nous fournirons un avis de ces changements en publiant
                les termes révisés sur la plateforme.
              </p>
            </div>
            <div className="text_div">
              <h1>12. Coordonnées</h1>
              <p>
                Pour toute question concernant ces Conditions d'Utilisation,
                veuillez nous contacter à [
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setFirstModalShow(false)}>
                  Cliquez Ici
                </Link>
                ].
              </p>
            </div>
          </>
        ) : lang == "/Portuguese" ? (
          <>
            <div className="sub_title_heading">
              <h6>Atualizado em 22 de dezembro de 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introdução</h1>
              <p>
                Bem-vindo à TürkAfrica.Market. Estes Termos de Uso regem seu
                acesso e uso de nossa plataforma de networking e comércio
                eletrônico B2B e B2C. Ao acessar ou usar nossos serviços, você
                concorda com estes termos.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Elegibilidade </h1>
              <p>
                Para usar nossa plataforma, você deve: <br />
                Ser maior de 18 anos. <br />
                Ter capacidade legal para celebrar contratos vinculativos.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Registro de Conta</h1>
              <p>
                Criação de Conta: Os usuários devem fornecer informações
                precisas e completas. Responsabilidades da Conta: Os usuários
                são responsáveis por manter a confidencialidade de sua conta e
                senha e por todas as atividades que ocorrem sob sua conta.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Uso dos Serviços</h1>
              <p>
                Compliance: Users must comply with all applicable laws, the
                terms of this agreement, and all platform policies. <br />
                Prohibited Conduct: Users must not misuse our services,
                including engaging in fraudulent activities, infringing on
                intellectual property, or spreading harmful or illegal content.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Intellectual Property</h1>
              <p>
                Conformidade: Os usuários devem cumprir todas as leis
                aplicáveis, os termos deste acordo e todas as políticas da
                plataforma. <br />
                Conduta Proibida: Os usuários não devem fazer uso indevido de
                nossos serviços, incluindo a participação em atividades
                fraudulentas, violação de propriedade intelectual ou
                disseminação de conteúdo prejudicial ou ilegal.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Propriedade Intelectual</h1>
              <p>
                Propriedade: Todo o conteúdo na TürkAfrica.Market, incluindo
                textos, gráficos, logotipos e software, é propriedade da
                TürkAfrica.Market ou seus licenciadores e é protegido pelas leis
                de propriedade intelectual do Reino Unido e internacionais.
                <br />
                Uso do Conteúdo: Os usuários não podem usar nenhum conteúdo de
                nossa plataforma sem permissão prévia por escrito.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Links e Serviços de Terceiros</h1>
              <p>
                Links Externos: Nossa plataforma pode conter links para sites ou
                serviços de terceiros. Não somos responsáveis pelo conteúdo,
                políticas de privacidade ou práticas desses sites. Serviços de
                Terceiros: Qualquer uso de serviços de terceiros por meio de
                nossa plataforma é exclusivamente entre o usuário e o terceiro.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Rescisão</h1>
              <p>
                Rescisão de Conta: Podemos encerrar ou suspender o acesso à
                nossa plataforma imediatamente, sem aviso prévio ou
                responsabilidade, por qualquer violação destes Termos.
              </p>
            </div>
            <div className="text_div">
              <h1>
                9. Isenções de Responsabilidade e Limitação de Responsabilidade
              </h1>
              <p>
                Sem Garantias: Nossos serviços são fornecidos "como estão" sem
                quaisquer garantias, expressas ou implícitas. Limitação de
                Responsabilidade: A TürkAfrica.Market não será responsável por
                quaisquer danos indiretos, incidentais, especiais,
                consequenciais ou punitivos resultantes do uso de nossos
                serviços.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Indenização</h1>
              <p>
                Os usuários concordam em indenizar e isentar a
                TürkAfrica.Market, seus oficiais, diretores, funcionários e
                agentes de quaisquer reivindicações, danos, perdas,
                responsabilidades e despesas decorrentes de seu uso da
                plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>11. Lei Aplicável</h1>
              <p>
                Estes Termos serão regidos e interpretados de acordo com as leis
                do Reino Unido.
              </p>
            </div>
            <div className="text_div">
              <h1>12. Alterações nos Termos de Uso</h1>
              <p>
                Reservamo-nos o direito de modificar estes termos a qualquer
                momento. Forneceremos aviso dessas alterações publicando os
                termos revisados na plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>13. Informações de Contato</h1>
              <p>
                Para quaisquer dúvidas sobre estes Termos de Uso, entre em
                contato conosco em [{" "}
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setFirstModalShow(false)}>
                  Clique Aqui
                </Link>
                ].
              </p>
            </div>
          </>
        ) : lang == "/Swahili" ? (
          <>
            <div className="sub_title_heading">
              <h6>Imesasishwa tarehe 22 Desemba, 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Utangulizi</h1>
              <p>
                Karibu kwenye TürkAfrica.Market. Vigezo hivi vya Matumizi
                vinadhibiti ufikiaji wako na matumizi ya jukwaa letu la mtandao
                na biashara kati ya biashara (B2B) na kati ya biashara na wateja
                (B2C). Kwa kufikia au kutumia huduma zetu, unakubaliana na
                vigezo hivi.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Kustahiki </h1>
              <p>
                Ili kutumia jukwaa letu, lazima: <br />
                Uwe na umri wa angalau miaka 18. <br />
                Uwe na uwezo wa kisheria kuingia mikataba inayofunga.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Usajili wa Akaunti</h1>
              <p>
                Uundaji wa Akaunti: Watumiaji lazima watoe taarifa sahihi na
                kamili. Majukumu ya Akaunti: Watumiaji wana jukumu la kudumisha
                usiri wa akaunti na nywila zao na kwa shughuli zote zinazotokea
                chini ya akaunti yao.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Matumizi ya Huduma</h1>
              <p>
                Uzingatiaji: Watumiaji lazima wafuate sheria zote husika, vigezo
                vya makubaliano haya, na sera zote za jukwaa. <br />
                Tabia Zilizokatazwa: Watumiaji hawapaswi kutumia huduma zetu
                vibaya, ikiwa ni pamoja na kujihusisha katika shughuli za
                udanganyifu, kukiuka haki miliki, au kusambaza maudhui yenye
                madhara au yasiyo halali
              </p>
            </div>
            <div className="text_div">
              <h1>5.Mali Miliki</h1>
              <p>
                Umiliki: Maudhui yote kwenye TürkAfrica.Market, ikiwa ni pamoja
                na maandishi, michoro, nembo, na programu, ni mali ya
                TürkAfrica.Market au watoa leseni wake na inalindwa na sheria za
                haki miliki za Uingereza na kimataifa. <br />
                Matumizi ya Maudhui: Watumiaji hawawezi kutumia maudhui yoyote
                kutoka kwenye jukwaa letu bila ruhusa iliyoandikwa mapema.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Viungo na Huduma za Tatu</h1>
              <p>
                Viungo vya Nje: Jukwaa letu linaweza kuwa na viungo kwa tovuti
                au huduma za watu wa tatu. Hatuwajibiki kwa maudhui, sera za
                faragha, au mazoea ya tovuti hizi.
                <br />
                Huduma za Tatu: Matumizi yoyote ya huduma za watu wa tatu
                kupitia jukwaa letu ni kati ya mtumiaji na mtu wa tatu pekee.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Kusitisha</h1>
              <p>
                Kusitishwa kwa Akaunti: Tunaweza kusitisha au kusimamisha
                ufikiaji wa jukwaa letu mara moja, bila taarifa ya awali au
                dhima, kwa ukiukaji wowote wa Vigezo hivi.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Kukana na Ukomo wa Dhima</h1>
              <p>
                Hakuna Dhamana: Huduma zetu zinatolewa "kama zilivyo" bila
                dhamana yoyote, iliyotamkwa au kudokezwa. <br />
                Ukomo wa Dhima: TürkAfrica.Market haitawajibika kwa uharibifu
                wowote wa moja kwa moja, wa bahati mbaya, maalum, wa matokeo, au
                wa adhabu unaotokana na matumizi ya huduma zetu.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Kujilinda</h1>
              <p>
                Watumiaji wanakubali kujilinda na kutowajibisha
                TürkAfrica.Market, maafisa wake, wakurugenzi, wafanyakazi, na
                mawakala kutokana na madai yoyote, uharibifu, hasara, madeni, na
                gharama zinazotokana na matumizi yao ya jukwaa.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Sheria Inayotumika</h1>
              <p>
                Vigezo hivi vitatawaliwa na kutafsiriwa kulingana na sheria za
                Uingereza.
              </p>
            </div>
            <div className="text_div">
              <h1>11. Mabadiliko ya Vigezo vya Matumizi</h1>
              <p>
                Tunahifadhi haki ya kurekebisha vigezo hivi wakati wowote.
                Tutatoa taarifa ya mabadiliko haya kwa kuchapisha vigezo
                vilivyorekebishwa kwenye jukwaa.
              </p>
            </div>
            <div className="text_div">
              <h1>12. Taarifa za Mawasiliano</h1>
              <p>
                Kwa maswali yoyote kuhusu Vigezo hivi vya Matumizi, tafadhali
                wasiliana nasi kwa [
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setFirstModalShow(false)}>
                  Bonyeza Hapa
                </Link>
                ].
              </p>
            </div>
          </>
        ) : lang == "/Spanish" ? (
          <>
            <div className="sub_title_heading">
              <h6>Actualizado el 22 de diciembre de 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introducción</h1>
              <p>
                Bienvenido a TürkAfrica.Market. Estos Términos de Uso gobiernan
                su acceso y uso de nuestra plataforma de comercio electrónico y
                redes B2B y B2C. Al acceder o utilizar nuestros servicios, usted
                acepta estos términos.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Elegibilidad </h1>
              <p>
                Para usar nuestra plataforma, debe:
                <br />
                Ser mayor de 18 años. <br />
                Tener la capacidad legal para celebrar contratos vinculantes.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Registro de Cuenta</h1>
              <p>
                Creación de Cuenta: Los usuarios deben proporcionar información
                precisa y completa. Responsabilidades de la Cuenta: Los usuarios
                son responsables de mantener la confidencialidad de su cuenta y
                contraseña y de todas las actividades que ocurran bajo su
                cuenta.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Uso de los Servicios</h1>
              <p>
                Cumplimiento: Los usuarios deben cumplir con todas las leyes
                aplicables, los términos de este acuerdo y todas las políticas
                de la plataforma. <br />
                Conducta Prohibida: Los usuarios no deben hacer un mal uso de
                nuestros servicios, incluyendo participar en actividades
                fraudulentas, infringir la propiedad intelectual, o difundir
                contenido dañino o ilegal.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Propiedad Intelectual</h1>
              <p>
                Propiedad: Todo el contenido en TürkAfrica.Market, incluyendo
                textos, gráficos, logotipos y software, es propiedad de
                TürkAfrica.Market o sus licenciantes y está protegido por las
                leyes de propiedad intelectual del Reino Unido e
                internacionales. <br />
                Uso del Contenido: Los usuarios no pueden usar ningún contenido
                de nuestra plataforma sin permiso previo por escrito.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Enlaces y Servicios de Terceros</h1>
              <p>
                Enlaces Externos: Nuestra plataforma puede contener enlaces a
                sitios web o servicios de terceros. No somos responsables del
                contenido, políticas de privacidad, o prácticas de estos sitios.
                <br />
                Servicios de Terceros: Cualquier uso de servicios de terceros a
                través de nuestra plataforma es exclusivamente entre el usuario
                y el tercero.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Terminación</h1>
              <p>
                Terminación de la Cuenta: Podemos terminar o suspender el acceso
                a nuestra plataforma de inmediato, sin previo aviso ni
                responsabilidad, por cualquier incumplimiento de estos Términos.
              </p>
            </div>
            <div className="text_div">
              <h1>
                8. Descargos de Responsabilidad y Limitación de Responsabilidad
              </h1>
              <p>
                Sin Garantías: Nuestros servicios se proporcionan "tal cual" sin
                ninguna garantía, expresa o implícita. Limitación de
                Responsabilidad: TürkAfrica.Market no será responsable de ningún
                daño indirecto, incidental, especial, consecuente o punitivo
                resultante del uso de nuestros servicios.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Indemnización</h1>
              <p>
                Los usuarios aceptan indemnizar y eximir de responsabilidad a
                TürkAfrica.Market, sus oficiales, directores, empleados y
                agentes de cualquier reclamo, daños, pérdidas, responsabilidades
                y gastos que surjan de su uso de la plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Ley Aplicable</h1>
              <p>
                Estos Términos se regirán e interpretarán de acuerdo con las
                leyes del Reino Unido.
              </p>
            </div>
            <div className="text_div">
              <h1>11. Cambios en los Términos de Uso</h1>
              <p>
                Nos reservamos el derecho de modificar estos términos en
                cualquier momento. Proporcionaremos aviso de estos cambios
                publicando los términos revisados en la plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>12. Información de Contacto</h1>
              <p>
                Para cualquier pregunta sobre estos Términos de Uso, por favor
                contáctenos en [
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setFirstModalShow(false)}>
                  Haz Clic Aquí
                </Link>
                ].
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="sub_title_heading">
              <h6>Updated on December 22, 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                Welcome to TürkAfrica.Market. These Terms of Use govern your
                access to and use of our B2B and B2C networking and e-commerce
                platform. By accessing or using our services, you agree to these
                terms.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Eligibility </h1>
              <p>
                To use our platform, you must: <br />
                Be at least 18 years old. <br />
                Have the legal capacity to enter into binding contracts.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Account Registration</h1>
              <p>
                Account Creation: Users must provide accurate and complete
                information. Account Responsibilities: Users are responsible for
                maintaining the confidentiality of their account and password
                and for all activities that occur under their account.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Use of Services</h1>
              <p>
                Compliance: Users must comply with all applicable laws, the
                terms of this agreement, and all platform policies. <br />
                Prohibited Conduct: Users must not misuse our services,
                including engaging in fraudulent activities, infringing on
                intellectual property, or spreading harmful or illegal content.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Intellectual Property</h1>
              <p>
                Ownership: All content on TürkAfrica.Market, including text,
                graphics, logos, and software, is the property of
                TürkAfrica.Market or its licensors and is protected by UK and
                international intellectual property laws. <br />
                Use of Content: Users may not use any content from our platform
                without prior written permission.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Third-Party Links and Services</h1>
              <p>
                External Links: Our platform may contain links to third-party
                websites or services. We are not responsible for the content,
                privacy policies, or practices of these sites.
                <br />
                Third-Party Services: Any use of third-party services through
                our platform is solely between the user and the third party.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Termination</h1>
              <p>
                Account Termination: We may terminate or suspend access to our
                platform immediately, without prior notice or liability, for any
                breach of these Terms.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Disclaimers and Limitation of Liability</h1>
              <p>
                No Warranties: Our services are provided "as is" without any
                warranties, expressed or implied. Limitation of Liability:
                TürkAfrica.Market will not be liable for any indirect,
                incidental, special, consequential, or punitive damages
                resulting from the use of our services.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Indemnification</h1>
              <p>
                Users agree to indemnify and hold harmless TürkAfrica.Market,
                its officers, directors, employees, and agents from any claims,
                damages, losses, liabilities, and expenses arising from their
                use of the platform.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Governing Law</h1>
              <p>
                These Terms shall be governed by and construed in accordance
                with the laws of the United Kingdom.
              </p>
            </div>
            <div className="text_div">
              <h1>11. Changes to Terms of Use</h1>
              <p>
                We reserve the right to modify these terms at any time. We will
                provide notice of these changes by posting the revised terms on
                the platform.
              </p>
            </div>
            <div className="text_div">
              <h1>12. Contact Information</h1>
              <p>
                For any questions about these Terms of Use, please contact us at
                [
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setFirstModalShow(false)}>
                  Click Here
                </Link>
                ].
              </p>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
export const Safetysecuritymodal = () => {
  const { SecondModalShow, setSecondModalShow } = useContext(GlobalContext);
  const [lang, setlang] = useState();
  const pathname = useLocation();
  useEffect(() => {
    setlang(pathname.pathname);
  }, [pathname]);
  return (
    <Modal show={SecondModalShow} onHide={setSecondModalShow} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="newtitle">
          <h3 style={{ textAlign: "center" }}>
            {lang == "/Turkish"
              ? "TürkAfrica.Market Güvenlik ve Emniyet"
              : lang == "/French"
              ? "Sécurité et Protection chez TürkAfrica.Market"
              : lang == "/Portuguese"
              ? "Segurança e Proteção na TürkAfrica.Market"
              : lang == "/Swahili"
              ? "Usalama na Ulinzi wa TürkAfrica.Market"
              : lang == "/Spanish"
              ? "TürkAfrica.Market Seguridad y Protección"
              : "TürkAfrica.Market Safety & Security"}
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="Modalbody">
        {lang == "/Turkish" ? (
          <>
            <div className="sub_title_heading">
              <h6>22 Aralık 2023 tarihinde güncellendi</h6>
            </div>
            <div className="text_div">
              <h1>1. Giriş</h1>
              <p>
                TürkAfrica.Market'ta, kullanıcılarımızın ve verilerinin
                güvenliğini sağlamak en üst önceliğimizdir. Tüm B2B ve B2C
                etkileşimlerimiz için güvenli ve güvenilir bir ortam sağlamaya
                kendimizi adamış durumdayız.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Ürün Güvenliği</h1>
              <p>
                Kalite Güvencesi: Tüm satıcıları ve ürünleri, yüksek kalite
                standartlarını karşıladıklarından emin olmak için titizlikle
                denetliyoruz. <br />
                Yönetmeliklere Uyum: Platformumuzdaki tüm ürünler, ilgili
                İngiltere ve uluslararası güvenlik yönetmeliklerine uygundur.{" "}
                <br />
                Kullanıcı Raporlama Sistemi: Kullanıcılar, ürünle ilgili
                güvenlik endişelerini hemen gözden geçirmek üzere
                bildirebilirler.
              </p>
            </div>
            <div className="text_div">
              <h1>3. İşlem Güvenliği</h1>
              <p>
                Güvenli Ödeme İşleme: Finansal işlemlerin güvenliğini sağlamak
                için şifreli ödeme kapılarını kullanıyoruz. <br />
                Sahtekarlık Önleme: Platformumuz, sahtekarlık faaliyetlerini
                tespit etmek ve önlemek için gelişmiş algoritmalar kullanır.{" "}
                <br />
                Kullanıcı Doğrulama: İşletmeler ve tüketiciler için doğrulama
                süreçlerini uygulayarak işlem bütünlüğünü artırıyoruz.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Veri Güvenliği</h1>
              <p>
                Veri Koruma: Kişisel ve iş verileri, endüstri standardı
                şifreleme ve güvenlik protokolleri kullanılarak korunmaktadır.{" "}
                <br />
                Düzenli Güvenlik Denetimleri: Potansiyel zafiyetleri belirleyip
                düzeltmek için platformumuz düzenli güvenlik denetimlerinden
                geçmektedir. <br />
                UKullanıcı Kontrolü: Kullanıcılar, kişisel verileri üzerinde,
                İngiltere GDPR'ına uygun olarak erişim ve silme haklarına
                sahiptirler.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Kullanıcı Eğitimi</h1>
              <p>
                Güvenlik Yönergeleri: Kullanıcılara, platformumuzda işlem
                yapmanın ve etkileşimde bulunmanın güvenli yolları hakkında
                yönergeler sağlıyoruz. <br />
                Farkındalık Kampanyaları: Kullanıcıları güvenlik en iyi
                uygulamaları ve olası dolandırıcılıklar hakkında bilgilendirmek
                için düzenli farkındalık kampanyaları yürütülmektedir.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Acil Durum Yanıtı</h1>
              <p>
                Olay Yanıt Ekibi: Güvenlik olaylarına ve acil durumlara hızlı
                bir şekilde yanıt vermek için adanmış bir ekibimiz var. <br />
                Kullanıcı Desteği: Kullanıcılar, herhangi bir güvenlik endişesi
                veya olayı bildirmek için 7/24 destek alabilirler.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Sürekli İyileştirme</h1>
              <p>
                Geribildirim Mekanizması: Güvenlik ve emniyet tedbirlerimizin
                sürekli iyileştirilmesi için kullanıcı geri bildirimleri hayati
                önem taşır. <br />
                Tehditlere Uyum: Siber tehditlere ve endüstri standartlarına
                yanıt olarak güvenlik önlemlerimizi düzenli olarak
                güncelliyoruz.
              </p>
            </div>
            <div className="text_div">
              <h1>8. İletişim ve Raporlama</h1>
              <p>
                İletişim Bilgileri: Herhangi bir güvenlik veya emniyet endişesi
                için kullanıcılar [
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setSecondModalShow(false)}>
                  Buraya Tıklayın
                </Link>
                ] adresinden bize ulaşabilirler. <br />
                Anonim Raporlama: Açık iletişimi teşvik etmek için güvenlik veya
                emniyet endişelerini anonim olarak bildirme seçeneği sunuyoruz.
              </p>
            </div>
          </>
        ) : lang == "/French" ? (
          <>
            <div className="sub_title_heading">
              <h6>Mis à jour le 22 décembre 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                Chez TürkAfrica.Market, assurer la sécurité de nos utilisateurs
                et de leurs données est une priorité absolue. Nous nous
                engageons à fournir un environnement sûr et fiable pour toutes
                nos interactions B2B et B2C.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Sécurité des Produits</h1>
              <p>
                Assurance Qualité : Nous évaluons rigoureusement tous les
                vendeurs et produits pour garantir qu'ils répondent à des normes
                de haute qualité. <br />
                Conformité aux Réglementations : Tous les produits sur notre
                plateforme sont conformes aux réglementations de sécurité
                applicables au Royaume-Uni et à l'international. <br />
                Système de Signalement par les Utilisateurs : Les utilisateurs
                peuvent signaler toute préoccupation liée à la sécurité des
                produits pour un examen immédiat.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Sécurité Transactionnelle</h1>
              <p>
                Traitement Sécurisé des Paiements : Nous utilisons des
                passerelles de paiement cryptées pour assurer la sécurité des
                transactions financières. <br />
                Prévention de la Fraude : Notre plateforme emploie des
                algorithmes avancés pour détecter et prévenir les activités
                frauduleuses. <br />
                Vérification des Utilisateurs : Nous mettons en œuvre des
                processus de vérification pour les entreprises et les
                consommateurs afin d'améliorer l'intégrité transactionnelle.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Sécurité des Données</h1>
              <p>
                Protection des Données : Les données personnelles et
                professionnelles sont protégées en utilisant un cryptage et des
                protocoles de sécurité standard de l'industrie. <br />
                Audits de Sécurité Réguliers : Notre plateforme subit des audits
                de sécurité réguliers pour identifier et corriger les
                vulnérabilités potentielles. <br />
                Contrôle par l'Utilisateur : Les utilisateurs ont le contrôle de
                leurs données personnelles, y compris les droits d'accès et de
                suppression conformément au RGPD du Royaume-Uni.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Éducation des Utilisateurs</h1>
              <p>
                Directives de Sécurité : Nous fournissons aux utilisateurs des
                directives sur la manière de réaliser des transactions en toute
                sécurité et d'interagir sur notre plateforme.
                <br />
                Awareness Campaigns: Regular awareness campaigns are conducted
                to educate users about security best practices and potential
                scams.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Réponse d'Urgence</h1>
              <p>
                Équipe d'Intervention en Cas d'Incident : Nous disposons d'une
                équipe dédiée pour répondre rapidement aux incidents de sécurité
                et aux urgences. <br />
                Support Utilisateur : Un support est disponible 24/7 pour que
                les utilisateurs signalent toute préoccupation ou incident de
                sécurité.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Amélioration Continue</h1>
              <p>
                Mécanisme de Retour d'Information : Les retours des utilisateurs
                sont essentiels pour l'amélioration continue de nos mesures de
                sécurité et de protection. <br />
                Adaptation aux Menaces : Nous mettons régulièrement à jour nos
                mesures de sécurité en réponse aux menaces cybernétiques
                évolutives et aux normes de l'industrie.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Contact et Signalement</h1>
              <p>
                Informations de Contact : Pour toute préoccupation de sécurité,
                les utilisateurs peuvent nous contacter à [{" "}
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setSecondModalShow(false)}>
                  Cliquez Ici
                </Link>
                ]. <br />
                Signalement Anonyme : Nous offrons une option de signalement
                anonyme pour les préoccupations de sécurité afin d'encourager
                une communication ouverte.
              </p>
            </div>
          </>
        ) : lang == "/Portuguese" ? (
          <>
            <div className="sub_title_heading">
              <h6>Atualizado em 22 de dezembro de 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introdução</h1>
              <p>
                Na TürkAfrica.Market, garantir a segurança dos nossos usuários e
                de seus dados é uma prioridade máxima. Estamos comprometidos em
                fornecer um ambiente seguro e confiável para todas as nossas
                interações B2B e B2C.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Segurança do Produto</h1>
              <p>
                Garantia de Qualidade: Avaliamos rigorosamente todos os
                fornecedores e produtos para garantir que eles atendam aos altos
                padrões de qualidade <br />
                Conformidade com Regulamentos: Todos os produtos em nossa
                plataforma cumprem com as regulamentações de segurança
                aplicáveis do Reino Unido e internacionais. <br />
                Sistema de Relatórios do Usuário: Os usuários podem reportar
                qualquer preocupação relacionada à segurança do produto para
                análise imediata.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Segurança nas Transações</h1>
              <p>
                Processamento de Pagamento Seguro: Utilizamos gateways de
                pagamento criptografados para garantir a segurança das
                transações financeiras. <br />
                Prevenção de Fraudes: Nossa plataforma emprega algoritmos
                avançados para detectar e prevenir atividades fraudulentas.{" "}
                <br />
                Verificação de Usuário: Implementamos processos de verificação
                para empresas e consumidores para aumentar a integridade das
                transações.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Segurança dos Dados</h1>
              <p>
                Proteção de Dados: Dados pessoais e empresariais são protegidos
                usando criptografia padrão da indústria e protocolos de
                segurança. <br />
                Auditorias de Segurança Regulares: Nossa plataforma passa por
                auditorias de segurança regulares para identificar e corrigir
                possíveis vulnerabilidades. <br />
                Controle do Usuário: Os usuários têm controle sobre seus dados
                pessoais, incluindo direitos de acesso e exclusão em
                conformidade com o GDPR do Reino Unido.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Educação do Usuário</h1>
              <p>
                Diretrizes de Segurança: Fornecemos aos usuários diretrizes
                sobre como realizar transações com segurança e interagir em
                nossa plataforma. <br />
                Campanhas de Conscientização: Realizamos campanhas regulares de
                conscientização para educar os usuários sobre as melhores
                práticas de segurança e possíveis golpes.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Resposta a Emergências</h1>
              <p>
                Equipe de Resposta a Incidentes: Temos uma equipe dedicada para
                responder prontamente a incidentes de segurança e emergências.{" "}
                <br />
                Suporte ao Usuário: Suporte 24/7 está disponível para os
                usuários relatarem quaisquer preocupações ou incidentes de
                segurança.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Melhoria Contínua</h1>
              <p>
                Mecanismo de Feedback: O feedback do usuário é vital para a
                melhoria contínua de nossas medidas de segurança e proteção.{" "}
                <br />
                Adaptação a Ameaças: Atualizamos regularmente nossas medidas de
                segurança em resposta a ameaças cibernéticas em evolução e
                padrões da indústria.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Contato e Denúncias</h1>
              <p>
                Informações de Contato: Para quaisquer preocupações com
                segurança ou proteção, os usuários podem nos contatar em [{" "}
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setSecondModalShow(false)}>
                  Clique Aqui
                </Link>
                ].
                <br />
                Denúncias Anônimas: Fornecemos uma opção para denúncias anônimas
                de preocupações com segurança ou proteção para incentivar a
                comunicação aberta.
              </p>
            </div>
          </>
        ) : lang == "/Swahili" ? (
          <>
            <div className="sub_title_heading">
              <h6>Imesasishwa tarehe 22 Desemba, 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Utangulizi</h1>
              <p>
                Katika TürkAfrica.Market, kuhakikisha usalama na ulinzi wa
                watumiaji wetu na data zao ni kipaumbele cha juu. Tunajitolea
                kutoa mazingira salama na ya kuaminika kwa ajili ya mwingiliano
                wetu wote wa B2B na B2C.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Usalama wa Bidhaa</h1>
              <p>
                Uhakikisho wa Ubora: Tunachunguza kwa makini wauzaji na bidhaa
                zote kuhakikisha zinakidhi viwango vya juu vya ubora. <br />
                Kufuata Kanuni: Bidhaa zote kwenye jukwaa letu zinazingatia
                kanuni za usalama za Uingereza na kimataifa.
                <br />
                Mfumo wa Kuripoti wa Watumiaji: Watumiaji wanaweza kuripoti
                wasiwasi wowote kuhusiana na usalama wa bidhaa kwa ukaguzi wa
                haraka.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Usalama wa Miamala</h1>
              <p>
                Uchakataji Salama wa Malipo: Tunatumia malango ya malipo
                yaliyosimbwa ili kuhakikisha usalama wa miamala ya kifedha.{" "}
                <br />
                Kuzuia Udanganyifu: Jukwaa letu linatumia algoriti za kisasa
                kutambua na kuzuia shughuli za udanganyifu. <br /> Uhakiki wa
                Watumiaji: Tunatekeleza mchakato wa uhakiki kwa biashara na
                watumiaji ili kuongeza uadilifu wa miamala.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Usalama wa Data</h1>
              <p>
                Ulinzi wa Data: Data binafsi na ya biashara inalindwa kwa
                kutumia usimbaji fiche na itifaki za usalama za kiwango cha
                sekta. <br />
                Ukaguzi wa Usalama wa Mara kwa Mara: Jukwaa letu linapitia
                ukaguzi wa usalama mara kwa mara ili kutambua na kurekebisha
                udhaifu wowote unaoweza kutokea. <br />
                Udhibiti wa Mtumiaji: Watumiaji wana udhibiti juu ya data yao
                binafsi, ikiwa ni pamoja na haki za ufikiaji na kufuta kulingana
                na GDPR ya Uingereza.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Elimu kwa Mtumiaji</h1>
              <p>
                Miongozo ya Usalama: Tunatoa miongozo kwa watumiaji jinsi ya
                kufanya miamala kwa usalama na kuingiliana kwenye jukwaa letu.{" "}
                <br />
                Kampeni za Ufahamu: Kampeni za mara kwa mara za ufahamu
                zinafanyika ili kuelimisha watumiaji kuhusu mazoea bora ya
                usalama na utapeli unaowezekana.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Majibu ya Dharura</h1>
              <p>
                Timu ya Majibu ya Matukio: Tuna timu iliyojitolea kujibu matukio
                ya usalama na dharura kwa haraka. <br /> Usaidizi kwa Mtumiaji:
                Usaidizi wa saa 24/7 unapatikana kwa watumiaji kuripoti wasiwasi
                wowote wa usalama au matukio.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Kuboresha kwa Kuendelea</h1>
              <p>
                Mfumo wa Maoni: Maoni ya watumiaji ni muhimu kwa kuboresha
                endelevu ya hatua zetu za usalama na ulinzi. <br /> Kukabiliana
                na Vitisho: Tunasasisha mara kwa mara hatua zetu za usalama
                kujibu vitisho vinavyobadilika vya mtandao na viwango vya sekta.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Mawasiliano na Kuripoti</h1>
              <p>
                Taarifa za Mawasiliano: Kwa wasiwasi wowote kuhusu usalama au
                ulinzi, watumiaji wanaweza kuwasiliana nasi kwa [
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setSecondModalShow(false)}>
                  Bonyeza Hapa
                </Link>
                ]. <br /> Kuripoti Bila Kutaja Jina: Tunatoa chaguo la kuripoti
                kwa siri wasiwasi wowote wa usalama au ulinzi ili kuhamasisha
                mawasiliano wazi.
              </p>
            </div>
          </>
        ) : lang == "/Spanish" ? (
          <>
            <div className="sub_title_heading">
              <h6>Actualizado el 22 de diciembre de 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introducción</h1>
              <p>
                En TürkAfrica.Market, garantizar la seguridad y protección de
                nuestros usuarios y sus datos es una prioridad máxima. Estamos
                comprometidos a proporcionar un entorno seguro y confiable para
                todas nuestras interacciones B2B y B2C.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Seguridad del Producto</h1>
              <p>
                Aseguramiento de la Calidad: Evaluamos rigurosamente a todos los
                proveedores y productos para asegurar que cumplan con altos
                estándares de calidad. <br />
                Cumplimiento con Regulaciones: Todos los productos en nuestra
                plataforma cumplen con las regulaciones de seguridad aplicables
                del Reino Unido e internacionales. <br />
                Sistema de Reporte de Usuarios: Los usuarios pueden reportar
                cualquier preocupación relacionada con la seguridad del producto
                para una revisión inmediata.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Seguridad Transaccional</h1>
              <p>
                Procesamiento de Pagos Seguro: Utilizamos pasarelas de pago
                encriptadas para garantizar la seguridad de las transacciones
                financieras. <br />
                Prevención de Fraudes: Nuestra plataforma emplea algoritmos
                avanzados para detectar y prevenir actividades fraudulentas.{" "}
                <br />
                Verificación de Usuarios: Implementamos procesos de verificación
                para empresas y consumidores para mejorar la integridad
                transaccional.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Seguridad de Datos</h1>
              <p>
                Protección de Datos: Los datos personales y empresariales están
                protegidos utilizando encriptación y protocolos de seguridad
                estándar en la industria. <br />
                Auditorías de Seguridad Regulares: Nuestra plataforma se somete
                a auditorías de seguridad regulares para identificar y
                rectificar vulnerabilidades potenciales. <br />
                Control por Parte del Usuario: Los usuarios tienen control sobre
                sus datos personales, incluyendo derechos de acceso y
                eliminación en línea con el GDPR del Reino Unido.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Educación del Usuario</h1>
              <p>
                Guías de Seguridad: Proporcionamos a los usuarios guías sobre
                cómo realizar transacciones de manera segura e interactuar en
                nuestra plataforma. <br />
                Campañas de Concientización: Se realizan campañas regulares de
                concientización para educar a los usuarios sobre las mejores
                prácticas de seguridad y posibles estafas.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Respuesta de Emergencia</h1>
              <p>
                Equipo de Respuesta a Incidentes: Contamos con un equipo
                dedicado para responder a incidentes de seguridad y emergencias
                de manera rápida <br />
                Soporte al Usuario: Soporte disponible 24/7 para que los
                usuarios reporten cualquier preocupación o incidente de
                seguridad.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Mejora Continua</h1>
              <p>
                Mecanismo de Retroalimentación: La retroalimentación de los
                usuarios es vital para la mejora continua de nuestras medidas de
                seguridad y protección. <br />
                Adaptación a Amenazas: Actualizamos regularmente nuestras
                medidas de seguridad en respuesta a amenazas cibernéticas en
                evolución y estándares de la industria.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Contacto y Reporte</h1>
              <p>
                Información de Contacto: Para cualquier preocupación de
                seguridad o protección, los usuarios pueden contactarnos en [{" "}
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setSecondModalShow(false)}>
                  Haz Clic Aquí
                </Link>
                ]. <br />
                Reporte Anónimo: Ofrecemos una opción para el reporte anónimo de
                preocupaciones de seguridad o protección para fomentar la
                comunicación abierta.
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="sub_title_heading">
              <h6>Updated on December 22, 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                At TürkAfrica.Market, ensuring the safety and security of our
                users and their data is a top priority. We are committed to
                providing a secure and trustworthy environment for all our B2B
                and B2C interactions.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Product Safety</h1>
              <p>
                Quality Assurance: We rigorously vet all vendors and products to
                ensure they meet high-quality standards. <br />
                Compliance with Regulations: All products on our platform comply
                with applicable UK and international safety regulations. <br />
                User Reporting System: Users can report any product-related
                safety concerns for immediate review.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Transactional Security</h1>
              <p>
                Secure Payment Processing: We use encrypted payment gateways to
                ensure the security of financial transactions. <br />
                Fraud Prevention: Our platform employs advanced algorithms to
                detect and prevent fraudulent activities. <br />
                User Verification: We implement verification processes for
                businesses and consumers to enhance transactional integrity.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Data Security</h1>
              <p>
                Data Protection: Personal and business data is protected using
                industry-standard encryption and security protocols. <br />
                Regular Security Audits: Our platform undergoes regular security
                audits to identify and rectify potential vulnerabilities. <br />
                User Control: Users have control over their personal data,
                including access and deletion rights in line with the UK GDPR.
              </p>
            </div>
            <div className="text_div">
              <h1>5. User Education</h1>
              <p>
                Safety Guidelines: We provide users with guidelines on how to
                safely conduct transactions and interact on our platform. <br />
                Awareness Campaigns: Regular awareness campaigns are conducted
                to educate users about security best practices and potential
                scams.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Emergency Response</h1>
              <p>
                Incident Response Team: We have a dedicated team to respond to
                security incidents and emergencies promptly. <br />
                User Support: 24/7 support is available for users to report any
                security concerns or incidents.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Continuous Improvement</h1>
              <p>
                Feedback Mechanism: User feedback is vital for continual
                improvement of our safety and security measures. <br />
                Adapting to Threats: We regularly update our security measures
                in response to evolving cyber threats and industry standards.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Contact and Reporting</h1>
              <p>
                Contact Information: For any safety or security concerns, users
                can contact us at [
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setSecondModalShow(false)}>
                  Click Here
                </Link>
                ]. <br />
                Anonymous Reporting: We provide an option for anonymous
                reporting of safety or security concerns to encourage open
                communication.
              </p>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
export const Guidelinesmodal = () => {
  const { ThirdModalShow, setThirdModalShow } = useContext(GlobalContext);
  const [lang, setlang] = useState();
  const pathname = useLocation();
  useEffect(() => {
    setlang(pathname.pathname);
  }, [pathname]);
  return (
    <Modal show={ThirdModalShow} onHide={setThirdModalShow} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="newtitle">
          <h3 style={{ textAlign: "center" }}>
            {lang == "/Turkish"
              ? "TürkAfrica.Market Kuralları"
              : lang == "/French"
              ? "Directives de TürkAfrica.Market"
              : lang == "/Portuguese"
              ? "Diretrizes da TürkAfrica.Market"
              : lang == "/Swahili"
              ? "Miongozo ya TürkAfrica.Market"
              : lang == "/Spanish"
              ? "Guías de TürkAfrica.Market"
              : "TürkAfrica.Market Guidelines"}
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="Modalbody">
        {lang == "/Turkish" ? (
          <>
            <div className="sub_title_heading">
              <h6>22 Aralık 2023 tarihinde güncellendi</h6>
            </div>
            <div className="text_div">
              <h1>Ayrımcılık, Nefret ve Anti-Semitizm</h1>
              <p>
                TürkAfrica.Market, ayrımcılığa, nefrete, ırkçılığa ve
                anti-Semitizme sıfır tolerans gösteren kapsayıcı bir
                platformdur. Nefret söylemi içeren veya nefret davranışını
                teşvik eden içeriklere izin vermiyoruz. Platformumuz dışında
                nefret söylemiyle ilişkili veya nefret söylemi yapan hesaplar
                askıya alınacak veya yasaklanacaktır. <br />
                Nefret söylemi veya nefret dolu davranışları, korunan
                özelliklere dayalı olarak bir bireye veya gruba saldıran, tehdit
                eden, şiddet teşvik eden veya diğer şekillerde insanlık dışı
                muamelede bulunan içerikler olarak tanımlıyoruz: Kast,
                Engellilik, Etnik köken, Cinsiyet, Ulusal köken, Irk, Din ve
                Cinsel yönelim.
              </p>
            </div>
            <div className="text_div">
              <h1>Yayınlamayın, yüklemeyin, yayınlamayın veya paylaşmayın:</h1>
              <p>
                <ul>
                  <li>
                    Bir bireye veya gruba yönelik nefret dolu içerikler,
                    aşağılık iddiaları, şiddet çağrıları, suçlamalar veya
                    insanlık dışı karşılaştırmalar içeren içerikler.
                  </li>
                  <li>
                    Korunan özelliklere dayalı bireylere veya gruplara zarar
                    veren içerikleri.
                  </li>
                </ul>
                Nefret İdeolojisi <br />
                Nefret ideolojileri, korunan özelliklerinden dolayı insanlara
                açık düşmanlık gösterir. Bunlar kapsayıcı ve destekleyici
                topluluğumuzla uyumsuzdur ve bu tür ideolojileri teşvik eden
                içerikleri kaldırırız.
              </p>
            </div>
            <div className="text_div">
              <h1>Yayınlamayın, yüklemeyin, yayınlamayın veya paylaşmayın:</h1>
              <p>
                <ul>
                  <li>
                    Herhangi bir nefret ideolojisini öven, teşvik eden, yücelten
                    veya destekleyen içerikler.
                  </li>
                  <li>
                    Nefret ideolojileriyle ilgili içerikler, dahil olmak üzere
                    semboller, sloganlar, müzik veya diğer nesneler.
                  </li>
                  <li>
                    Korunan özelliklere sahip grupları etkileyen iyi belgelenmiş
                    şiddet olaylarını inkar eden veya üstünlük iddiaları içeren
                    içerikler.
                  </li>
                  <li>
                    Nefret ideolojilerini haklı çıkaran gerçek dışı teoriler.
                  </li>
                </ul>
                Aşırılık <br />
                TürkAfrica.Market'te şiddete karşıyız. Platformumuzun şiddeti
                tehdit etmek veya teşvik etmek için kullanılmasına izin
                vermiyoruz. Platformumuz dışında şiddeti teşvik eden hesapları
                askıya alabilir veya yasaklayabiliriz ve tehditleri yasal
                makamlara bildirebiliriz. Topluluğumuzu korumak için diğer
                platformlardan ve çevrimdışı bilgileri de dikkate alarak şiddet
                ve aşırılık yanlısı birey veya örgütleri tanımlayabiliriz. Bu
                tür varlıklarla ilişkilendirilen hesaplar kapatılacaktır.
              </p>
            </div>
            <div className="text_div">
              <h1>Şiddet ve Şiddeti Teşvik Etme Tehditleri</h1>
              <p>
                Şiddeti teşvik etme, şiddet işlemeye teşvik etmek veya
                başkalarını şiddet işlemeye cesaretlendirmek anlamına gelir.
                Ciddi fiziksel zarara yol açabilecek tehditlere veya şiddeti
                teşvik etmeye izin vermiyoruz.
              </p>
            </div>
            <div className="text_div">
              <h1>Yayınlamayın, yüklemeyin, yayınlamayın veya paylaşmayın:</h1>
              <p>
                Fiziksel şiddeti savunan ifadeler veya görseller. <br />
                Bireylere veya gruplara korku salmak veya tehdit etmek için
                silah getirme çağrıları. <br />
                Tehlikeli Bireyler ve Örgütler <br />
                Şiddetle ilişkili birey veya örgütlere platformumuzda yer
                vermiyoruz. Bu, toplu katilleri, nefret gruplarını, suç
                örgütlerini, terör örgütlerini ve diğerlerini içerir
              </p>
            </div>
            <div className="text_div">
              <h1>Terör Örgütleri ve Örgütlü Nefret</h1>
              <p>
                Terör örgütleri ve örgütlü nefret grupları, siyasi, dini, etnik
                veya ideolojik amaçlarla sivilleri hedef alır. Bu tür
                varlıklarla ilgili içerikleri kaldırırız.
              </p>
            </div>
            <div className="text_div">
              <h1>Örgütlü Suç</h1>
              <p>
                Ticaret veya finansal suçlar gibi ciddi suçlarla ilgili suç
                örgütleri kabul edilmez.
              </p>
            </div>
            <div className="text_div">
              <h1>Yayınlamayın, yüklemeyin, yayınlamayın veya paylaşmayın:</h1>
              <p>
                Tehlikeli bireyleri veya örgütleri destekleyen içerikler. <br />
                Tehlikeli örgütler için işe alım veya tanıtım içerikleri. <br />
                Yasa Dışı Faaliyetler <br />
                TürkAfrica.Market'in yasaları veya düzenlemeleri ihlal eden
                faaliyetlere olanak sağlamamasını sağlamak için çalışıyoruz.
                <br />
                Suçla ilgili içeriklere izin vermiyoruz.
              </p>
            </div>
            <div className="text_div">
              <h1>Suç Faaliyetleri</h1>
              <p>
                Bu, hırsızlık, insan sömürüsü veya sahtecilik gibi geniş bir
                yasa dışı eylem yelpazesini içerir.
              </p>
            </div>
            <div className="text_div">
              <h1>Yayınlamayın, yüklemeyin, yayınlamayın veya paylaşmayın:</h1>
              <p>
                <ul>
                  <li>
                    Fiziksel zarar, insan sömürüsü veya yasa dışı ticareti
                    teşvik eden içerikler.
                  </li>
                </ul>
                Dolandırıcılıklar ve Sahtekarlıklar <br />
                İnsanları yasa dışı mali veya kişisel kazanç için aldatan
                içerikleri kaldırırız, çeşitli dolandırıcılık türleri dahil.
              </p>
            </div>
            <div className="text_div">
              <h1>Yayınlamayın, yüklemeyin, yayınlamayın veya paylaşmayın:</h1>
              <p>
                <ul>
                  <li>
                    Phishing, Ponzi şemaları veya diğer dolandırıcılık
                    faaliyetleriyle ilgili içerikler.
                  </li>
                </ul>
                Gizlilik, Kişisel Veriler ve PII <br />
                Kişisel verilerin gizliliğini veya kişisel olarak tanımlanabilir
                bilgileri (PII) ihlal eden içeriklere izin vermiyoruz.
              </p>
            </div>
            <div className="text_div">
              <h1>Yayınlamayın, yüklemeyin, yayınlamayın veya paylaşmayın:</h1>
              <p>
                Kişisel verileri veya PII içeren içerikler. <br />
                Şiddetli ve Grafik İçerik
                <br />
                Aşırı şok edici olan veya şiddeti yücelten içeriklere izin
                vermiyoruz.
              </p>
            </div>
            <div className="text_div">
              <h1>Yayınlamayın, yüklemeyin, yayınlamayın veya paylaşmayın:</h1>
              <p>
                <ul>
                  <li>
                    İnsanlar veya hayvanlarla ilgili şiddetli veya grafik
                    olayları betimleyen içerikler.
                  </li>
                </ul>
                İntihar, Kendine Zarar Verme ve Tehlikeli Davranışlar
                <br /> Topluluk üyelerimizin sağlık ve esenliğine öncelik
                veriyoruz. İntihara, kendine zarar vermeye, yeme bozukluklarına
                veya tehlikeli faaliyetlere yol açabilecek içeriklere izin
                vermiyoruz.
              </p>
            </div>
            {/* <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Content related to suicide, self-harm, eating disorders, or
                    dangerous acts.
                  </li>
                </ul>
                Harassment and Bullying <br />
                We strive for an inclusive community free from harassment and
                bullying.
              </p>
            </div>
            <div className="text_div">
              <h1>Abusive Behavior</h1>
              <p>
                We remove expressions of abuse, including threats or derogatory
                statements.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Content that insults, intimidates, or harasses individuals.
                  </li>
                </ul>
                Sexual Harassment <br /> Sexual harassment in any form is not
                tolerated.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Content that includes unwanted sexual behavior or
                    commentary.
                  </li>
                </ul>
                Threats of Hacking, Doxxing, and Blackmail <br />
                We consider online behaviors like doxxing and hacking as forms
                of abuse.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Threats to reveal personal data or engage in hacking or
                    blackmail.
                  </li>
                </ul>
                Adult Nudity and Sexual <br /> Activitie We prohibit pornography
                and sexually explicit content
              </p>
            </div>
            <div className="text_div">
              <h1>Sexual Exploitation</h1>
              <p>Sexually exploitative content is not permitted.</p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                Content depicting non-consensual sexual acts or nudity. <br />
                Integrity and Authenticity <br />
                We maintain the authenticity of our platform and prevent
                activities undermining its integrity.
              </p>
            </div>
            <div className="text_div">
              <h1>Spam and Fake Engagement</h1>
              <p>We prohibit artificial manipulation of engagement metrics.</p>
            </div>
            <div className="text_div">
              <h1>Impersonation</h1>
              <p>
                We do not allow deceptive impersonation but permit parody or
                commentary accounts if clearly indicated.
              </p>
            </div>
            <div className="text_div">
              <h1>Misinformation</h1>
              <p>We do not permit harmful misinformation.</p>
            </div>
            <div className="text_div">
              <h1>Intellectual Property Violations</h1>
              <p>
                We respect intellectual property rights and remove content that
                infringes these rights. <br />
                General Commitments <br />
                <ul>
                  <li>
                    <h1>Safety:</h1>Commitment to making TurkAfrica.Market a
                    safe place.
                  </li>
                  <li>
                    <h1>Privacy:</h1>Protecting personal privacy and
                    information.
                  </li>
                  <li>
                    <h1>Dignity:</h1>Respecting the dignity and rights of all
                    people.
                  </li>
                </ul>
                These guidelines apply globally to all content and are designed
                to be comprehensive. We understand that context matters and
                strive to apply our policies consistently and fairly. Users can
                report violations, and we may notify law enforcement in cases of
                genuine risk.
              </p>
            </div> */}
          </>
        ) : lang == "/French" ? (
          <>
            <div className="sub_title_heading">
              <h6>Mis à jour le 22 décembre 2023</h6>
            </div>
            <div className="text_div">
              <h1>Discrimination, Haine et Antisémitisme</h1>
              <p>
                TürkAfrica.Market est une plateforme inclusive avec une
                tolérance zéro pour la discrimination, la haine, le racisme et
                l'antisémitisme. Nous n'autorisons pas de contenu qui contient
                des discours haineux ou promeut un comportement haineux. Les
                comptes qui s'engagent dans des discours haineux ou associés à
                des discours haineux en dehors de notre plateforme seront
                suspendus ou bannis. Nous définissons les discours haineux ou
                comportements haineux comme des contenus qui attaquent,
                menacent, incitent à la violence contre, ou déshumanisent un
                individu ou un groupe sur la base d'attributs protégés : Caste,
                Handicap, Ethnicité, Genre, Origine nationale, Race, Religion,
                et Orientation sexuelle.
              </p>
            </div>
            <div className="text_div">
              <h1>
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                <ul>
                  <li>
                    Contenu haineux lié à un individu ou un groupe, y compris
                    des affirmations d'infériorité, des appels à la violence,
                    des accusations criminelles, ou des comparaisons
                    déshumanisantes.
                  </li>
                  <li>
                    Contenu représentant des dommages à des individus ou à des
                    groupes basés sur des attributs protégés.
                  </li>
                </ul>
                Idéologie Haineuse
                <br />
                Les idéologies haineuses montrent une hostilité claire envers
                les personnes en raison de leurs attributs protégés. Ces
                idéologies sont incompatibles avec notre communauté inclusive et
                solidaire, et nous supprimons le contenu les promouvant.
              </p>
            </div>
            <div className="text_div">
              <h1>
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas:
              </h1>
              <p>
                <ul>
                  <li>
                    Contenu faisant l'éloge, promouvant, glorifiant ou soutenant
                    une idéologie haineuse.
                  </li>
                  <li>
                    Contenu lié à des idéologies haineuses, y compris des
                    symboles, slogans, musiques, ou autres objets.
                  </li>
                  <li>
                    Contenu niant des événements violents bien documentés
                    affectant des groupes avec des attributs protégés ou faisant
                    des revendications de suprématie.
                  </li>
                  <li>
                    Théories non factuelles justifiant des idéologies haineuses.
                  </li>
                </ul>
                Extrémisme <br />
                Nous nous opposons fermement à la violence sur ou en dehors de
                TürkAfrica.Market. Nous n'autorisons pas notre plateforme à être
                utilisée pour menacer ou inciter à la violence ou pour
                promouvoir des individus ou des organisations dangereux. Nous
                pouvons suspendre ou bannir des comptes promouvant la violence
                hors de la plateforme et signaler des menaces aux autorités
                légales. Pour protéger notre communauté, nous pouvons considérer
                des informations provenant d'autres plateformes et hors ligne
                pour identifier des individus ou des organisations violents et
                extrémistes. Les comptes trouvés associés à de telles entités
                seront fermés.
              </p>
            </div>
            <div className="text_div">
              <h1>Menaces et Incitation à la Violence</h1>
              <p>
                L'incitation à la violence comprend le plaidoyer ou
                l'encouragement d'autres à commettre des violences. Nous
                n'autorisons pas les menaces ou l'incitation à la violence qui
                peuvent conduire à de graves dommages physiques.
              </p>
            </div>
            <div className="text_div">
              <h1>
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                Déclarations ou imageries prônant la violence physique. <br />
                Appels à apporter des armes pour intimider ou menacer des
                individus ou des groupes. <br />
                Individus et Organisations Dangereux <br />
                Nous n'autorisons pas les individus ou organisations sur notre
                plateforme qui sont impliqués dans la violence. Cela inclut les
                meurtriers de masse, les groupes haineux, les organisations
                criminelles, les organisations terroristes, et d'autres.
              </p>
            </div>
            <div className="text_div">
              <h1>Organisations Terroristes et Haine Organisée</h1>
              <p>
                Les organisations terroristes et les groupes de haine organisée
                ciblent des civils pour des objectifs politiques, religieux,
                ethniques ou idéologiques. Nous supprimons le contenu lié à de
                telles entités.
              </p>
            </div>
            <div className="text_div">
              <h1>Crime Organisé</h1>
              <p>
                Les organisations criminelles impliquées dans des crimes graves
                tels que le trafic ou les crimes financiers ne sont pas
                autorisées.
              </p>
            </div>
            <div className="text_div">
              <h1>
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                Contenu soutenant des individus ou des organisations dangereux.{" "}
                <br />
                Contenu de recrutement ou promotionnel pour des organisations
                dangereuses. <br />
                Activités Illégales <br />
                Nous travaillons pour garantir que TürkAfrica.Market n'active
                pas des activités qui violent les lois ou les réglementations.{" "}
                <br />
                Nous interdisons le contenu lié à des activités criminelles.
              </p>
            </div>
            <div className="text_div">
              <h1>Activités Criminelles</h1>
              <p>
                Cela inclut une large gamme d'actes illégaux, tels que le vol,
                l'exploitation humaine, ou la contrefaçon.
              </p>
            </div>
            <div className="text_div">
              <h1>
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                <ul>
                  <li>
                    Contenu promouvant des dommages physiques, l'exploitation
                    humaine, ou le commerce illégal.
                  </li>
                </ul>
                Fraudes et Escroqueries <br />
                Nous supprimons le contenu trompant les gens pour un gain
                financier ou personnel illégal, y compris divers types
                d'escroqueries.
              </p>
            </div>
            <div className="text_div">
              <h1>
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                <ul>
                  <li>
                    Contenu lié au phishing, aux schémas de Ponzi, ou à d'autres
                    activités frauduleuses..
                  </li>
                </ul>
                Vie Privée, Données Personnelles et PII
                <br />
                Nous n'autorisons pas le contenu qui viole la confidentialité
                des données personnelles ou des informations personnellement
                identifiables (PII).
              </p>
            </div>
            <div className="text_div">
              <h1>
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                Contenu contenant des données personnelles ou des PII <br />
                Contenu Violent et Graphique <br />
                Nous n'autorisons pas le contenu qui est excessivement choquant
                ou qui glorifie la violence.
              </p>
            </div>
            <div className="text_div">
              <h1>
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                <ul>
                  <li>
                    Contenu dépeignant des événements violents ou graphiques
                    impliquant des humains ou des animaux.
                  </li>
                </ul>
                Suicide, Automutilation et Actes Dangereux <br /> Nous
                priorisons la santé et le bien-être de nos membres de la
                communauté. Nous n'autorisons pas le contenu pouvant conduire au
                suicide, à l'automutilation, aux troubles alimentaires, ou à des
                activités dangereuses.
              </p>
            </div>
            <div className="text_div">
              <h1>
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                <ul>
                  <li>
                    Contenu lié au suicide, à l'automutilation, aux troubles
                    alimentaires, ou à des actes dangereux.
                  </li>
                </ul>
                Harcèlement et Intimidation <br />
                Nous nous efforçons d'une communauté inclusive exempte de
                harcèlement et d'intimidation.
              </p>
            </div>
            <div className="text_div">
              <h1>Comportement Abusif</h1>
              <p>
                Nous supprimons les expressions d'abus, y compris les menaces ou
                déclarations péjoratives.
              </p>
            </div>
            <div className="text_div">
              <h1>
                {" "}
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                <ul>
                  <li>
                    Contenu qui insulte, intimide ou harcèle des individus.
                  </li>
                </ul>
                Harcèlement Sexuel <br /> Le harcèlement sexuel sous toute forme
                n'est pas toléré.
              </p>
            </div>
            <div className="text_div">
              <h1>
                {" "}
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                <ul>
                  <li>
                    • Contenu qui inclut un comportement sexuel non désiré ou
                    des commentaires.
                  </li>
                </ul>
                Menaces de Piratage, Doxxing et Chantage <br />
                Nous considérons les comportements en ligne comme le doxxing et
                le piratage comme des formes d'abus.
              </p>
            </div>
            <div className="text_div">
              <h1>
                {" "}
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                <ul>
                  <li>
                    Menaces de révéler des données personnelles ou de s'engager
                    dans le piratage ou le chantage
                  </li>
                </ul>
                Nudité Adulte et Activités Sexuelles <br /> Nous interdisons la
                pornographie et le contenu sexuellement explicite
              </p>
            </div>
            <div className="text_div">
              <h1>Exploitation Sexuelle</h1>
              <p>Le contenu sexuellement exploiteur n'est pas autorisé.</p>
            </div>
            <div className="text_div">
              <h1>
                Ne postez pas, ne téléchargez pas, ne diffusez pas, ou ne
                partagez pas :
              </h1>
              <p>
                Contenu dépeignant des actes sexuels non consensuels ou de la
                nudité. <br />
                Intégrité et Authenticité
                <br />
                Nous maintenons l'authenticité de notre plateforme et prévenons
                les activités compromettant son intégrité.
              </p>
            </div>
            <div className="text_div">
              <h1>Spam and Fake Engagement</h1>
              <p>We prohibit artificial manipulation of engagement metrics.</p>
            </div>
            <div className="text_div">
              <h1>Spam et Faux Engagement</h1>
              <p>
                Nous interdisons la manipulation artificielle des métriques
                d’engagement.
              </p>
            </div>
            <div className="text_div">
              <h1>Spam et Engagement Factice</h1>
              <p>
                Nous interdisons toute manipulation artificielle des mesures
                d'engagement.
              </p>
            </div>
            <div className="text_div">
              <h1>Violations de la Propriété Intellectuelle</h1>
              <p>
                Nous respectons les droits de propriété intellectuelle et
                supprimons les contenus qui enfreignent ces droits. <br />
                Engagements Généraux
                <br />
                <ul>
                  <li>
                    <h1>Sécurité:</h1> Engagement à faire de TurkAfrica.Market
                    un lieu sûr.
                  </li>
                  <li>
                    <h1>Vie Privée : </h1>Protection de la vie privée
                    personnelle et des informations.
                  </li>
                  <li>
                    <h1>Dignité:</h1> Respect de la dignité et des droits de
                    toutes les personnes.
                  </li>
                </ul>
                Ces directives s'appliquent globalement à tous les contenus et
                sont conçues pour être complètes. Nous comprenons que le
                contexte est important et nous nous efforçons d'appliquer nos
                politiques de manière cohérente et équitable. Les utilisateurs
                peuvent signaler les violations, et nous pouvons notifier les
                autorités judiciaires en cas de risque réel.
              </p>
            </div>
          </>
        ) : lang == "/Portuguese" ? (
          <>
            <div className="sub_title_heading">
              <h6>Atualizado em 22 de dezembro de 2023</h6>
            </div>
            <div className="text_div">
              <h1>Discriminação, Ódio e Antissemitismo</h1>
              <p>
                A TürkAfrica.Market é uma plataforma inclusiva com tolerância
                zero para discriminação, ódio, racismo e antissemitismo. Não
                permitimos conteúdo que contenha discurso de ódio ou promova
                comportamento odioso. Contas envolvidas em discurso de ódio ou
                associadas a discurso de ódio fora de nossa plataforma serão
                suspensas ou banidas. <br />
                Definimos discurso de ódio ou comportamento odioso como conteúdo
                que ataca, ameaça, incita violência contra ou desumaniza um
                indivíduo ou grupo com base em atributos protegidos: Casta,
                Deficiência, Etnia, Gênero, Origem Nacional, Raça, Religião e
                Orientação Sexual.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                <ul>
                  <li>
                    Conteúdo odioso relacionado a um indivíduo ou grupo,
                    incluindo afirmações de inferioridade, apelos à violência,
                    acusações criminais ou comparações desumanizantes.
                  </li>
                  <li>
                    Conteúdo retratando danos a indivíduos ou grupos com base em
                    atributos protegidos.
                  </li>
                </ul>
                Ideologia de Ódio
                <br />
                Ideologias de ódio demonstram clara hostilidade contra pessoas
                devido a seus atributos protegidos. Estas são incompatíveis com
                nossa comunidade inclusiva e solidária, e removemos conteúdo que
                promova tais ideologias.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                <ul>
                  <li>
                    Conteúdo elogiando, promovendo, glorificando ou apoiando
                    qualquer ideologia de ódio.
                  </li>
                  <li>
                    Conteúdo relacionado a ideologias de ódio, incluindo
                    símbolos, slogans, músicas ou outros objetos.
                  </li>
                  <li>
                    Conteúdo negando eventos violentos bem documentados que
                    afetam grupos com atributos protegidos ou fazendo
                    reivindicações de supremacia.
                  </li>
                  <li>Teorias não factuais justificando ideologias de ódio.</li>
                </ul>
                Extremismo <br />
                Somos firmemente contra a violência dentro ou fora da
                TürkAfrica.Market. Não permitimos que nossa plataforma seja
                usada para ameaçar ou incitar violência ou para promover
                indivíduos ou organizações perigosas. Podemos suspender ou banir
                contas promovendo violência fora da plataforma e relatar ameaças
                às autoridades legais. Para proteger nossa comunidade, podemos
                considerar informações de outras plataformas e offline na
                identificação de indivíduos ou organizações violentos e
                extremistas. Contas associadas a tais entidades serão fechadas.
              </p>
            </div>
            <div className="text_div">
              <h1>Ameaças e Incitação à Violência</h1>
              <p>
                Incitação à violência inclui defender ou encorajar outros a
                cometer violência. Não permitimos ameaças ou incitação à
                violência que possam levar a danos físicos graves.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                Declarações ou imagens defendendo violência física. <br />
                Chamados para trazer armas para intimidar ou ameaçar indivíduos
                ou grupos.
                <br />
                Indivíduos e Organizações Perigosas
                <br />
                Não permitimos indivíduos ou organizações em nossa plataforma
                que estejam envolvidos em violência. Isso inclui assassinos em
                massa, grupos de ódio, organizações criminosas, organizações
                terroristas e outros.
              </p>
            </div>
            <div className="text_div">
              <h1>Organizações Terroristas e Ódio Organizado</h1>
              <p>
                Organizações terroristas e grupos de ódio organizado visam civis
                por objetivos políticos, religiosos, étnicos ou ideológicos.
                Removemos conteúdo relacionado a tais entidades.
              </p>
            </div>
            <div className="text_div">
              <h1>Crime Organizado</h1>
              <p>
                Organizações criminosas envolvidas em crimes graves como tráfico
                ou crimes financeiros não são permitidas.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                Conteúdo apoiando indivíduos ou organizações perigosas.
                <br />
                Conteúdo de recrutamento ou promocional para organizações
                perigosas.
                <br />
                Atividades Ilegais <br />
                Trabalhamos para garantir que a TürkAfrica.Market não habilite
                atividades que violem leis ou regulamentos. <br />
                Proibimos conteúdo relacionado a atividades criminosas.
              </p>
            </div>
            <div className="text_div">
              <h1>Atividades Criminais</h1>
              <p>
                Isso inclui uma ampla gama de atos ilegais, como roubo,
                exploração humana ou falsificação.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                <ul>
                  <li>
                    Conteúdo promovendo danos físicos, exploração humana ou
                    comércio ilegal.
                  </li>
                </ul>
                Fraudes e Golpes
                <br />
                Removemos conteúdo enganando pessoas para ganhos financeiros ou
                pessoais ilegais, incluindo vários tipos de golpes.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                <ul>
                  <li>
                    Conteúdo relacionado a phishing, esquemas de Ponzi ou outras
                    atividades fraudulentas.
                  </li>
                </ul>
                Privacidade, Dados Pessoais e PII <br />
                Não permitimos conteúdo que viole a confidencialidade de dados
                pessoais ou informações pessoalmente identificáveis (PII).
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                Conteúdo contendo dados pessoais ou PII. <br />
                Conteúdo Violento e Gráfico
                <br />
                Não permitimos conteúdo excessivamente chocante ou que
                glorifique violência.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                <ul>
                  <li>
                    Conteúdo retratando eventos violentos ou gráficos envolvendo
                    humanos ou animais.
                  </li>
                </ul>
                Suicídio, Automutilação e Atos Perigosos
                <br /> Priorizamos a saúde e o bem-estar dos membros de nossa
                comunidade. Não permitimos conteúdo que possa levar ao suicídio,
                automutilação, distúrbios alimentares ou atividades perigosas.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                <ul>
                  <li>
                    Conteúdo relacionado a suicídio, automutilação, transtornos
                    alimentares ou atos perigosos.
                  </li>
                </ul>
                Assédio e Bullying
                <br />
                Nos esforçamos por uma comunidade inclusiva livre de assédio e
                bullying.
              </p>
            </div>
            <div className="text_div">
              <h1>Comportamento Abusivo</h1>
              <p>
                Removemos expressões de abuso, incluindo ameaças ou declarações
                depreciativas.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                <ul>
                  <li>Conteúdo que insulte, intimide ou assedie indivíduos.</li>
                </ul>
                Assédio Sexual <br /> Assédio sexual em qualquer forma não é
                tolerado.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                <ul>
                  <li>
                    Conteúdo que inclua comportamento sexual indesejado ou
                    comentários.
                  </li>
                </ul>
                Ameaças de Hacking, Doxxing e Chantagem
                <br />
                Consideramos comportamentos online como doxxing e hacking como
                formas de abuso.
              </p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                <ul>
                  <li>
                    Ameaças de revelar dados pessoais ou se envolver em hacking
                    ou chantagem.
                  </li>
                </ul>
                Nudez Adulta e Atividades Sexuais <br /> Proibimos pornografia e
                conteúdo sexualmente explícito.
              </p>
            </div>
            <div className="text_div">
              <h1>Exploração Sexual</h1>
              <p>Conteúdo sexualmente exploratório não é permitido.</p>
            </div>
            <div className="text_div">
              <h1>Não poste, faça upload, transmita ou compartilhe:</h1>
              <p>
                Conteúdo retratando atos sexuais não consensuais ou nudez.{" "}
                <br />
                Integridade e Autenticidade <br />
                Mantemos a autenticidade de nossa plataforma e prevenimos
                atividades que prejudiquem sua integridade.
              </p>
            </div>
            <div className="text_div">
              <h1>Spam e Engajamento Falso</h1>
              <p>
                Proibimos a manipulação artificial de métricas de engajamento.
              </p>
            </div>
            <div className="text_div">
              <h1>Personificação</h1>
              <p>
                Não permitimos personificação enganosa, mas permitimos contas de
                paródia ou comentário se claramente indicadas.
              </p>
            </div>
            <div className="text_div">
              <h1>Desinformação</h1>
              <p>WNão permitimos desinformação prejudicial.</p>
            </div>
            <div className="text_div">
              <h1>Violações de Propriedade Intelectual</h1>
              <p>
                Respeitamos os direitos de propriedade intelectual e removemos
                conteúdo que infrinja esses direitos. <br />
                Compromissos Gerais <br />
                <ul>
                  <li>
                    <h1> Segurança :</h1> Compromisso em tornar a
                    TürkAfrica.Market um lugar seguro
                  </li>
                  <li>
                    <h1>Privacidade :</h1> Proteção da privacidade pessoal e
                    informação.
                  </li>
                  <li>
                    <h1>Dignidade:</h1> Respeito à dignidade e direitos de todas
                    as pessoas.
                  </li>
                </ul>
                Estas diretrizes aplicam-se globalmente a todo o conteúdo e são
                projetadas para serem abrangentes. Entendemos que o contexto
                importa e nos esforçamos para aplicar nossas políticas de
                maneira consistente e justa. Os usuários podem denunciar
                violações, e podemos notificar as autoridades policiais em casos
                de risco genuíno.
              </p>
            </div>
          </>
        ) : lang == "/Swahili" ? (
          <>
            <div className="sub_title_heading">
              <h6>Imesasishwa tarehe 22 Desemba, 2023</h6>
            </div>
            <div className="text_div">
              <h1>Ubaguzi, Chuki, na Uhasama dhidi ya Wayahudi</h1>
              <p>
                TürkAfrica.Market ni jukwaa lenye kujumuisha wote na halivumilii
                ubaguzi, chuki, ubaguzi wa rangi, na uhasama dhidi ya Wayahudi.
                Hatukubali yaliyomo yanayojumuisha hotuba ya chuki au
                yanayotangaza tabia ya chuki. Akaunti zinazojihusisha na hotuba
                ya chuki au zinazohusiana na hotuba ya chuki nje ya jukwaa letu
                zitasimamishwa au kufungiwa. <br />
                Tunafafanua hotuba ya chuki au tabia ya chuki kama yaliyomo
                yanayoshambulia, kutishia, kuchochea vurugu dhidi ya, au
                vinginevyo kudhalilisha mtu binafsi au kikundi kulingana na sifa
                zilindwa: Tabaka, Ulemavu, Uasili wa Kikabila, Jinsia, Asili ya
                Kitaifa, Rangi, Dini, na Mwelekeo wa Kingono.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                <ul>
                  <li>
                    Yaliyomo yenye chuki yanayohusiana na mtu binafsi au
                    kikundi, ikiwa ni pamoja na madai ya uduni, wito wa vurugu,
                    tuhuma za uhalifu, au kulinganisha kudhalilisha.
                  </li>
                  <li>
                    Yaliyomo yanayoonyesha madhara kwa watu binafsi au vikundi
                    kulingana na sifa zilindwa.
                  </li>
                </ul>
                Itikadi ya Chuki <br />
                Itikadi za chuki zinaonyesha uadui wazi kwa watu kwa sababu ya
                sifa zao zilindwa. Hizi hazikubaliani na jamii yetu
                inayojumuisha na kusaidiana, na tunaondoa yaliyomo yanayotangaza
                zao.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                <ul>
                  <li>
                    Yaliyomo yanayosifu, kutangaza, kufafanua, au kusaidia
                    itikadi yoyote ya chuki.
                  </li>
                  <li>
                    Yaliyomo yanayohusiana na itikadi za chuki, ikiwa ni pamoja
                    na alama, kauli mbiu, muziki, au vitu vingine.
                  </li>
                  <li>
                    Yaliyomo yanayokana matukio ya vurugu yaliyothibitishwa
                    vizuri yanayoathiri vikundi vyenye sifa zilindwa au madai ya
                    kufanya ubora.
                  </li>
                  <li>
                    Nadharia zisizo za kweli zinazohalalisha itikadi za chuki.
                  </li>
                </ul>
                Usaidi <br />
                Tunapinga vikali vurugu ndani au nje ya TürkAfrica.Market.
                Hatuwezi kuruhusu jukwaa letu litumike kuwatishia au kuchochea
                vurugu au kutangaza watu au mashirika hatari. Tunaweza
                kusimamisha au kufungia akaunti zinazotangaza vurugu nje ya
                jukwaa na kuripoti vitisho kwa mamlaka za kisheria. Kulinda
                jamii yetu, tunaweza kuzingatia taarifa kutoka kwa majukwaa
                mengine na nje ya mtandao katika kutambua watu na mashirika ya
                vurugu na ya kigaidi. Akaunti zinazopatikana zinahusishwa na
                vyombo kama hivyo zitafungwa.
              </p>
            </div>
            <div className="text_div">
              <h1>Vitisho na Kuchochea Vurugu</h1>
              <p>
                Kuchochea vurugu kunajumuisha kutetea au kuhamasisha wengine
                kutekeleza vurugu. Hatuwezi kuruhusu vitisho au kuchochea vurugu
                ambazo zinaweza kusababisha madhara makubwa ya kimwili.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                Kauli au picha zinazotetea vurugu za kimwili. <br />
                Wito wa kuleta silaha kwa ajili ya kutisha au kutishia watu
                binafsi au vikundi. <br />
                Watu na Mashirika Hatari <br />
                Haturuhusu watu au mashirika kwenye jukwaa letu ambao wanahusika
                katika vurugu. Hii inajumuisha wauaji wa umati, makundi ya
                chuki, mashirika ya uhalifu, mashirika ya kigaidi, na wengine.
              </p>
            </div>
            <div className="text_div">
              <h1>Mashirika ya Kigaidi na Chuki Iliyoandaliwa</h1>
              <p>
                Mashirika ya kigaidi na makundi ya chuki yaliyoandaliwa
                yanawalenga raia kwa malengo ya kisiasa, kidini, kikabila, au
                kiitikadi. Tunaondoa yaliyomo yanayohusiana na vyombo kama
                hivyo.
              </p>
            </div>
            <div className="text_div">
              <h1>Uhalifu Uliyoandaliwa</h1>
              <p>
                Mashirika ya uhalifu yanayohusika katika uhalifu mkubwa kama
                usafirishaji haramu au uhalifu wa kifedha hayaruhusiwi.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                Yaliyomo yanayounga mkono watu hatari au mashirika. <br />
                Yaliyomo ya kuajiri au kutangaza mashirika hatari. <br />
                Shughuli Haramu <br />
                Tunafanya kazi kuhakikisha TürkAfrica.Market haitoi nafasi kwa
                shughuli zinazokiuka sheria au kanuni. <br />
                Tunakataza yaliyomo yanayohusiana na shughuli za uhalifu.
              </p>
            </div>
            <div className="text_div">
              <h1>Shughuli za Uhalifu</h1>
              <p>
                Hii inajumuisha aina mbalimbali za vitendo haramu, kama wizi,
                unyonyaji wa binadamu, au uuzaji bandia.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                <ul>
                  <li>
                    Yaliyomo yanayotangaza madhara ya kimwili, unyonyaji wa
                    binadamu, au biashara haramu.
                  </li>
                </ul>
                Utapeli na Udanganyifu <br />
                Tunaondoa yaliyomo yanayodanganya watu kwa faida isiyo halali ya
                kifedha au binafsi, ikiwa ni pamoja na aina mbalimbali za
                utapeli.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                <ul>
                  <li>
                    Yaliyomo yanayohusiana na utapeli, mipango ya Ponzi, au
                    shughuli nyingine za udanganyifu.
                  </li>
                </ul>
                Faragha, Data Binafsi, na PII
                <br />
                Haturuhusu yaliyomo yanayokiuka usiri wa data binafsi au taarifa
                binafsi zinazoweza kutambulika (PII).
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                Yaliyomo yanayojumuisha data binafsi au PII. <br />
                Yaliyomo ya Vurugu na ya Kushtur
                <br />
                Haturuhusu yaliyomo yanayoshtua kupita kiasi au yanayotukuza
                vurugu.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                <ul>
                  <li>
                    Yaliyomo yanayoonyesha matukio ya vurugu au ya kushtua
                    yanayohusisha binadamu au wanyama.
                  </li>
                </ul>
                Kujiua, Kujidhuru, na Vitendo Hatari
                <br /> Tunapewa kipaumbele afya na ustawi wa wanajamii wetu.
                Hatuwezi kuruhusu yaliyomo yanayoweza kusababisha kujiua,
                kujidhuru, matatizo ya kula, au shughuli hatari.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                <ul>
                  <li>
                    Yaliyomo yanayohusiana na kujiua, kujiumiza, matatizo ya
                    ulaji, au vitendo hatari.
                  </li>
                </ul>
                Unyanyasaji na Uonevu <br />
                Tunajitahidi kwa jamii inayojumuisha na isiyo na unyanyasaji na
                uonevu.
              </p>
            </div>
            <div className="text_div">
              <h1>Tabia ya Unyanyasaji</h1>
              <p>
                Tunaondoa matamshi ya unyanyasaji, ikiwa ni pamoja na vitisho au
                kauli za kudharau.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                <ul>
                  <li>Yaliyomo yanayotukana, kubagua, au kunyanyasa watu.</li>
                </ul>
                Unyanyasaji wa Kingono
                <br />
                Unyanyasaji wa kingono kwa namna yoyote haukubaliki.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                <ul>
                  <li>
                    Yaliyomo yanayojumuisha tabia za kingono zisizotakiwa au
                    maoni.
                  </li>
                </ul>
                Vitisho vya Udukuzi, Doxxing, na Ulaghai <br />
                Tunaona tabia za mtandaoni kama doxxing na udukuzi kama aina za
                unyanyasaji.
              </p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                <ul>
                  <li>
                    Vitisho vya kufichua data binafsi au kujihusisha na udukuzi
                    au ulaghai.
                  </li>
                </ul>
                Uchi wa Watu Wazima na Shughuli za Kingono
                <br /> Tunakataza ponografia na maudhui ya kingono waziwazi.
              </p>
            </div>
            <div className="text_div">
              <h1>Unyonyaji wa Kingono</h1>
              <p>Maudhui yanayoonyesha unyonyaji wa kingono hayaruhusiwi.</p>
            </div>
            <div className="text_div">
              <h1>Usipakie, usitume, usirushie, au ushiriki:</h1>
              <p>
                Yaliyomo yanayoonyesha vitendo vya kingono visivyo na ridhaa au
                uchi. <br />
                Uaminifu na Uhalisia <br />
                Tunadumisha uhalisia wa jukwaa letu na kuzuia shughuli
                zinazoharibu uaminifu wake.
              </p>
            </div>
            <div className="text_div">
              <h1>Spam na Ushiriki wa Bandia</h1>
              <p>Tunakataza uchochezi bandia wa metriki za ushiriki.</p>
            </div>
            <div className="text_div">
              <h1>Kuigiza</h1>
              <p>
                Haturuhusu udanganyifu wa kuigiza lakini tunaruhusu akaunti za
                dhihaka au maoni ikiwa zimewekwa wazi.
              </p>
            </div>
            <div className="text_div">
              <h1>Upotoshaji</h1>
              <p>Hatukubali upotoshaji wenye madhara.</p>
            </div>
            <div className="text_div">
              <h1>Ukiukaji wa Haki Miliki</h1>
              <p>
                Tunaheshimu haki miliki na kuondoa yaliyomo yanayokiuka haki
                hizo.
                <br />
                Ahadi za Jumla
                <br />
                <ul>
                  <li>
                    <h1>Usalama:</h1> Ahadi ya kufanya TurkAfrica.Market kuwa
                    mahali salama.
                  </li>
                  <li>
                    <h1>Faragha:</h1> Kulinda faragha binafsi na taarifa.
                  </li>
                  <li>
                    <h1>Heshima:</h1> Kuheshimu utu na haki za watu wote.
                  </li>
                </ul>
                Miongozo hii inatumika kimataifa kwa yaliyomo yote na imeundwa
                kuwa kamili. Tunaelewa umuhimu wa muktadha na tunajitahidi
                kutumia sera zetu kwa usawa na haki. Watumiaji wanaweza kuripoti
                ukiukaji, na tunaweza kuarifu mamlaka za kisheria katika kesi za
                hatari halisi.
              </p>
            </div>
          </>
        ) : lang == "/Spanish" ? (
          <>
            <div className="sub_title_heading">
              <h6>Actualizado el 22 de diciembre de 2023</h6>
            </div>
            <div className="text_div">
              <h1>Discriminación, Odio y Antisemitismo</h1>
              <p>
                TürkAfrica.Market es una plataforma inclusiva con tolerancia
                cero hacia la discriminación, el odio, el racismo y el
                antisemitismo. No permitimos contenido que contenga discursos de
                odio o promueva comportamientos odiosos. Las cuentas que
                participen en discursos de odio o estén asociadas con discursos
                de odio fuera de nuestra plataforma serán suspendidas o
                prohibidas. <br />
                Definimos el discurso de odio o comportamiento odioso como
                contenido que ataca, amenaza, incita a la violencia contra, o de
                otro modo deshumaniza a un individuo o un grupo basado en
                atributos protegidos: Casta, Discapacidad, Etnia, Género, Origen
                nacional, Raza, Religión y Orientación sexual.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                <ul>
                  <li>
                    Contenido odioso relacionado con un individuo o grupo,
                    incluyendo afirmaciones de inferioridad, llamados a la
                    violencia, acusaciones criminales o comparaciones
                    deshumanizantes.
                  </li>
                  <li>
                    Contenido que represente daño a individuos o grupos basado
                    en atributos protegidos.
                  </li>
                </ul>
                Ideología de Odio
                <br />
                Las ideologías de odio muestran una clara hostilidad hacia las
                personas debido a sus atributos protegidos. Estas son
                incompatibles con nuestra comunidad inclusiva y de apoyo, y
                eliminamos contenido que las promueva.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                <ul>
                  <li>
                    Contenido que alabe, promueva, glorifique o apoye cualquier
                    ideología de odio.
                  </li>
                  <li>
                    Contenido relacionado con ideologías de odio, incluyendo
                    símbolos, consignas, música u otros objetos.
                  </li>
                  <li>
                    Contenido que niegue eventos violentos bien documentados que
                    afecten a grupos con atributos protegidos o haga
                    afirmaciones de supremacía.
                  </li>
                  <li>
                    Teorías no factuales que justifiquen ideologías de odio..
                  </li>
                </ul>
                Extremismo <br />
                Nos oponemos firmemente a la violencia dentro y fuera de
                TürkAfrica.Market. No permitimos que nuestra plataforma se
                utilice para amenazar o incitar a la violencia, ni para promover
                individuos u organizaciones peligrosas. Podemos suspender o
                prohibir cuentas que promuevan violencia fuera de la plataforma
                e informar amenazas a las autoridades legales. Para proteger
                nuestra comunidad, podemos considerar información de otras
                plataformas y fuera de línea para identificar individuos u
                organizaciones violentas y extremistas. Las cuentas asociadas
                con tales entidades serán cerradas.
              </p>
            </div>
            <div className="text_div">
              <h1>Amenazas e Incitación a la Violencia</h1>
              <p>
                La incitación a la violencia incluye abogar o alentar a otros a
                cometer violencia. No permitimos amenazas o incitación a la
                violencia que puedan conducir a daños físicos graves.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                Declaraciones o imágenes que aboguen por la violencia física.{" "}
                <br />
                Llamados a traer armas para intimidar o amenazar a individuos o
                grupos. <br />
                Individuos y Organizaciones Peligrosas <br />
                No permitimos individuos u organizaciones en nuestra plataforma
                que estén involucrados en violencia. Esto incluye a asesinos
                masivos, grupos de odio, organizaciones criminales,
                organizaciones terroristas y otros.
              </p>
            </div>
            <div className="text_div">
              <h1>Organizaciones Terroristas y Odio Organizado</h1>
              <p>
                Las organizaciones terroristas y los grupos de odio organizado
                atacan a civiles por objetivos políticos, religiosos, étnicos o
                ideológicos. Eliminamos contenido relacionado con tales
                entidades.
              </p>
            </div>
            <div className="text_div">
              <h1>Crimen Organizado</h1>
              <p>
                Las organizaciones criminales involucradas en crímenes graves
                como tráfico o delitos financieros no están permitidas.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                Contenido que apoye a individuos u organizaciones peligrosas.{" "}
                <br />
                Contenido de reclutamiento o promocional para organizaciones
                peligrosas. <br />
                Actividades Ilegales <br />
                Trabajamos para asegurar que TürkAfrica.Market no permita
                actividades que violen leyes o regulaciones. <br />
                Prohibimos contenido relacionado con actividades criminales.
              </p>
            </div>
            <div className="text_div">
              <h1>Actividades Criminales</h1>
              <p>
                Esto incluye una amplia gama de actos ilegales, como robo,
                explotación humana o falsificación.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                <ul>
                  <li>
                    Contenido que promueva daño físico, explotación humana o
                    comercio ilegal.
                  </li>
                </ul>
                Fraudes y Estafas <br />
                Eliminamos contenido que engañe a las personas para obtener
                ganancias financieras o personales ilegales, incluyendo varios
                tipos de estafas.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                <ul>
                  <li>
                    Contenido relacionado con phishing, esquemas Ponzi u otras
                    actividades fraudulentas.
                  </li>
                </ul>
                Privacidad, Datos Personales y PII <br />
                No permitimos contenido que viole la confidencialidad de datos
                personales o información personal identificable (PII).
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                Contenido que contenga datos personales o PII. <br />
                Contenido Violento y Gráfico <br />
                No permitimos contenido que sea excesivamente impactante o que
                glorifique la violencia.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                <ul>
                  <li>
                    Contenido que represente eventos violentos o gráficos que
                    involucren a humanos o animales.
                  </li>
                </ul>
                Suicidio, Autolesiones y Actos Peligrosos
                <br /> Priorizamos la salud y el bienestar de los miembros de
                nuestra comunidad. No permitimos contenido que pueda conducir al
                suicidio, autolesiones, trastornos alimentarios o actividades
                peligrosas.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                <ul>
                  <li>
                    Contenido relacionado con el suicidio, autolesión,
                    trastornos alimentarios o actos peligrosos.
                  </li>
                </ul>
                Acoso y Bullying
                <br />
                Nos esforzamos por una comunidad inclusiva libre de acoso y
                bullying.
              </p>
            </div>
            <div className="text_div">
              <h1>Comportamiento Abusivo</h1>
              <p>
                Eliminamos expresiones de abuso, incluyendo amenazas o
                declaraciones despectivas.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                <ul>
                  <li>Contenido que insulte, intimide o acose a individuos.</li>
                </ul>
                Acoso Sexual <br /> No se tolera el acoso sexual en ninguna de
                sus formas.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                <ul>
                  <li>
                    Contenido que incluya comportamiento sexual no deseado o
                    comentarios.
                  </li>
                </ul>
                Amenazas de Hacking, Doxxing y Chantaje <br />
                Consideramos comportamientos en línea como doxxing y hacking
                como formas de abuso.
              </p>
            </div>
            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                <ul>
                  <li>
                    • Amenazas para revelar datos personales o participar en
                    hacking o chantaje.
                  </li>
                </ul>
                Nudez de Adultos y Actividades Sexuales
                <br /> Prohibimos la pornografía y el contenido sexualmente
                explícito.
              </p>
            </div>
            <div className="text_div">
              <h1>Explotación Sexual</h1>
              <p>SNo se permite contenido sexualmente explotador.</p>
            </div>

            <div className="text_div">
              <h1>No publique, cargue, transmita o comparta:</h1>
              <p>
                Contenido que represente actos sexuales no consensuales o
                desnudez. <br />
                Integridad y Autenticidad <br />
                Mantenemos la autenticidad de nuestra plataforma y prevenimos
                actividades que socaven su integridad.
              </p>
            </div>
            <div className="text_div">
              <h1>Spam y Participación Falsa</h1>
              <p>
                Prohibimos la manipulación artificial de métricas de
                participación.
              </p>
            </div>
            <div className="text_div">
              <h1>Suplantación de Identidad</h1>
              <p>
                No permitimos la suplantación de identidad engañosa, pero
                permitimos cuentas de parodia o comentario si están claramente
                indicadas.
              </p>
            </div>
            <div className="text_div">
              <h1>Desinformación</h1>
              <p>We do not permit harmful misinformation.</p>
            </div>
            <div className="text_div">
              <h1>No permitimos desinformación dañina.</h1>
              <p>
                Violaciones de la Propiedad Intelectual Respetamos los derechos
                de propiedad intelectual y eliminamos contenido que infrinja
                estos derechos.
                <br />
                Compromisos Generales
                <ul>
                  <li>
                    <h1>Seguridad:</h1> Compromiso de hacer de TurkAfrica.Market
                    un lugar seguro.
                  </li>
                  <li>
                    <h1>Privacidad:</h1> Protección de la privacidad personal e
                    información.
                  </li>
                  <li>
                    <h1>Dignidad:</h1> Respeto a la dignidad y derechos de todas
                    las personas.
                  </li>
                </ul>
                Estas guías se aplican globalmente a todo el contenido y están
                diseñadas para ser integrales. Entendemos que el contexto
                importa y nos esforzamos por aplicar nuestras políticas de
                manera consistente y justa. Los usuarios pueden reportar
                violaciones, y podemos notificar a las autoridades legales en
                casos de riesgo genuino.
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="sub_title_heading">
              <h6>Updated on December 22, 2023</h6>
            </div>
            <div className="text_div">
              <h1>Discrimination, Hate, and Anti-Semitism</h1>
              <p>
                TurkAfrica.Market is an inclusive platform with zero tolerance
                for discrimination, hate, racism, and anti-Semitism. We do not
                permit content that contains hate speech or promotes hateful
                behavior. Accounts engaging in hate speech or associated with
                hate speech off our platform will be suspended or banned. <br />
                We define hate speech or hateful behavior as content that
                attacks, threatens, incites violence against, or otherwise
                dehumanizes an individual or a group based on protected
                attributes: Caste, Disability, Ethnicity, Gender, National
                origin, Race, Religion, and Sexual orientation.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Hateful content related to an individual or group, including
                    claims of inferiority, calls for violence, criminal
                    accusations, or dehumanizing comparisons.
                  </li>
                  <li>
                    Content depicting harm to individuals or groups based on
                    protected attributes.
                  </li>
                </ul>
                Hateful Ideology <br />
                Hateful ideologies show clear hostility toward people because of
                their protected attributes. These are incompatible with our
                inclusive and supportive community, and we remove content
                promoting them.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Content praising, promoting, glorifying, or supporting any
                    hateful ideology.
                  </li>
                  <li>
                    Content related to hateful ideologies, including symbols,
                    slogans, music, or other objects.
                  </li>
                  <li>
                    Content denying well-documented violent events affecting
                    groups with protected attributes or making supremacy claims.
                  </li>
                  <li>Non-factual theories justifying hateful ideologies.</li>
                </ul>
                Extremism <br />
                We firmly oppose violence on or off TurkAfrica.Market. We do not
                allow our platform to be used to threaten or incite violence or
                to promote dangerous individuals or organizations. We may
                suspend or ban accounts promoting off-platform violence and
                report threats to legal authorities. To protect our community,
                we may consider information from other platforms and offline in
                identifying violent and extremist individuals or organizations.
                Accounts found to be associated with such entities will be
                closed.
              </p>
            </div>
            <div className="text_div">
              <h1>Threats and Incitement to Violence</h1>
              <p>
                Incitement to violence includes advocating for or encouraging
                others to commit violence. We do not allow threats or incitement
                to violence that may lead to serious physical harm.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                Statements or imagery advocating physical violence. <br />
                Calls to bring weapons to intimidate or threaten individuals or
                groups. <br />
                Dangerous Individuals and Organizations <br />
                We do not allow individuals or organizations on our platform who
                are involved in violence. This includes mass murderers, hate
                groups, criminal organizations, terrorist organizations, and
                others.
              </p>
            </div>
            <div className="text_div">
              <h1>Terrorist Organizations and Organised Hate</h1>
              <p>
                Terrorist organizations and organized hate groups target
                civilians for political, religious, ethnic, or ideological
                objectives. We remove content related to such entities.
              </p>
            </div>
            <div className="text_div">
              <h1>Organised Crime</h1>
              <p>
                Criminal organizations involved in serious crimes like
                trafficking or financial crimes are not permitted.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                Content supporting dangerous individuals or organizations.{" "}
                <br />
                Recruitment or promotional content for dangerous organizations.{" "}
                <br />
                Illegal Activities <br />
                We work to ensure TurkAfrica.Market does not enable activities
                that violate laws or regulations. <br />
                We prohibit content related to criminal activities.
              </p>
            </div>
            <div className="text_div">
              <h1>Criminal Activities</h1>
              <p>
                This includes a wide range of unlawful acts, such as theft,
                human exploitation, or counterfeiting.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Content promoting physical harm, human exploitation, or
                    illegal trade.
                  </li>
                </ul>
                Frauds and Scams <br />
                We remove content deceiving people for unlawful financial or
                personal gain, including various types of scams.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Content related to phishing, Ponzi schemes, or other
                    fraudulent activities.
                  </li>
                </ul>
                Privacy, Personal Data, and PII <br />
                We do not allow content that violates the confidentiality of
                personal data or personally identifiable information (PII).
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                Content containing personal data or PII. <br />
                Violent and Graphic Content <br />
                We do not permit content that is excessively shocking or that
                glorifies violence.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Content depicting violent or graphic events involving humans
                    or animals.
                  </li>
                </ul>
                Suicide, Self-Harm, and Dangerous Acts <br /> We prioritize the
                health and well-being of our community members. We do not allow
                content that could lead to suicide, self-harm, eating disorders,
                or dangerous activities.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Content related to suicide, self-harm, eating disorders, or
                    dangerous acts.
                  </li>
                </ul>
                Harassment and Bullying <br />
                We strive for an inclusive community free from harassment and
                bullying.
              </p>
            </div>
            <div className="text_div">
              <h1>Abusive Behavior</h1>
              <p>
                We remove expressions of abuse, including threats or derogatory
                statements.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Content that insults, intimidates, or harasses individuals.
                  </li>
                </ul>
                Sexual Harassment <br /> Sexual harassment in any form is not
                tolerated.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Content that includes unwanted sexual behavior or
                    commentary.
                  </li>
                </ul>
                Threats of Hacking, Doxxing, and Blackmail <br />
                We consider online behaviors like doxxing and hacking as forms
                of abuse.
              </p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                <ul>
                  <li>
                    Threats to reveal personal data or engage in hacking or
                    blackmail.
                  </li>
                </ul>
                Adult Nudity and Sexual <br /> Activitie We prohibit pornography
                and sexually explicit content
              </p>
            </div>
            <div className="text_div">
              <h1>Sexual Exploitation</h1>
              <p>Sexually exploitative content is not permitted.</p>
            </div>
            <div className="text_div">
              <h1>Do not post, upload, stream, or share:</h1>
              <p>
                Content depicting non-consensual sexual acts or nudity. <br />
                Integrity and Authenticity <br />
                We maintain the authenticity of our platform and prevent
                activities undermining its integrity.
              </p>
            </div>
            <div className="text_div">
              <h1>Spam and Fake Engagement</h1>
              <p>We prohibit artificial manipulation of engagement metrics.</p>
            </div>
            <div className="text_div">
              <h1>Impersonation</h1>
              <p>
                We do not allow deceptive impersonation but permit parody or
                commentary accounts if clearly indicated.
              </p>
            </div>
            <div className="text_div">
              <h1>Misinformation</h1>
              <p>We do not permit harmful misinformation.</p>
            </div>
            <div className="text_div">
              <h1>Intellectual Property Violations</h1>
              <p>
                We respect intellectual property rights and remove content that
                infringes these rights. <br />
                General Commitments <br />
                <ul>
                  <li>
                    <h1>Safety:</h1>Commitment to making TurkAfrica.Market a
                    safe place.
                  </li>
                  <li>
                    <h1>Privacy:</h1>Protecting personal privacy and
                    information.
                  </li>
                  <li>
                    <h1>Dignity:</h1>Respecting the dignity and rights of all
                    people.
                  </li>
                </ul>
                These guidelines apply globally to all content and are designed
                to be comprehensive. We understand that context matters and
                strive to apply our policies consistently and fairly. Users can
                report violations, and we may notify law enforcement in cases of
                genuine risk.
              </p>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
export const PrivacyPolicyCookiesmodal = () => {
  const { FourModalShow, setFourModalShow } = useContext(GlobalContext);
  const [lang, setlang] = useState();
  const pathname = useLocation();
  useEffect(() => {
    setlang(pathname.pathname);
  }, [pathname]);
  return (
    <Modal show={FourModalShow} onHide={setFourModalShow} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="newtitle">
          <h3 style={{ textAlign: "center" }}>
            {lang == "/Turkish"
              ? "TürkAfrica.Market Gizlilik Politikası"
              : lang == "/French"
              ? "Politique de confidentialité et politique de cookies de TürkAfrica.Market"
              : lang == "/Portuguese"
              ? "Türkafrica.Market Política de privacidade e cookies "
              : lang == "/Swahili"
              ? "Sera ya Faragha ya TürkAfrica.Market"
              : lang == "/Spanish"
              ? "Política de privacidad y cookies de TürkAfrica.Market"
              : "TürkAfrica.Market Privacy Policy & Cookies"}
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="Modalbody">
        {lang == "/Turkish" ? (
          <>
            <Modal.Title>Türkafrica.market Gizlilik Politikası</Modal.Title>
            <div className="sub_title_heading">
              <h6>22 Aralık 2023 tarihinde güncellendi</h6>
            </div>
            <div className="text_div">
              <h1>1. Giriş</h1>
              <p>
                TürkAfrica.Market'a hoş geldiniz, güçlü iş bağlantıları
                geliştirmeye ve kapsamlı e-ticaret çözümleri sunmaya adanmış
                önde gelen bir B2B ve B2C platformu. Bu Gizlilik Politikası,
                kişisel bilgilerinizi nasıl topladığımızı, kullandığımızı,
                koruduğumuzu ve paylaştığımızı açıklar.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Bilgi Toplama</h1>
              <p>
                Platformumuzda kayıt olduğunuzda, sipariş verdiğinizde veya
                hizmetlerimizle etkileşimde bulunduğunuzda verdiğiniz kişisel
                bilgileri toplarız. Bu şunları içerir: <br />
                Kişisel Tanımlama Bilgileri: İsim, e-posta adresi, telefon
                numarası, iş detayları. <br />• İşlemsel Bilgi: Platformumuz
                aracılığıyla gerçekleştirdiğiniz işlemlerin ve siparişlerin
                detayları. <br />
                Kullanım Verileri: Web sitemizi ve hizmetlerimizi nasıl
                kullandığınıza dair bilgiler.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Veri Kullanımı</h1>
              <p>
                Verileriniz şunlar için kullanılır: <br />
                Hizmetleri Sağlamak: İşlemleri işlemek ve müşteri desteği
                sağlamak.
                <br />
                Platformumuzu Geliştirmek: Kullanıcı deneyimini geliştirmek ve
                yeni özellikler geliştirmek. <br />
                Pazarlama: Sadece onayınızla tanıtım mesajları ve teklifler
                göndermek.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Veri Paylaşımı ve Açıklama</h1>
              <p>
                Bilgilerinizi şunlarla paylaşabiliriz:
                <br />
                Hizmet Sağlayıcılar: İş operasyonlarında yardımcı olan ortaklar
                (örn. ödeme işleme, lojistik). <br />
                Yasal Yükümlülükler: Yasalar gerektirdiğinde veya haklarımızı
                korumak için.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Veri Güvenliği</h1>
              <p>
                Verilerinizi yetkisiz erişim ve ihlallerden korumak için
                şifreleme ve erişim kontrolleri gibi sağlam güvenlik önlemleri
                uyguluyoruz.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Veri Saklama</h1>
              <p>
                Hizmetlerimizi sağlamak veya yasalar gerektirdiği sürece kişisel
                verilerinizi saklarız, ardından güvenli bir şekilde silinir veya
                anonimleştirilir.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Kullanıcı Hakları</h1>
              <p>
                UK GDPR altında, kişisel verilerinize erişme, düzeltme, silme
                veya kullanımını kısıtlama hakkına sahipsiniz. Bu haklarınızı
                kullanmak için bize başvurabilirsiniz.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Uluslararası Veri Transferleri</h1>
              <p>
                Verileri İngiltere dışına aktardığımızda, yeterli korumayı
                sağladığımızı ve yasal çerçevelere uyduğumuzu garanti ederiz.
              </p>
            </div>
            <div className="text_div">
              <h1>9. İletişim Bilgileri</h1>
              <p>
                Gizlilikle ilgili herhangi bir soru için lütfen [
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setFourModalShow(false)}>
                  Buraya Tıklayın
                </Link>
                ] adresinden bize ulaşın.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Politika Güncellemeleri</h1>
              <p>
                Bu politikayı güncelleyebilir ve önemli değişiklikleri
                platformumuz üzerinden bildirebiliriz.
              </p>
            </div>
            <Modal.Title>TürkAfrica.Market Çerez Politikası</Modal.Title>
            <div className="sub_title_heading">
              <h6>Updated on December 22, 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Giriş </h1>
              <p>
                Çerezler, kullanıcı deneyimini geliştirmek için kullanılan küçük
                metin dosyalarıdır. Çerez Politikamız, platformumuzda
                kullanımlarını açıklar.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Kullanılan Çerez Türleri </h1>
              <p>
                Temel Çerezler: Platformun işlevselliği için gerekli. <br />
                Analitik Çerezler: Hizmetlerimizi geliştirmek için kullanım
                verilerini toplar. <br />
                Pazarlama Çerezleri: Reklamları kişiselleştirmek için
                kullanılır.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Çerezleri Yönetme </h1>
              <p>
                Çerez tercihlerinizi tarayıcı ayarlarınızdan yönetebilirsiniz.{" "}
                <br />
                Zorunlu olmayan çerezler için izin, platformumuzu ilk
                ziyaretinizde alınır.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Çerez Politikası Değişiklikleri</h1>
              <p>
                Bu politikayı güncelleyebilir ve platformumuzda bildirimde
                bulunabiliriz.
              </p>
            </div>
            <div className="text_div">
              <h1>5. İletişim Bilgileri</h1>
              <p>
                Çerez Politikamızla ilgili sorularınız için lütfen [
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setFourModalShow(false)}>
                  Buraya Tıklayın
                </Link>
                ] adresinden bize ulaşın.
              </p>
            </div>
          </>
        ) : lang == "/French" ? (
          <>
            <Modal.Title>
              Politique de Confidentialité de TürkAfrica.Market
            </Modal.Title>
            <div className="sub_title_heading">
              <h6>Mis à jour le 22 décembre 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                Bienvenue sur TürkAfrica.Market, une plateforme B2B et B2C de
                premier plan dédiée à renforcer les connexions commerciales et à
                fournir des solutions complètes de commerce électronique. Cette
                Politique de Confidentialité décrit comment nous collectons,
                utilisons, protégeons et partageons vos informations
                personnelles.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Collecte d'Informations </h1>
              <p>
                Nous collectons des informations personnelles que vous
                fournissez lors de votre inscription sur notre plateforme, de
                vos commandes ou de vos interactions avec nos services. Cela
                inclut : <br />
                Informations Transactionnelles : Détails des transactions et
                commandes effectuées via notre plateforme.
                <br />
                Données d'Utilisation : Informations sur la façon dont vous
                utilisez notre site Web et nos services.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Utilisation des Données</h1>
              <p>
                Vos données sont utilisées pour : <br />
                Fournir des Services : Traiter les transactions et fournir un
                support client. <br />
                Améliorer Notre Plateforme : Améliorer l'expérience utilisateur
                et développer de nouvelles fonctionnalités. <br />
                Marketing : Envoyer des messages promotionnels et des offres,
                uniquement avec votre consentement.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Partage et Divulgation des Données</h1>
              <p>
                Nous pouvons partager vos informations avec : <br />
                Prestataires de Services : Partenaires qui assistent dans les
                opérations commerciales (par exemple, traitement des paiements,
                logistique). <br />
                Obligations Légales : Lorsque requis par la loi ou pour protéger
                nos droits.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Sécurité des Données</h1>
              <p>
                Nous mettons en œuvre des mesures de sécurité robustes telles
                que le cryptage et les contrôles d'accès pour protéger vos
                données contre les accès non autorisés et les violations.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Conservation des Données</h1>
              <p>
                Nous conservons vos données personnelles aussi longtemps que
                nécessaire pour fournir nos services ou tel que requis par la
                loi, après quoi elles sont supprimées de manière sécurisée ou
                anonymisées.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Droits de l'Utilisateur</h1>
              <p>
                Sous le RGPD du Royaume-Uni, vous avez le droit d'accéder, de
                corriger, de supprimer ou de restreindre l'utilisation de vos
                données personnelles. Vous pouvez exercer ces droits en nous
                contactant.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Transferts de Données Internationaux</h1>
              <p>
                Si nous transférons des données hors du Royaume-Uni, nous
                assurons une protection adéquate et le respect des cadres
                juridiques.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Informations de Contact</h1>
              <p>
                Pour toute question relative à la confidentialité, veuillez nous
                contacter à [{" "}
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setFourModalShow(false)}>
                  Cliquez Ici
                </Link>
                ].
              </p>
            </div>
            <div className="text_div">
              <h1>10. Mises à Jour de la Politique</h1>
              <p>
                Nous pouvons mettre à jour cette politique et vous en
                informerons de tout changement significatif via notre
                plateforme.
              </p>
            </div>
            <Modal.Title>Politique de Cookies de TürkAfrica.Market</Modal.Title>
            <div className="sub_title_heading">
              <h6>Mis à jour le 22 décembre 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction </h1>
              <p>
                Les cookies sont de petits fichiers texte utilisés pour
                améliorer l'expérience utilisateur. Notre Politique de Cookies
                explique leur utilisation sur notre plateforme.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Types de Cookies Utilisés </h1>
              <p>
                Cookies Essentiels : Nécessaires pour la fonctionnalité de la
                plateforme. <br />
                Cookies Analytiques : Collectent des données d'utilisation pour
                améliorer nos services.
                <br />
                Cookies Marketing : Utilisés pour personnaliser la publicité.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Gestion des Cookies </h1>
              <p>
                Vous pouvez gérer vos préférences de cookies via les paramètres
                de votre navigateur. <br />
                Le consentement pour les cookies non essentiels est obtenu lors
                de votre première visite sur notre plateforme.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Modifications de la Politique de Cookies </h1>
              <p>
                Nous pouvons mettre à jour cette politique et fournirons un avis
                sur notre plateforme.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Informations de Contact </h1>
              <p>
                Pour toute question concernant notre Politique de Cookies,
                contactez [{" "}
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setFourModalShow(false)}>
                  Cliquez Ici
                </Link>
                ].
              </p>
            </div>
          </>
        ) : lang == "/Portuguese" ? (
          <>
            <Modal.Title>
              Política de Privacidade da TürkAfrica.Market
            </Modal.Title>
            <div className="sub_title_heading">
              <h6>Atualizado em 22 de dezembro de 2023.</h6>
            </div>
            <div className="text_div">
              <h1>1. Introdução</h1>
              <p>
                Bem-vindo à TürkAfrica.Market, uma plataforma líder B2B e B2C
                dedicada a promover fortes conexões comerciais e fornecer
                soluções abrangentes de comércio eletrônico. Esta Política de
                Privacidade descreve como coletamos, usamos, protegemos e
                compartilhamos suas informações pessoais.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Coleta de Informações</h1>
              <p>
                Coletamos informações pessoais que você fornece ao se registrar
                em nossa plataforma, fazer pedidos ou interagir com nossos
                serviços. Isso inclui: <br />
                Informações de Identificação Pessoal: Nome, endereço de e-mail,
                número de telefone, detalhes comerciais. Informações
                Transacionais: Detalhes das transações e pedidos realizados
                através de nossa plataforma.
                <br />
                Dados de Uso: Informações sobre como você usa nosso site e
                serviços.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Uso de Dados</h1>
              <p>
                Seus dados são usados para: <br />
                Cumprir Serviços: Processar transações e fornecer suporte ao
                cliente. <br />
                Melhorar Nossa Plataforma: Aprimorar a experiência do usuário e
                desenvolver novos recursos. <br />
                Marketing: Enviar mensagens promocionais e ofertas, somente com
                o seu consentimento.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Compartilhamento e Divulgação de Dados</h1>
              <p>
                Podemos compartilhar suas informações com: <br />
                Prestadores de Serviços: Parceiros que auxiliam nas operações
                comerciais (por exemplo, processamento de pagamentos,
                logística). <br />
                Obrigações Legais: Quando exigido por lei ou para proteger
                nossos direitos.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Segurança de Dados</h1>
              <p>
                Implementamos medidas de segurança robustas, como criptografia e
                controles de acesso, para proteger seus dados de acessos não
                autorizados e violações.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Retenção de Dados</h1>
              <p>
                Retemos seus dados pessoais pelo tempo necessário para fornecer
                nossos serviços ou conforme exigido por lei, após o qual são
                excluídos ou anonimizados de forma segura.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Direitos do Usuário</h1>
              <p>
                Sob o GDPR do Reino Unido, você tem o direito de acessar,
                corrigir, excluir ou restringir o uso de seus dados pessoais.
                Você pode exercer esses direitos entrando em contato conosco.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Transferências Internacionais de Dados</h1>
              <p>
                Se transferirmos dados para fora do Reino Unido, garantimos
                proteção adequada e conformidade com frameworks legais.
              </p>
            </div>
            <div className="text_div">
              <h1>9.Informações de Contato</h1>
              <p>
                Para quaisquer dúvidas relacionadas à privacidade, entre em
                contato conosco em [{" "}
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setFourModalShow(false)}>
                  Clique Aqui
                </Link>
                ].
              </p>
            </div>
            <div className="text_div">
              <h1>10. Atualizações da Política</h1>
              <p>
                Podemos atualizar esta política e notificaremos você sobre
                mudanças significativas através de nossa plataforma.
              </p>
            </div>
            <Modal.Title>Política de Cookies da TürkAfrica.Market</Modal.Title>
            <div className="sub_title_heading">
              <h6>Atualizado em 22 de dezembro de 2023.</h6>
            </div>
            <div className="text_div">
              <h1>1. Introdução </h1>
              <p>
                Cookies são pequenos arquivos de texto usados para aprimorar a
                experiência do usuário. Nossa Política de Cookies explica o uso
                deles em nossa plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Tipos de Cookies Usados </h1>
              <p>
                Cookies Essenciais: Necessários para a funcionalidade da
                plataforma. <br />
                Cookies de Análise: Coletam dados de uso para melhorar nossos
                serviços. <br />
                Cookies de Marketing: Usados para personalizar a publicidade.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Gerenciamento de Cookies </h1>
              <p>
                Você pode gerenciar suas preferências de cookies através das
                configurações do seu navegador. <br />O consentimento para
                cookies não essenciais é obtido em sua primeira visita à nossa
                plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Alterações na Política de Cookies</h1>
              <p>
                Podemos atualizar esta política e forneceremos aviso em nossa
                plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Informações de Contato </h1>
              <p>
                Para dúvidas sobre nossa Política de Cookies, entre em contato
                com [{" "}
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setFourModalShow(false)}>
                  Clique Aqui
                </Link>
                ].
              </p>
            </div>
          </>
        ) : lang == "/Swahili" ? (
          <>
            <Modal.Title>TürkAfrica.Market Privacy Policy </Modal.Title>
            <div className="sub_title_heading">
              <h6>Imesasishwa tarehe 22 Desemba, 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Utangulizi</h1>
              <p>
                Karibu kwenye TürkAfrica.Market, jukwaa la kipekee la B2B na B2C
                lililojitolea kuimarisha uhusiano wa kibiashara na kutoa
                suluhisho kamili la biashara ya mtandao. Sera hii ya Faragha
                inaelezea jinsi tunavyokusanya, kutumia, kulinda, na kushiriki
                taarifa yako binafsi.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Ukusanyaji wa Taarifa</h1>
              <p>
                Tunakusanya taarifa binafsi unazotoa unapojisajili kwenye jukwaa
                letu, kuweka maagizo, au kuingiliana na huduma zetu. Hii
                inajumuisha: <br />
                Taarifa za Utambulisho Binafsi: Jina, anwani ya barua pepe,
                nambari ya simu, maelezo ya biashara.Taarifa za Miamala: Maelezo
                ya miamala na maagizo unayofanya kupitia jukwaa letu. <br />
                Data ya Matumizi: Taarifa kuhusu jinsi unavyotumia tovuti yetu
                na huduma
              </p>
            </div>
            <div className="text_div">
              <h1>3. Matumizi ya Data</h1>
              <p>
                Data yako inatumika kwa: <br />
                Kutimiza Huduma: Kuchakata miamala na kutoa usaidizi kwa wateja.{" "}
                <br />
                Kuboresha Jukwaa Letu: Kuimarisha uzoefu wa mtumiaji na kukuza
                vipengele vipya. <br />
                Masoko: Kutuma ujumbe wa kibiashara na ofa, tu kwa idhini yako
              </p>
            </div>
            <div className="text_div">
              <h1>4. Ushirikiano na Ufunuo wa Data</h1>
              <p>
                Tunaweza kushiriki taarifa yako na: <br />
                Watoa Huduma: Washirika wanaosaidia katika operesheni za
                biashara (k.m. uchakataji wa malipo, vifaa vya usafirishaji).{" "}
                <br />
                Majukumu ya Kisheria: Tunapohitajika kisheria au kulinda haki
                zetu.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Usalama wa Data</h1>
              <p>
                Tunatekeleza hatua madhubuti za usalama kama usimbaji fiche na
                udhibiti wa ufikiaji kulinda data yako dhidi ya ufikiaji
                usioruhusiwa na uvunjaji.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Uhifadhi wa Data</h1>
              <p>
                Tunahifadhi data yako binafsi kwa muda mrefu kama inavyohitajika
                kutoa huduma zetu au kama inavyotakiwa na sheria, baada ya hapo
                inafutwa kwa usalama au kufanywa isiyoweza kutambulika.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Haki za Mtumiaji</h1>
              <p>
                Chini ya GDPR ya Uingereza, una haki ya kufikia, kusahihisha,
                kufuta, au kuzuia matumizi ya data yako binafsi. Unaweza
                kutekeleza haki hizi kwa kuwasiliana nasi.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Uhamishaji wa Data wa Kimataifa</h1>
              <p>
                IIkiwa tunahamisha data nje ya Uingereza, tunahakikisha ulinzi
                wa kutosha na kufuata mifumo ya kisheria.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Taarifa za Mawasiliano</h1>
              <p>
                Kwa maswali yoyote yanayohusiana na faragha, tafadhali wasiliana
                nasi kwa [
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setFourModalShow(false)}>
                  Bonyeza Hapa
                </Link>
                ].
              </p>
            </div>
            <div className="text_div">
              <h1>10. Maboresho ya Sera</h1>
              <p>
                Tunaweza kuboresha sera hii na tutakujulisha kuhusu mabadiliko
                makubwa kupitia jukwaa letu.
              </p>
            </div>
            <Modal.Title>Sera ya Kuki ya TürkAfrica.Market</Modal.Title>
            <div className="sub_title_heading">
              <h6>Imesasishwa tarehe 22 Desemba, 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Utangulizi </h1>
              <p>
                Kuki ni faili ndogo za maandishi zinazotumika kuboresha uzoefu
                wa mtumiaji. Sera yetu ya Kuki inafafanua matumizi yao kwenye
                jukwaa letu.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Aina za Kuki Zinazotumika </h1>
              <p>
                Kuki Muhimu: Zinahitajika kwa utendakazi wa jukwaa.
                <br />
                Kuki za Takwimu: Zinakusanya data ya matumizi kuboresha huduma
                zetu.
                <br />
                Kuki za Matangazo: Zinatumika kwa ajili ya kubinafsisha
                matangazo.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Usimamizi wa Kuki </h1>
              <p>
                Unaweza kudhibiti mapendeleo yako ya kuki kupitia mipangilio ya
                kivinjari chako. Idhini kwa kuki zisizo muhimu inapatikana
                unapotembelea jukwaa letu kwa mara ya kwanza.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Mabadiliko kwenye Sera ya Kuki</h1>
              <p>
                Tunaweza kuboresha sera hii na tutatoa taarifa kwenye jukwaa
                letu.
              </p>
            </div>
            <div className="text_div">
              <h1>5.Taarifa za Mawasiliano </h1>
              <p>
                Kwa maswali kuhusu Sera yetu ya Kuki, wasiliana na [
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setFourModalShow(false)}>
                  Bonyeza Hapa
                </Link>
                ].
              </p>
            </div>
          </>
        ) : lang == "/Spanish" ? (
          <>
            <Modal.Title>
              Política de Privacidad de TürkAfrica.Market
            </Modal.Title>
            <div className="sub_title_heading">
              <h6>Actualizado el 22 de diciembre de 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introducción</h1>
              <p>
                Bienvenido a TürkAfrica.Market, una plataforma líder B2B y B2C
                dedicada a fomentar fuertes conexiones comerciales y
                proporcionar soluciones integrales de comercio electrónico. Esta
                Política de Privacidad describe cómo recopilamos, usamos,
                protegemos y compartimos su información personal.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Recolección de Información</h1>
              <p>
                Recopilamos información personal que usted proporciona al
                registrarse en nuestra plataforma, realizar pedidos o
                interactuar con nuestros servicios. Esto incluye: <br />
                Información de Identificación Personal: Nombre, dirección de
                correo electrónico, número de teléfono, detalles comerciales.
                Información Transaccional: Detalles de las transacciones y
                pedidos que realiza a través de nuestra plataforma. <br />
                Datos de Uso: Información sobre cómo usa nuestro sitio web y
                servicios.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Uso de Datos</h1>
              <p>
                Sus datos se utilizan para:
                <br />
                Cumplir Servicios: Procesar transacciones y proporcionar soporte
                al cliente. <br />
                Mejorar Nuestra Plataforma: Mejorar la experiencia del usuario y
                desarrollar nuevas funciones.
                <br />
                Marketing: Enviar mensajes promocionales y ofertas, solo con su
                consentimiento.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Compartición y Divulgación de Datos</h1>
              <p>
                Podemos compartir su información con: <br />
                Proveedores de Servicios: Socios que ayudan en las operaciones
                comerciales (por ejemplo, procesamiento de pagos, logística).{" "}
                <br />
                Obligaciones Legales: Cuando lo exija la ley o para proteger
                nuestros derechos.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Seguridad de Datos</h1>
              <p>
                Implementamos medidas de seguridad robustas como encriptación y
                controles de acceso para proteger sus datos del acceso no
                autorizado y violaciones.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Retención de Datos</h1>
              <p>
                Retenemos sus datos personales el tiempo que sea necesario para
                proporcionar nuestros servicios o según lo requiera la ley,
                después de lo cual se elimina de manera segura o se anonimiza.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Derechos del Usuario</h1>
              <p>
                Bajo el GDPR del Reino Unido, tiene derecho a acceder, corregir,
                eliminar o restringir el uso de sus datos personales. Puede
                ejercer estos derechos contactándonos.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Transferencias Internacionales de Datos</h1>
              <p>
                Si transferimos datos fuera del Reino Unido, aseguramos una
                protección adecuada y cumplimiento con marcos legales.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Información de Contacto </h1>
              <p>
                Para cualquier consulta relacionada con la privacidad,
                contáctenos en [{" "}
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setFourModalShow(false)}>
                  Haz Clic Aquí
                </Link>
                ].
              </p>
            </div>
            <div className="text_div">
              <h1>10. Actualizaciones de la Política</h1>
              <p>
                Podemos actualizar esta política y le notificaremos sobre
                cambios significativos a través de nuestra plataforma.
              </p>
            </div>
            <Modal.Title>Política de Cookies de TürkAfrica.Market</Modal.Title>
            <div className="sub_title_heading">
              <h6>Actualizado el 22 de diciembre de 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introducción </h1>
              <p>
                Las cookies son pequeños archivos de texto utilizados para
                mejorar la experiencia del usuario. Nuestra Política de Cookies
                explica su uso en nuestra plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Tipos de Cookies Utilizadas </h1>
              <p>
                Cookies Esenciales: Necesarias para la funcionalidad de la
                plataforma. <br />
                Cookies de Análisis: Recopilan datos de uso para mejorar
                nuestros servicios. <br />
                Cookies de Marketing: Utilizadas para personalizar la
                publicidad.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Gestión de Cookies </h1>
              <p>
                Puede gestionar sus preferencias de cookies a través de la
                configuración de su navegador. <br />
                El consentimiento para cookies no esenciales se obtiene en su
                primera visita a nuestra plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Cambios en la Política de Cookies</h1>
              <p>
                Podemos actualizar esta política y proporcionaremos aviso en
                nuestra plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Información de Contacto</h1>
              <p>
                Para consultas sobre nuestra Política de Cookies, contacte a [{" "}
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setFourModalShow(false)}>
                  Haz Clic Aquí
                </Link>
                ].
              </p>
            </div>
          </>
        ) : (
          <>
            <Modal.Title>TürkAfrica.Market Privacy Policy </Modal.Title>
            <div className="sub_title_heading">
              <h6>Updated on December 22, 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                Welcome to TürkAfrica.Market, a leading B2B and B2C platform
                dedicated to fostering strong business connections and providing
                comprehensive e-commerce solutions. This Privacy Policy outlines
                how we collect, use, protect, and share your personal
                information.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Information Collection</h1>
              <p>
                We collect personal information that you provide when you
                register on our platform, place orders, or interact with our
                services. This includes: <br />
                Personal Identification Information: Name, email address, phone
                number, business details. Transactional Information: Details of
                the transactions and orders you carry out through our platform.{" "}
                <br />
                Usage Data: Information on how you use our website and services.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Use of Data</h1>
              <p>
                Your data is used to: Fulfill Services: <br />
                Process transactions and provide customer support. <br />
                Improve Our Platform: Enhance user experience and develop new
                features. <br />
                Marketing: Send promotional messages and offers, only with your
                consent.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Data Sharing and Disclosure</h1>
              <p>
                We may share your information with: <br />
                Service Providers: Partners who assist in business operations
                (e.g., payment processing, logistics). <br />
                Legal Obligations: When required by law or to protect our
                rights.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Data Security</h1>
              <p>
                We implement robust security measures like encryption and access
                controls to protect your data from unauthorized access and
                breaches.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Data Retention</h1>
              <p>
                We retain your personal data as long as necessary to provide our
                services or as required by law, after which it is securely
                deleted or anonymized.
              </p>
            </div>
            <div className="text_div">
              <h1>7. User Rights</h1>
              <p>
                Under the UK GDPR, you have the right to access, correct,
                delete, or restrict the use of your personal data. You can
                exercise these rights by contacting us.
              </p>
            </div>
            <div className="text_div">
              <h1>8. International Data Transfers</h1>
              <p>
                If we transfer data outside the UK, we ensure adequate
                protection and compliance with legal frameworks.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Contact Information</h1>
              <p>
                For any privacy-related inquiries, please contact us at [{" "}
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setFourModalShow(false)}>
                  Click Here
                </Link>
                ].
              </p>
            </div>
            <div className="text_div">
              <h1>10. Policy Updates</h1>
              <p>
                We may update this policy and will notify you of significant
                changes through our platform.
              </p>
            </div>
            <Modal.Title>TürkAfrica.Market Cookies</Modal.Title>
            <div className="sub_title_heading">
              <h6>Updated on December 22, 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction </h1>
              <p>
                Cookies are small text files used to enhance user experience.
                Our Cookie Policy explains their use on our platform.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Types of Cookies Used </h1>
              <p>
                Essential Cookies: Necessary for the platform's functionality.{" "}
                <br />
                Analytics Cookies: Collect usage data to improve our services.{" "}
                <br />
                Marketing Cookies: Used for personalizing advertising.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Managing Cookies </h1>
              <p>
                You can manage your cookie preferences through your browser
                settings. <br />
                Consent for nonessential cookies is obtained upon your first
                visit to our platform.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Changes to Cookie Policy </h1>
              <p>
                We may update this policy and will provide notice on our
                platform.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Contact Information </h1>
              <p>
                For queries about our Cookie Policy, contact [{" "}
                <Link
                  to="Contact"
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "#e30a17",
                    fontWeight: "700",
                  }}
                  onClick={() => setFourModalShow(false)}>
                  Click Here
                </Link>
                ].
              </p>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
export const Downloadmodal = ({ show, handelClose }) => {
  // const { FiveModalShow, setFiveModalShow } = useContext(GlobalContext);

  // const handleClose = () => {e30a17
  //   setFiveModalShow(false);
  // };
  return (
    <Modal show={show} onHide={handelClose} size="lg" centered>
      <Modal.Header closeButton className="heading_style">
        <Modal.Title className="newtitle">
          <h3 style={{ textAlign: "center" }}>
            Ready to transform your journey with TurkAfrica?
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body closeButton className="modelbg">
        <div className="store_div_download_section_model">
          <p>
            Use your <span>TurkAfrica B2B </span> credentials to log in or scan
            to download the app. Discover the Blog and Academy packed with
            powerful resources to boost your growth. <br />
            <span>The time to act is now! </span>
          </p>
          <div className="Qr_code_div">
            <img src={QRcode} alt="" />
          </div>
          <p>or</p>
          <div
            className="store_img"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <div className="tooltip-container">
              <span className="tooltip">Dwonload now</span>

              <a
                className="text store_img"
                href="https://apps.apple.com/app/turkafrica-b2b-app/id6475347626"
                target="_blank"
                rel="noopener noreferrer">
                <img src={applelogo} alt="Apple Store" />
              </a>
            </div>
            <div className="tooltip-container">
              <span className="tooltip">Dwonload now</span>

              <a
                className="text store_img"
                href="https://play.google.com/store/apps/details?id=com.amin.turk_africa"
                target="_blank"
                rel="noopener noreferrer">
                <img src={playlogo} alt="Google Play Store" />
              </a>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const LogoutModel = ({ show, handelClose }) => {
  const { LogoutModalshow, setLogoutModalshow } = useContext(GlobalContext);
  const navigate = useNavigate();
  const handleClose = () => {
    setLogoutModalshow(false);
  };
  const MYToken = JSON.parse(localStorage.getItem("MYToken"));

  const handleLogout = () => {
    setLogoutModalshow(false);
    // localStorage.removeItem("DeviceToken");
    localStorage.removeItem("MYToken");
    localStorage.removeItem("Userdata");
    localStorage.removeItem("checkSubscription");
    navigate("/");
  };
  // const handleLogout = async () => {
  //   // console.log("🚀 ~ handleLogout ~ handleLogout:");
  //   // try {
  //   //   const response = await axios.get("auth/logout", {
  //   //     headers: {
  //   //       Authorization: `Bearer ${MYToken}`,
  //   //     },
  //   //   });
  //   //   navigate("/Login");
  //   //   setLogoutModalshow(false);
  //   //   localStorage.removeItem("Userdata");
  //   //   console.log("🚀 ~ handleLogout ~ response:", response);
  //   // } catch (error) {
  //   //   console.log("🚀 ~ handleLogout ~ error:", error);
  //   // }
  // };

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={handelClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
        <Modal.Body closeButton className="modelbg">
          <Modal.Title style={{ textAlign: "center" }}>Log out !</Modal.Title>
          <p>Are you sure, you want to Logout ?</p>

          <div className="btn_of_delte_model">
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <button
              onClick={() => handleLogout()}
              className="btn_primry_fp"
              style={{ textAlign: "center" }}>
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
