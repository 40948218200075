import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.css";

const Index = () =>
{
  const [lang, setlang] = useState();
  console.log("🚀 ~ Index ~ lang:", lang);
  const pathname = useLocation();
  const getname = pathname?.state?.pathname?.pathname;
  useEffect(() =>
  {
    setlang(getname);
  }, [pathname]);
  const Navigation = useNavigate();

  return (
    <div className="main_div">
      <div className="arrow">
        <button
          onClick={() =>
            lang == "/Turkish"
              ? Navigation("/Turkish")
              : lang == "/French"
                ? Navigation("/French")
                : lang == "/Portuguese"
                  ? Navigation("/Portuguese")
                  : lang == "/Swahili"
                    ? Navigation("/Swahili")
                    : lang == "/Spanish"
                      ? Navigation("/Spanish")
                      : Navigation("/")
          }
          className="button-27"
          role="button">
          &#8592;
        </button>
      </div>
      <h3 className="head_text">
        {lang == "/Turkish"
          ? "TürkAfrica B2B App Kullanım Koşulları"
          : lang == "/French"
            ? "Conditions d'Utilisation de TürkAfrica B2B App"
            : lang == "/Portuguese"
              ? "Termos de Uso da TürkAfrica B2B App"
              : lang == "/Swahili"
                ? "Vigezo vya Matumizi ya TürkAfrica B2B App"
                : lang == "/Spanish"
                  ? "Términos de Uso de TürkAfrica B2B App"
                  : "TürkAfrica B2B App Terms of Use"}
      </h3>
      <div className="contacnt_div">
        {lang == "/Turkish" ? (
          <>
            <div className="sub_title_heading">
              <h6>2 Ocak 2025'te Güncellendi</h6>
            </div>
            <div className="text_div">
              <p>
                Kullanım Koşulları ("Koşullar"), United Entertainment & Media Limited ("biz", "bizim" veya "biz") tarafından işletilen TürkAfrica B2B Uygulaması'nın ("Uygulama") kullanımınızı yönetir. Uygulamaya erişerek veya kullanarak, bu Koşullara uymayı ve tarafından bağlanmayı kabul edersiniz. Bu Koşulların herhangi bir bölümü ile aynı fikirde değilseniz, Uygulamayı kullanmamalısınız.
              </p>
            </div>
            <div className="text_div">
              <h1>1. Giriş</h1>
              <p>
                TürkAfrica B2B Uygulaması, Afrika ve Türkiye'den işletmeleri ve girişimcileri bir araya getirmek için tasarlanmış bir işten işe ağ kurma ve ticaret platformudur. Uygulama, kullanıcılara ağ oluşturma fırsatları, ücretsiz mesajlaşma, sesli aramalar, video konferans (premium özellik), iş eşleştirme hizmetleri ve işletme ve yatırımcı dizinlerine erişim sağlar.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Uygunluk</h1>
              <p>
                Uygulama, en az 18 yaşında olan ve Birleşik Krallık yasalarına göre bağlayıcı sözleşmeler yapma hukuki kapasitesine sahip kullanıcılar içindir. Uygulamayı kullanarak, bu uygunluk şartlarını karşıladığınızı teyit edersiniz.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Kayıt & Hesap</h1>
              <p>
                Uygulamanın belirli özelliklerine erişmek için kayıt olmanız ve bir hesap oluşturmanız gerekebilir. Kabul ediyorsunuz:
                <br />
                • Kayıt sırasında güncel ve doğru bilgi sağlamak.
                <br />
                • Hesap bilgilerinizin gizliliğini korumak.
                <br /> • Hesabınız altında gerçekleşen tüm aktivitelerden sorumlu olmak.
                <br /> • Hesabınızın yetkisiz kullanımı hakkında bizi derhal bilgilendirmek.
                <br />Bu Koşulları ihlal ettiğimizi belirlersek hesabınızı herhangi bir zamanda sonlandırma veya askıya alma hakkını saklı tutarız.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Sunulan Hizmetler</h1>
              <p>
                <span>• Ücretsiz B2B Mesajlaşma: </span> Tüm kayıtlı kullanıcılar diğer işletmelerle iletişim kurmak için ücretsiz mesajlaşma hizmetini kullanabilir. <br />
                <span>• Sesli Aramalar & Video Konferanslar: </span> İş görüşmeleri ve müzakereler için kesintisiz iletişimi kolaylaştıran bu özellikler, premium kullanıcılar için mevcuttur.
                <br /> <span>• İş Eşleştirme: </span> Uygulama, kullanıcı tercihleri ve endüstrisine bağlı olarak kullanıcıları ilgili iş kontakları, tedarikçiler veya yatırımcılarla eşleştiren algoritmalar sunar. <br />
                <span>• Veritabanı Erişimi: </span>
                Kullanıcılar, yeni pazarlar ve fırsatlar keşfetmek için iş dizinlerine ve yatırımcı veritabanlarına erişim sağlar.
                <br />
                <span>• Ve Daha Fazlası.</span>
              </p>
            </div>
            <div className="text_div">
              <h1>5. Kullanıcı Sorumlulukları</h1>
              <p>
                Uygulamayı kullanırken, aşağıdakilere uymayı kabul edersiniz:
                <ui>
                  <li>Uygulamayı yalnızca yasal amaçlar için kullanmak.</li>
                  <li>
                    Yasadışı, iftira niteliğinde veya zararlı içerik paylaşmamak veya yayınlamamak.
                  </li>
                  <li>
                    Diğer kullanıcılara saygı göstermek ve taciz, kötü muamele veya uygunsuz davranışlardan kaçınmak.
                  </li>
                  <li>
                    Uygulamayı kullanırken geçerli tüm yasalara ve düzenlemelere uymak, bunlar arasında ihracat kontrol yasaları, ticaret yaptırımları ve gizlilik düzenlemeleri bulunur.
                  </li>
                </ui>
                Uygulamada paylaştığınız içerik ve bilgilerden yalnızca siz sorumlu olduğunuzu kabul edersiniz.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Yasaklanan Faaliyetler</h1>
              <p>
                Aşağıdakileri yapmayacağınızı kabul edersiniz:
                <ul>
                  <li>
                    Dolandırıcılık, yasadışı veya aldatıcı faaliyetlerde bulunmak için Uygulamayı kullanmak.
                  </li>
                  <li>
                    Uygulamaya veya kullanıcılarına zarar verebilecek virüsler, kötü amaçlı yazılımlar veya diğer zararlı yazılımları iletmek.
                  </li>
                  <li>Uygulamayı hacklemeye, bozmaya veya tersine mühendislik yapmaya çalışmak</li>
                  <li>
                    İstenmeyen ticari iletişimler (spam) göndermek için Uygulamayı kötüye kullanmak.
                  </li>
                  <li>
                    Diğer kullanıcıları taklit etmek veya işletme veya birey ile bağlantınızı yanlış temsil etmek.
                  </li>
                </ul>
              </p>
            </div>
            <div className="text_div">
              <h1>7. Premium Özellikler & Ödeme</h1>
              <p>
                Temel hizmetler (örneğin, sohbet mesajlaşması) ücretsiz olmasına karşın, belirli özellikler (örn. sesli aramalar, video konferanslar) premium abonelik gerektirir. Premium özelliklerin ve fiyatlandırmanın detayları Uygulamada mevcuttur. Premium hizmetler için ödemeler, güvenli üçüncü taraf sağlayıcılar aracılığıyla işlenir ve bu işlemler için geçerli ödeme detaylarını sağlamayı kabul edersiniz.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Fikri Mülkiyet</h1>
              <p>
                Uygulamadaki tüm fikri mülkiyet hakları, logolar, içerik, tasarım ve yazılım dahil ancak bunlarla sınırlı olmamak üzere, bize aittir veya bize lisanslanmıştır. İçeriği yazılı iznimiz olmadan çoğaltamaz, dağıtamaz veya değiştiremezsiniz. <br />
                Uygulamada paylaştığınız herhangi bir içeriğin mülkiyetini korursunuz ancak içeriği Uygulama tarafından sağlanan hizmetlerin bir parçası olarak kullanmak, göstermek ve dağıtmak üzere bize dünya çapında, münhasır olmayan, telifsiz bir lisans verirsiniz
              </p>
            </div>
            <div className="text_div">
              <h1>9. Gizlilik & Veri Koruma</h1>
              <p>
                Gizliliğinizi ciddiye alıyoruz. Uygulamayı kullanarak, verilerinizin <a href="http://https://turkafrica.app/PrivacyPolicyandCookies">Gizlilik Politikamızda</a> açıklandığı şekilde toplanmasını ve kullanılmasını kabul edersiniz. Geçerli veri koruma yasalarına, Genel Veri Koruma Tüzüğü (GDPR) dahil uyarız.
              </p>
            </div>
            <div className="text_div">
              <h1>10. İşletmeler İçin Doğrulama Politikası</h1>
              <p>
                Güvenilir bir platform sağlamak için, Uygulamayı kullanan tüm işletmelerin meşruiyetlerini doğrulamak için bir doğrulama sürecini tamamlamaları gerekmektedir. Doğrulama, işletme türüne bağlı olarak belirli belgelerin sunulmasını gerektirir:
              </p>
              <h1>  Gerekli Belgeler:</h1>
              <p><span>• Resmi İşletmeler İçin:</span>: Şirket Tescil Belgesi, Vergi Kimlik Numarası (TIN) Sertifikası veya İş Lisansı/Ruhsatı.</p>
              <p><span>• Gayri Resmi İşletmeler İçin:</span> Ticaret Üyelik Kanıtı, Yerel Yönetim İzni veya Kişisel Kimlik Belgeleri.</p>
              <p><span>• Girişimciler & Tek Sahipler İçin:</span> Kendi Beyan Formu, Gelir Kaynakları Kanıtı veya
                Tavsiye/Onay Mektubu.</p>

              <h1>Doğrulama Süreci:</h1>
              <p><span>1. Teslim:</span> Belgeleri Uygulama üzerinden “Doğrulama” bölümüne yükleyin.</p>
              <p><span>2. İnceleme:</span> Ekibimiz teslimatları 3 iş günü içinde inceler.</p>
              <p><span>3. Onay:</span> Doğrulanmış işletmeler profillerinde “Doğrulandı” rozeti alır, güvenilirlik işareti olarak.</p>
              <p>Detaylı yönergeler için Doğrulama
                <a href="http://https://turkafrica.app/PrivacyPolicyandCookies">
                  İşletmeler İçin Politika.
                </a>
              </p>
            </div>
            <div className="text_div">
              <h1>11. Sorumluluk Sınırlaması</h1>
              <p>
                Kanunen mümkün olan en geniş ölçüde, Uygulamanın kullanımından kaynaklanan dolaylı, tesadüfi veya sonuç olarak ortaya çıkan zararlar için sorumlu değiliz. Bu, iş fırsatlarının kaybı, veri, kar veya diğer ekonomik kayıplar da dahil olmak üzere sınırlı değildir. Kullanıcılar tarafından paylaşılan içeriğin doğruluğu veya güvenilirliğini garanti etmiyoruz, ne de Uygulamayı kullanan işletmeleri veya bireyleri onaylıyoruz
              </p>
            </div>
            <div className="text_div">
              <h1>12. Fesih</h1>
              <p>
                Bu Koşulları ihlal ettiğinizi veya Uygulamada yasa dışı veya uygunsuz faaliyetlerde bulunduğunuzu belirlersek, hesabınızı askıya alma veya feshetme hakkını kendimizde saklı tutarız.
              </p>
            </div>
            <div className="text_div">
              <h1>13. Üçüncü Taraf Bağlantıları</h1>
              <p>
                Uygulama, bize ait olmayan veya kontrolümüz altında olmayan üçüncü taraf web sitelerine veya hizmetlerine bağlantılar içerebilir. Üçüncü taraf web sitelerinin içeriğinden, gizlilik politikalarından veya uygulamalarından sorumlu değiliz. Üçüncü taraf web sitelerini kullanmanın kendi riskiniz olduğunu kabul edersiniz.
              </p>
            </div>
            <div className="text_div">
              <h1>14. Yönetim Hukuku</h1>
              <p>
                Bu Koşullar, Birleşik Krallık yasalarına göre yönetilir ve yorumlanır. Bu Koşullardan veya bunlarla bağlantılı olarak ortaya çıkan herhangi bir anlaşmazlık, Birleşik Krallık mahkemelerinin münhasır yargı yetkisine tabidir.
              </p>
            </div>
            <div className="text_div">
              <h1>15. Koşullardaki Değişiklikler</h1>
              <p>
                Bu Koşulları herhangi bir zamanda değiştirme veya güncelleme hakkını saklı tutarız. Kullanıcılara, e-posta veya Uygulama aracılığıyla önemli değişiklikler hakkında bildirimde bulunacağız. Bu tür değişikliklerden sonra Uygulamayı kullanmaya devam etmeniz, yeni Koşulları kabul ettiğiniz anlamına gelir.
              </p>
            </div>
            <div className="text_div">
              <h1>16. Bize Ulaşın</h1>
              <p>
                Bu Koşullar hakkında herhangi bir sorunuz veya endişeniz varsa, lütfen <a href="https://turkafrica.app/#Contact">https://turkafrica.app/#Contact</a> adresindeki iletişim formumuz aracılığıyla bize ulaşın.
              </p>
            </div>
          </>
        ) : lang == "/French" ? (
          <>
            <div className="sub_title_heading">
              <h6>Mis à jour le 2 janvier 2025</h6>
            </div>
            <div className="text_div">
              <p>
                Les Conditions d'Utilisation ("Conditions") régissent votre utilisation de l'application TürkAfrica B2B ("l'Application"), exploitée par United Entertainment & Media Limited ("nous", "notre" ou "nos"). En accédant ou en utilisant l'Application, vous acceptez de vous conformer et d'être lié par ces Conditions. Si vous n'acceptez pas une partie de ces Conditions, vous ne devez pas utiliser l'Application.
              </p>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                L'Application TürkAfrica B2B est une plateforme de mise en réseau et de commerce interentreprises conçue pour connecter les entreprises et les entrepreneurs d'Afrique et de Turquie. L'Application offre aux utilisateurs des opportunités de mise en réseau, des messageries gratuites, des appels vocaux, des conférences vidéo (fonctionnalité premium), des services de mise en relation commerciale, et des répertoires d'entreprises et d'investisseurs.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Éligibilité</h1>
              <p>
                L'Application est destinée aux utilisateurs âgés d'au moins 18 ans et ayant la capacité juridique de conclure des contrats contraignants en vertu des lois du Royaume-Uni. En utilisant l'Application, vous confirmez que vous remplissez ces conditions d'éligibilité.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Inscription & Compte</h1>
              <p>
                Pour accéder à certaines fonctionnalités de l'Application, vous pouvez être amené à vous inscrire et à créer un compte. Vous acceptez de :
                <br />
                • Fournir des informations exactes et à jour lors de l'inscription. <br />
                • Maintenir la confidentialité de vos identifiants de compte.
                <br /> • Être responsable de toutes les activités qui se produisent sous votre compte.
                <br /> • Nous notifier immédiatement en cas d'utilisation non autorisée de votre compte.
                <br />Nous nous réservons le droit de résilier ou de suspendre votre compte à tout moment si nous déterminons que vous avez violé ces Conditions.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Services fournis</h1>
              <p>
                <span>• Messagerie B2B gratuite : </span> Tous les utilisateurs inscrits peuvent utiliser le service de messagerie gratuit pour communiquer avec d'autres entreprises. <br />
                <span>• Appels vocaux & Vidéoconférences : </span> Disponibles pour les utilisateurs premium, ces fonctionnalités facilitent la communication sans interruption pour les discussions et négociations commerciales.
                <br /> <span>• Mise en relation commerciale : </span> L'Application propose des algorithmes pour associer les utilisateurs avec des contacts commerciaux pertinents, des fournisseurs ou des investisseurs, en fonction des préférences des utilisateurs et du secteur. <br />
                <span>• Accès aux bases de données : </span>
                Les utilisateurs ont accès aux répertoires d'entreprises et aux bases de données d'investisseurs pour explorer de nouveaux marchés et opportunités.
                <br />
                <span>• Et plus encore.</span>
              </p>
            </div>
            <div className="text_div">
              <h1>5. Responsabilités des utilisateurs</h1>
              <p>
                En utilisant l'Application, vous acceptez de :
                <ui>
                  <li>Utiliser l'Application uniquement à des fins légales.</li>
                  <li>
                    Ne pas publier ou partager de contenu illégal, diffamatoire ou nuisible.
                  </li>
                  <li>
                    Respecter les autres utilisateurs et éviter tout harcèlement, abus ou comportement inapproprié.
                  </li>
                  <li>
                    Respecter toutes les lois et réglementations applicables lors de l'utilisation de l'Application, y compris les lois sur le contrôle des exportations, les sanctions commerciales et les réglementations sur la vie privée.
                  </li>
                </ui>
                Vous reconnaissez être seul responsable du contenu et des informations que vous partagez sur l'Application.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Activités interdites</h1>
              <p>
                Vous acceptez de ne pas :
                <ul>
                  <li>
                    Utiliser l'Application pour vous engager dans des activités frauduleuses, illégales ou trompeuses.
                  </li>
                  <li>
                    Transmettre des virus, des logiciels malveillants ou d'autres logiciels nuisibles susceptibles d'endommager l'Application ou ses utilisateurs.
                  </li>
                  <li>Tenter de pirater, perturber ou décompiler l'Application</li>
                  <li>
                    Utiliser l'Application pour envoyer des communications commerciales non sollicitées (spam).
                  </li>
                  <li>
                    Usurper l'identité d'autres utilisateurs ou faussement représenter votre affiliation avec une entreprise ou une personne.
                  </li>
                </ul>
              </p>
            </div>
            <div className="text_div">
              <h1>7. Fonctionnalités premium & Paiement</h1>
              <p>
                Bien que les services de base tels que la messagerie chat soient gratuits, certaines fonctionnalités (par exemple, les appels vocaux, les vidéoconférences) nécessitent un abonnement premium. Les détails des fonctionnalités premium et des tarifs sont disponibles sur l'Application. Les paiements pour les services premium sont traités par des fournisseurs tiers sécurisés, et vous acceptez de fournir des détails de paiement valides pour ces transactions.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Propriété intellectuelle</h1>
              <p>
                Tous les droits de propriété intellectuelle dans l'Application, y compris mais sans s'y limiter, les logos, le contenu, la conception et le logiciel, nous appartiennent ou sont licenciés à nous. Vous ne pouvez pas reproduire, distribuer ou modifier aucun contenu sans notre consentement écrit. <br />
                Vous conservez la propriété de tout contenu que vous publiez sur l'Application mais vous nous accordez une licence mondiale, non exclusive et sans redevance pour utiliser, afficher et distribuer le contenu dans le cadre des services fournis par l'Application
              </p>
            </div>
            <div className="text_div">
              <h1>9. Confidentialité & Protection des données</h1>
              <p>
                Nous prenons votre vie privée au sérieux. En utilisant l'Application, vous acceptez la collecte et l'utilisation de vos données comme décrit dans notre <a href="http://https://turkafrica.app/PrivacyPolicyandCookies">Politique de confidentialité</a>. Nous nous conformons aux lois applicables sur la protection des données, y compris le Règlement général sur la protection des données (RGPD).
              </p>
            </div>
            <div className="text_div">
              <h1>10. Politique de vérification pour les entreprises</h1>
              <p>
                Pour maintenir une plateforme de confiance, toutes les entreprises utilisant l'Application doivent compléter un processus de vérification pour confirmer leur légitimité. La vérification nécessite la soumission de documents spécifiques en fonction du type d'entreprise :
              </p>
              <h1>Documents requis :</h1>
              <p><span>• Pour les entreprises formelles :</span> Certificat d'incorporation, Certificat de numéro d'identification fiscale (TIN), ou Licence/Permis d'affaires.</p>
              <p><span>• Pour les entreprises informelles :</span> Preuve d'appartenance à une chambre de commerce, Permis du gouvernement local, ou Documents d'identification personnelle.</p>
              <p><span>• Pour les entrepreneurs et propriétaires uniques :</span> Formulaire d'auto-déclaration, Preuve de flux de revenus, ou Lettre de recommandation/endorsement.</p>

              <h1>Processus de vérification :</h1>
              <p><span>1. Soumission :</span> Téléchargez les documents via l'Application dans la section "Vérification".</p>
              <p><span>2. Révision :</span> Notre équipe examine les soumissions dans les 3 jours ouvrables.</p>
              <p><span>3. Approbation :</span> Les entreprises vérifiées reçoivent un badge "Vérifié" sur leur profil, signalant leur fiabilité</p>
              <p>Pour des directives détaillées, visitez la Politique de
                <a href="http://https://turkafrica.app/PrivacyPolicyandCookies">
                  Vérification pour les Entreprises.
                </a>
              </p>
            </div>
            <div className="text_div">
              <h1>11. Limitation de responsabilité</h1>
              <p>
                Dans la mesure permise par la loi, nous ne sommes pas responsables des dommages indirects, accessoires ou consécutifs résultant de votre utilisation de l'Application. Cela inclut, mais sans s'y limiter, la perte d'opportunités commerciales, de données, de profits ou d'autres pertes économiques. Nous ne garantissons pas l'exactitude ou la fiabilité du contenu publié par les utilisateurs, ni n'approuvons aucune entreprise ou individu utilisant l'Application
              </p>
            </div>
            <div className="text_div">
              <h1>12. Résiliation</h1>
              <p>
                Nous nous réservons le droit de suspendre ou de résilier votre compte à notre seule discrétion si nous déterminons que vous avez violé ces Conditions ou vous êtes engagé dans des activités illégales ou inappropriées sur l'Application.
              </p>
            </div>
            <div className="text_div">
              <h1>13. Liens tiers</h1>
              <p>
                L'Application peut contenir des liens vers des sites web ou des services tiers qui ne sont ni possédés ni contrôlés par nous. Nous ne sommes pas responsables du contenu, des politiques de confidentialité, ou des pratiques de tout site web tiers. Vous reconnaissez que votre utilisation des sites web tiers est à vos propres risques.
              </p>
            </div>
            <div className="text_div">
              <h1>14. Loi applicable</h1>
              <p>
                Ces Conditions sont régies par et interprétées conformément aux lois du Royaume-Uni. Tout litige découlant de ou en relation avec ces Conditions sera soumis à la compétence exclusive des tribunaux du Royaume-Uni.
              </p>
            </div>
            <div className="text_div">
              <h1>15. Modifications des Conditions</h1>
              <p>
                Nous nous réservons le droit de modifier ou de mettre à jour ces Conditions à tout moment. Nous notifierons les utilisateurs de tout changement significatif par courriel ou via l'Application. Votre utilisation continue de l'Application après de telles modifications constitue votre acceptation des nouvelles Conditions.
              </p>
            </div>
            <div className="text_div">
              <h1>16. Contactez-nous</h1>
              <p>
                Si vous avez des questions ou des préoccupations concernant ces Conditions, veuillez nous contacter via notre formulaire de contact à <a href="https://turkafrica.app/#Contact">https://turkafrica.app/#Contact.</a>
              </p>
            </div>
          </>
        ) : lang == "/Portuguese" ? (
          <>
            <div className="sub_title_heading">
              <h6>Atualizado em 2 de janeiro de 2025</h6>
            </div>
            <div className="text_div">
              <p>
                Os Termos de Uso ("Termos") regem seu uso do aplicativo TürkAfrica B2B ("o Aplicativo"), operado pela United Entertainment & Media Limited ("nós", "nosso" ou "nossa"). Ao acessar ou usar o Aplicativo, você concorda em cumprir e ser vinculado por estes Termos. Se você não concordar com qualquer parte destes Termos, você não deve usar o Aplicativo.
              </p>
            </div>
            <div className="text_div">
              <h1>1. Introdução</h1>
              <p>
                O Aplicativo TürkAfrica B2B é uma plataforma de rede e comércio business-to-business projetada para conectar empresas e empreendedores da África e Turquia. O Aplicativo oferece aos usuários acesso a oportunidades de rede, mensagens gratuitas, chamadas de voz, conferências de vídeo (recurso premium), serviços de correspondência comercial e diretórios de empresas e investidores.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Elegibilidade</h1>
              <p>
                O Aplicativo é destinado a usuários que têm pelo menos 18 anos de idade e possuem capacidade legal para celebrar contratos vinculativos sob as leis do Reino Unido. Ao usar o Aplicativo, você confirma que atende a esses requisitos de elegibilidade.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Registro & Conta</h1>
              <p>
                Para acessar certos recursos do Aplicativo, você pode ser solicitado a se registrar e criar uma conta. Você concorda em:
                <br />
                • Fornecer informações precisas e atualizadas durante o registro.
                <br />
                • Manter a confidencialidade das credenciais da sua conta.
                <br /> • Ser responsável por todas as atividades que ocorrerem sob sua conta.
                <br /> • Notificar-nos imediatamente sobre qualquer uso não autorizado de sua conta.
                <br />Reservamo-nos o direito de terminar ou suspender sua conta a qualquer momento se determinarmos que você violou estes Termos.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Serviços Fornecidos</h1>
              <p>
                <span>• Mensagens de Chat B2B Gratuitas: </span> Todos os usuários registrados podem usar o serviço de mensagens gratuito para se comunicar com outras empresas.
                <br />
                <span>• Chamadas de Voz & Conferências de Vídeo: </span> Disponíveis para usuários premium, esses recursos facilitam a comunicação contínua para discussões e negociações comerciais.
                <br /> <span>• Correspondência Comercial: </span> O Aplicativo oferece algoritmos para combinar usuários com contatos comerciais relevantes, fornecedores ou investidores, com base nas preferências dos usuários e no setor.
                <br />
                <span>• Acesso ao Banco de Dados: </span>
                Os usuários têm acesso a diretórios de empresas e bancos de dados de investidores para explorar novos mercados e oportunidades.
                <br />
                <span>• E Mais.</span>
              </p>
            </div>
            <div className="text_div">
              <h1>5. Responsabilidades dos Usuários</h1>
              <p>
                Ao usar o Aplicativo, você concorda em:
                <ui>
                  <li>Usar o Aplicativo apenas para fins legais.</li>
                  <li>
                    Abster-se de postar ou compartilhar conteúdo ilegal, difamatório ou prejudicial.
                  </li>
                  <li>
                    Respeitar outros usuários e abster-se de se envolver em assédio, abuso ou comportamento inapropriado.
                  </li>
                  <li>
                    Cumprir todas as leis e regulamentos aplicáveis ao usar o Aplicativo, incluindo leis de controle de exportações, sanções comerciais e regulamentos de privacidade.
                  </li>
                </ui>
                Você reconhece que é o único responsável pelo conteúdo e informações que compartilha no Aplicativo.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Atividades Proibidas</h1>
              <p>
                Você concorda em não:
                <ul>
                  <li>
                    Usar o Aplicativo para se envolver em atividades fraudulentas, ilegais ou enganosas.
                  </li>
                  <li>
                    Transmitir vírus, malware ou outro software prejudicial que possa danificar o Aplicativo ou seus usuários.
                  </li>
                  <li>Tentar hackear, interromper ou fazer engenharia reversa do Aplicativo</li>
                  <li>
                    Usar o Aplicativo para enviar comunicações comerciais não solicitadas (spam).
                  </li>
                  <li>
                    Falsificar identidades de outros usuários ou deturpar sua afiliação com uma empresa ou indivíduo.
                  </li>
                </ul>
              </p>
            </div>
            <div className="text_div">
              <h1>7. Recursos Premium & Pagamento</h1>
              <p>
                Embora os serviços básicos como mensagens de chat sejam gratuitos, certos recursos (por exemplo, chamadas de voz, conferências de vídeo) requerem uma assinatura premium. Detalhes sobre os recursos premium e preços estão disponíveis no Aplicativo. Pagamentos para serviços premium são processados através de provedores terceirizados seguros, e você concorda em fornecer detalhes de pagamento válidos para essas transações.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Propriedade Intelectual</h1>
              <p>
                Todos os direitos de propriedade intelectual no Aplicativo, incluindo, mas não se limitando a, logotipos, conteúdo, design e software, são de nossa propriedade ou licenciados para nós. Você não pode reproduzir, distribuir ou modificar qualquer conteúdo sem nosso consentimento por escrito. <br />
                Você mantém a propriedade de qualquer conteúdo que postar no Aplicativo, mas concede-nos uma licença global, não exclusiva e livre de royalties para usar, exibir e distribuir o conteúdo como parte dos serviços fornecidos pelo Aplicativo
              </p>
            </div>
            <div className="text_div">
              <h1>9. Privacidade & Proteção de Dados</h1>
              <p>
                Levamos sua privacidade a sério. Ao usar o Aplicativo, você concorda com a coleta e uso de seus dados conforme descrito em nossa <a href="http://https://turkafrica.app/PrivacyPolicyandCookies">Política de Privacidade</a>. Cumprimos as leis de proteção de dados aplicáveis, incluindo o Regulamento Geral sobre a Proteção de Dados (GDPR).
              </p>
            </div>
            <div className="text_div">
              <h1>10. Política de Verificação para Empresas</h1>
              <p>
                Para manter uma plataforma confiável, todas as empresas que usam o Aplicativo devem completar um processo de verificação para confirmar sua legitimidade. A verificação exige a apresentação de documentos específicos dependendo do tipo de empresa:
              </p>
              <h1>Documentos Necessários:</h1>
              <p><span>• Para Empresas Formais:</span> Certificado de Incorporação, Certificado de Identificação Fiscal (TIN), ou Licença/Permissão Comercial.</p>
              <p><span>• Para Empresas Informais:</span> Prova de Associação Comercial, Permissão do Governo Local, ou Documentos de Identificação Pessoal.</p>
              <p><span>• Para Empreendedores & Proprietários Únicos:</span> Formulário de Auto-Declaração, Prova de Fluxos de Receita, ou Carta de Recomendação/Aval.</p>

              <h1>Processo de Verificação:</h1>
              <p><span>1. Submissão:</span> Envie documentos pelo Aplicativo na seção "Verificação".</p>
              <p><span>2. Revisão:</span> Nossa equipe revisa as submissões dentro de 3 dias úteis.</p>
              <p><span>3. Aprovação:</span> As empresas verificadas recebem um selo "Verificado" em seu perfil, sinalizando confiabilidade</p>
              <p>Para diretrizes detalhadas, visite Política de
                <a href="http://https://turkafrica.app/PrivacyPolicyandCookies">
                  Verificação para Empresas.
                </a>
              </p>
            </div>
            <div className="text_div">
              <h1>11. Limitação de Responsabilidade</h1>
              <p>
                Na medida permitida por lei, não somos responsáveis por quaisquer danos indiretos, incidentais ou consequenciais decorrentes do seu uso do Aplicativo. Isso inclui, mas não se limita a, perda de oportunidades comerciais, dados, lucros ou outras perdas econômicas. Não garantimos a precisão ou confiabilidade do conteúdo postado pelos usuários, nem endossamos quaisquer empresas ou indivíduos usando o Aplicativo
              </p>
            </div>
            <div className="text_div">
              <h1>12. Rescisão</h1>
              <p>
                Reservamo-nos o direito de suspender ou terminar sua conta a nosso exclusivo critério se determinarmos que você violou estes Termos ou se envolveu em atividades ilegais ou inapropriadas no Aplicativo.
              </p>
            </div>
            <div className="text_div">
              <h1>13. Links de Terceiros</h1>
              <p>
                O Aplicativo pode conter links para sites ou serviços de terceiros que não são de nossa propriedade ou controle. Não somos responsáveis pelo conteúdo, políticas de privacidade ou práticas de quaisquer sites de terceiros. Você reconhece que o uso de sites de terceiros é por sua conta e risco.
              </p>
            </div>
            <div className="text_div">
              <h1>14. Lei Aplicável</h1>
              <p>
                Estes Termos são regidos e interpretados de acordo com as leis do Reino Unido. Quaisquer disputas decorrentes ou relacionadas a estes Termos serão submetidas à jurisdição exclusiva dos tribunais do Reino Unido.
              </p>
            </div>
            <div className="text_div">
              <h1>15. Alterações aos Termos</h1>
              <p>
                Reservamo-nos o direito de modificar ou atualizar estes Termos a qualquer momento. Notificaremos os usuários de quaisquer alterações materiais por e-mail ou através do Aplicativo. Seu uso contínuo do Aplicativo após tais modificações constitui sua aceitação dos novos Termos.
              </p>
            </div>
            <div className="text_div">
              <h1>16. Contate-Nos</h1>
              <p>
                Se você tiver quaisquer perguntas ou preocupações sobre estes Termos, por favor entre em contato conosco através do nosso formulário de contato em <a href="https://turkafrica.app/#Contact">https://turkafrica.app/#Contact.</a>
              </p>
            </div>
          </>
        ) : lang == "/Swahili" ? (
          <>
            <div className="sub_title_heading">
              <h6>Imesasishwa Januari 2, 2025</h6>
            </div>
            <div className="text_div">
              <p>
                Vigezo vya Matumizi ("Vigezo") vinatawala matumizi yako ya programu ya TürkAfrica B2B ("Programu"), inayoendeshwa na United Entertainment & Media Limited ("sisi", "yetu" au "zetu"). Kwa kufikia au kutumia Programu, unakubali kufuata na kufungwa na Vigezo hivi. Ikiwa haukubaliani na sehemu yoyote ya Vigezo hivi, haupaswi kutumia Programu.
              </p>
            </div>
            <div className="text_div">
              <h1>1. Utangulizi</h1>
              <p>
                Programu ya TürkAfrica B2B ni jukwaa la biashara kwa biashara la kusanikisha na kufanya biashara lililoundwa kuunganisha biashara na wajasiriamali kutoka Afrika na Uturuki. Programu inatoa fursa za kujenga mitandao, ujumbe wa bure, simu za sauti, mikutano ya video (kipengele cha premium), huduma za kuunganisha biashara, na saraka za biashara na wawekezaji.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Kustahiki</h1>
              <p>
                Programu inalenga watumiaji walio na umri wa angalau miaka 18 na wanao uwezo wa kisheria kuingia mikataba inayofunga chini ya sheria za Uingereza. Kwa kutumia Programu, unathibitisha kuwa unakidhi mahitaji haya ya kustahiki.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Usajili & Akaunti</h1>
              <p>
                Ili kufikia vipengele fulani vya Programu, unaweza kuhitajika kusajili na kuunda akaunti. Unakubali:
                <br />
                • Kutoa taarifa sahihi na za hivi punde wakati wa usajili.
                <br />
                • Kuweka siri ya sifa za akaunti yako.
                <br /> • Kuwa na jukumu la shughuli zote zinazotokea chini ya akaunti yako.
                <br /> • Kutujulisha mara moja kuhusu matumizi yasiyoidhinishwa ya akaunti yako.
                <br />Tunahifadhi haki ya kusitisha au kusimamisha akaunti yako wakati wowote ikiwa tutagundua kuwa umevunja Vigezo hivi.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Huduma Zinazotolewa</h1>
              <p>
                <span>• Ujumbe wa Gumzo la B2B Bure: </span> Watumiaji wote waliosajiliwa wanaweza kutumia huduma ya ujumbe wa bure kuwasiliana na biashara zingine. <br />
                <span>• Simu za Sauti na Mikutano ya Video: </span> Inapatikana kwa watumiaji wa premium, vipengele hivi vinawezesha mawasiliano yasiyo na mkatizo kwa majadiliano ya biashara na majadiliano.
                <br /> <span>• Kuunganisha Biashara: </span> Programu inatoa algorithms za kuwafananisha watumiaji na mawasiliano ya biashara yanayofaa, wauzaji, au wawekezaji kulingana na mapendeleo ya watumiaji na sekta. <br />
                <span>• Ufikiaji wa Database: </span>
                Watumiaji wana ufikiaji wa saraka za biashara na hifadhidata za wawekezaji ili kuchunguza masoko mapya na fursa.
                <br />
                <span>• Na Zaidi.</span>
              </p>
            </div>
            <div className="text_div">
              <h1>5. Majukumu ya Watumiaji</h1>
              <p>
                Unapotumia Programu, unakubali:
                <ui>
                  <li>Kutumia Programu kwa madhumuni ya kisheria tu.</li>
                  <li>
                    Kuepuka kuchapisha au kushiriki yaliyomo haramu, ya kashfa, au yenye madhara.
                  </li>
                  <li>
                    Kuheshimu watumiaji wengine na kuepuka kushiriki katika unyanyasaji, dhuluma, au tabia isiyofaa.
                  </li>
                  <li>
                    Kufuata sheria na kanuni zote zinazotumika unapotumia Programu, pamoja na sheria za udhibiti wa usafirishaji, vikwazo vya biashara, na kanuni za faragha.
                  </li>
                </ui>
                Unakiri kuwa wewe pekee ndiye unayewajibika kwa yaliyomo na taarifa unazoshiriki kwenye Programu.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Shughuli Zilizokatazwa</h1>
              <p>
                Unakubali kutofanya yafuatayo:
                <ul>
                  <li>
                    Kutumia Programu kushiriki katika shughuli za udanganyifu, haramu, au za udanganyifu.
                  </li>
                  <li>
                    Kutuma virusi, zisizo, au programu nyingine yenye madhara ambayo yanaweza kuharibu Programu au watumiaji wake.
                  </li>
                  <li>Kujaribu kuhack, kuvuruga, au kubadilisha Programu</li>
                  <li>
                    Kutumia Programu kutuma mawasiliano ya kibiashara yasiyoombwa (spam).
                  </li>
                  <li>
                    Kujifanya watumiaji wengine au kupotosha ushirika wako na biashara au mtu binafsi.
                  </li>
                </ul>
              </p>
            </div>
            <div className="text_div">
              <h1>7. Vipengele vya Premium & Malipo</h1>
              <p>
                Ingawa huduma za msingi kama vile ujumbe wa gumzo ni bure, vipengele fulani (kwa mfano, simu za sauti, mikutano ya video) vinahitaji usajili wa premium. Maelezo kuhusu vipengele vya premium na bei vinapatikana kwenye Programu. Malipo kwa huduma za premium yanashughulikiwa kupitia watoa huduma wa tatu wa kuaminika, na unakubali kutoa maelezo halali ya malipo kwa miamala hiyo.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Mali Miliki</h1>
              <p>
                Haki zote za mali miliki kwenye Programu, ikiwa ni pamoja na lakini sio tu kwa nembo, yaliyomo, muundo, na programu, ni mali yetu au zimelisensiwa kwetu. Huwezi kuzalisha, kusambaza, au kurekebisha yaliyomo bila idhini yetu iliyoandikwa. <br />
                Unabaki na umiliki wa yaliyomo yoyote unayochapisha kwenye Programu lakini unatupa leseni ya ulimwengu, isiyo ya kipekee, isiyolipishwa kwa kutumia, kuonyesha, na kusambaza yaliyomo kama sehemu ya huduma zinazotolewa na Programu
              </p>
            </div>
            <div className="text_div">
              <h1>9. Faragha & Ulinzi wa Data</h1>
              <p>
                Tunachukulia faragha yako kwa uzito. Kwa kutumia Programu, unakubali ukusanyaji na matumizi ya data yako kama ilivyoelezwa katika <a href="http://https://turkafrica.app/PrivacyPolicyandCookies">Sera yetu ya Faragha</a>. Tunatii sheria zinazotumika za ulinzi wa data, pamoja na Kanuni ya Jumla ya Ulinzi wa Data (GDPR).
              </p>
            </div>
            <div className="text_div">
              <h1>10. Sera ya Uthibitishaji kwa Biashara</h1>
              <p>
                Ili kudumisha jukwaa la kuaminika, biashara zote zinazotumia Programu lazima zikamilishe mchakato wa uthibitishaji ili kuthibitisha uhalali wao. Uthibitishaji unahitaji uwasilishaji wa nyaraka maalum kulingana na aina ya biashara:
              </p>
              <h1>Nyaraka Zinazohitajika:</h1>
              <p><span>• Kwa Biashara Rasmi:</span> Cheti cha Ushirika, Cheti cha Kitambulisho cha Kodi (TIN), au Leseni/Ruhusa ya Biashara.</p>
              <p><span>• Kwa Biashara Zisizo Rasmi:</span> Uthibitisho wa Uanachama wa Biashara, Ruhusa ya Serikali ya Mitaa, au Hati za Utambulisho Binafsi.</p>
              <p><span>• Kwa Wajasiriamali & Wamiliki Pekee:</span> Fomu ya Kujitangaza, Uthibitisho wa Vyanzo vya Mapato, au Barua ya Mapendekezo/Idhini.</p>

              <h1>Mchakato wa Uthibitishaji:</h1>
              <p><span>1. Uwasilishaji:</span> Pakia nyaraka kupitia Programu chini ya sehemu ya "Uthibitishaji".</p>
              <p><span>2. Mapitio:</span> Timu yetu inapitia uwasilishaji ndani ya siku 3 za kazi.</p>
              <p><span>3. Idhini:</span> Biashara zilizothibitishwa hupokea beji ya "Imethibitishwa" kwenye wasifu wao, ikiashiria kuaminika</p>
              <p>Kwa mwongozo wa kina, tembelea Sera ya
                <a href="http://https://turkafrica.app/PrivacyPolicyandCookies">
                  Uthibitishaji kwa Biashara.
                </a>
              </p>
            </div>
            <div className="text_div">
              <h1>11. Kikomo cha Dhima</h1>
              <p>
                Kwa kiwango kinachoruhusiwa na sheria, hatuwajibiki kwa uharibifu wowote wa moja kwa moja, wa bahati mbaya, au unaofuata unaotokana na matumizi yako ya Programu. Hii ni pamoja na, lakini sio tu, hasara ya fursa za biashara, data, faida, au hasara nyingine za kiuchumi. Hatuhakikishi usahihi au uaminifu wa yaliyomo yaliyochapishwa na watumiaji, wala hatuidhinishi biashara au watu binafsi wanaotumia Programu
              </p>
            </div>
            <div className="text_div">
              <h1>12. Kusitisha</h1>
              <p>
                Tunahifadhi haki ya kusimamisha au kusitisha akaunti yako kwa hiari yetu pekee ikiwa tutagundua kuwa umekiuka Vigezo hivi au umeshiriki katika shughuli haramu au zisizofaa kwenye Programu.
              </p>
            </div>
            <div className="text_div">
              <h1>13. Viungo vya Tatu</h1>
              <p>
                Programu inaweza kuwa na viungo kwa tovuti au huduma za watu wa tatu ambazo hazimilikiwi wala kudhibitiwa na sisi. Hatuwajibiki kwa maudhui, sera za faragha, au mazoea ya tovuti yoyote ya watu wa tatu. Unakubali kuwa matumizi yako ya tovuti za watu wa tatu ni kwa hatari yako mwenyewe.
              </p>
            </div>
            <div className="text_div">
              <h1>14. Sheria Inayotumika</h1>
              <p>
                Vigezo hivi vinatawaliwa na kufasiriwa kulingana na sheria za Uingereza. Mizozo yoyote inayotokana na au kuhusiana na Vigezo hivi itakuwa chini ya mamlaka ya kipekee ya mahakama za Uingereza.
              </p>
            </div>
            <div className="text_div">
              <h1>15. Mabadiliko kwa Vigezo</h1>
              <p>
                Tunahifadhi haki ya kurekebisha au kusasisha Vigezo hivi wakati wowote. Tutawajulisha watumiaji kuhusu mabadiliko yoyote muhimu kwa barua pepe au kupitia Programu. Matumizi yako endelevu ya Programu baada ya marekebisho kama hayo yanamaanisha kukubali kwako Vigezo vipya.
              </p>
            </div>
            <div className="text_div">
              <h1>16. Wasiliana Nasi</h1>
              <p>
                Ikiwa una maswali au wasiwasi wowote kuhusu Vigezo hivi, tafadhali wasiliana nasi kupitia fomu yetu ya mawasiliano kwenye <a href="https://turkafrica.app/#Contact">https://turkafrica.app/#Contact.</a>
              </p>
            </div>
          </>
        ) : lang == "/Spanish" ? (
          <>
            <div className="sub_title_heading">
              <h6>Actualizado el 2 de enero de 2025</h6>
            </div>
            <div className="text_div">
              <p>
                Los Términos de Uso ("Términos") rigen su uso de la aplicación TürkAfrica B2B ("la Aplicación"), operada por United Entertainment & Media Limited ("nosotros", "nuestro" o "nos"). Al acceder o utilizar la Aplicación, usted acepta cumplir y estar vinculado por estos Términos. Si no está de acuerdo con alguna parte de estos Términos, no debe usar la Aplicación.
              </p>
            </div>
            <div className="text_div">
              <h1>1. Introducción</h1>
              <p>
                La aplicación TürkAfrica B2B es una plataforma de networking y comercio de empresa a empresa diseñada para conectar negocios y emprendedores de África y Turquía. La Aplicación ofrece a los usuarios acceso a oportunidades de networking, mensajería gratuita, llamadas de voz, conferencias de vídeo (característica premium), servicios de emparejamiento empresarial y directorios de negocios e inversores.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Elegibilidad</h1>
              <p>
                La Aplicación está destinada a usuarios que tienen al menos 18 años de edad y capacidad legal para celebrar contratos vinculantes bajo las leyes del Reino Unido. Al usar la Aplicación, usted confirma que cumple con estos requisitos de elegibilidad.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Registro & Cuenta</h1>
              <p>
                Para acceder a ciertas características de la Aplicación, puede ser necesario que se registre y cree una cuenta. Usted acepta:
                <br />
                • Proporcionar información precisa y actualizada durante el registro.
                <br />
                • Mantener la confidencialidad de las credenciales de su cuenta.
                <br /> • Ser responsable de todas las actividades que ocurran bajo su cuenta.
                <br /> • Notificarnos inmediatamente sobre cualquier uso no autorizado de su cuenta.
                <br />Nos reservamos el derecho de terminar o suspender su cuenta en cualquier momento si determinamos que ha violado estos Términos.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Servicios Provistos</h1>
              <p>
                <span>• Mensajería de Chat B2B Gratuita: </span> Todos los usuarios registrados pueden utilizar el servicio de mensajería gratuito para comunicarse con otros negocios. <br />
                <span>• Llamadas de Voz y Conferencias de Vídeo: </span> Disponibles para usuarios premium, estas características facilitan la comunicación sin interrupciones para discusiones y negociaciones comerciales.
                <br /> <span>• Emparejamiento de Negocios: </span> La Aplicación ofrece algoritmos para emparejar a los usuarios con contactos comerciales relevantes, proveedores o inversores, basándose en las preferencias de los usuarios y el sector. <br />
                <span>• Acceso a la Base de Datos: </span>
                Los usuarios tienen acceso a directorios empresariales y bases de datos de inversores para explorar nuevos mercados y oportunidades.
                <br />
                <span>• Y Más.</span>
              </p>
            </div>
            <div className="text_div">
              <h1>5. Responsabilidades del Usuario</h1>
              <p>
                Al usar la Aplicación, usted acepta:
                <ui>
                  <li>Utilizar la Aplicación solo para fines legales.</li>
                  <li>
                    Abstenerse de publicar o compartir contenido ilegal, difamatorio o dañino.
                  </li>
                  <li>
                    Respetar a otros usuarios y abstenerse de participar en acoso, abuso o comportamiento inapropiado.
                  </li>
                  <li>
                    Cumplir con todas las leyes y regulaciones aplicables al usar la Aplicación, incluidas las leyes de control de exportaciones, sanciones comerciales y regulaciones de privacidad.
                  </li>
                </ui>
                Usted reconoce que es el único responsable del contenido e información que comparte en la Aplicación.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Actividades Prohibidas</h1>
              <p>
                Usted acepta no:
                <ul>
                  <li>
                    Utilizar la Aplicación para participar en actividades fraudulentas, ilegales o engañosas.
                  </li>
                  <li>
                    Transmitir virus, malware u otro software dañino que pueda dañar la Aplicación o a sus usuarios.
                  </li>
                  <li>Intentar hackear, interrumpir o hacer ingeniería inversa de la Aplicación</li>
                  <li>
                    Usar la Aplicación para enviar comunicaciones comerciales no solicitadas (spam).
                  </li>
                  <li>
                    Hacerse pasar por otros usuarios o tergiversar su afiliación con un negocio o individuo.
                  </li>
                </ul>
              </p>
            </div>
            <div className="text_div">
              <h1>7. Características Premium y Pago</h1>
              <p>
                Si bien los servicios básicos como la mensajería de chat son gratuitos, ciertas características (por ejemplo, llamadas de voz, conferencias de vídeo) requieren una suscripción premium. Los detalles sobre las características premium y los precios están disponibles en la Aplicación. Los pagos por servicios premium se procesan a través de proveedores de terceros seguros, y usted acepta proporcionar detalles de pago válidos para dichas transacciones.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Propiedad Intelectual</h1>
              <p>
                Todos los derechos de propiedad intelectual en la Aplicación, incluyendo, pero no limitado a, logotipos, contenido, diseño y software, son propiedad nuestra o están licenciados a nosotros. Usted no puede reproducir, distribuir o modificar ningún contenido sin nuestro consentimiento escrito. <br />
                Usted conserva la propiedad de cualquier contenido que publique en la Aplicación, pero nos concede una licencia mundial, no exclusiva, libre de regalías para usar, mostrar y distribuir el contenido como parte de los servicios proporcionados por la Aplicación
              </p>
            </div>
            <div className="text_div">
              <h1>9. Privacidad y Protección de Datos</h1>
              <p>
                Tomamos su privacidad en serio. Al usar la Aplicación, usted acepta la recopilación y el uso de sus datos como se describe en nuestra <a href="http://https://turkafrica.app/PrivacyPolicyandCookies">Política de Privacidad</a>. Cumplimos con las leyes de protección de datos aplicables, incluido el Reglamento General de Protección de Datos (GDPR).
              </p>
            </div>
            <div className="text_div">
              <h1>10. Política de Verificación para Negocios</h1>
              <p>
                Para mantener una plataforma confiable, todos los negocios que usen la Aplicación deben completar un proceso de verificación para confirmar su legitimidad. La verificación requiere la presentación de documentos específicos dependiendo del tipo de negocio:
              </p>
              <h1>Documentos Requeridos:</h1>
              <p><span>• Para Negocios Formales:</span> Certificado de Incorporación, Certificado de Identificación Fiscal (TIN), o Licencia/Permiso Comercial.</p>
              <p><span>• Para Negocios Informales:</span> Prueba de Membresía Comercial, Permiso del Gobierno Local, o Documentos de Identificación Personal.</p>
              <p><span>• Para Emprendedores y Propietarios Únicos:</span> Formulario de Autodeclaración, Prueba de Flujos de Ingresos, o Carta de Recomendación/Endoso.</p>

              <h1>Proceso de Verificación:</h1>
              <p><span>1. Envío:</span> Suba los documentos a través de la Aplicación en la sección "Verificación".</p>
              <p><span>2. Revisión:</span> Nuestro equipo revisa las presentaciones dentro de 3 días hábiles.</p>
              <p><span>3. Aprobación:</span> Los negocios verificados reciben una insignia "Verificado" en su perfil, señalando confiabilidad</p>
              <p>Para directrices detalladas, visite Política de
                <a href="http://https://turkafrica.app/PrivacyPolicyandCookies">
                  {" "}Verificación para Negocios.
                </a>
              </p>
            </div>
            <div className="text_div">
              <h1>11. Limitación de Responsabilidad</h1>
              <p>
                En la medida permitida por la ley, no somos responsables por daños indirectos, incidentales o consecuentes que surjan de su uso de la Aplicación. Esto incluye, pero no se limita a, la pérdida de oportunidades comerciales, datos, ganancias u otras pérdidas económicas. No garantizamos la exactitud o fiabilidad del contenido publicado por los usuarios, ni endorsamos a ningún negocio o individuo que use la Aplicación
              </p>
            </div>
            <div className="text_div">
              <h1>12. Terminación</h1>
              <p>
                Nos reservamos el derecho de suspender o terminar su cuenta a nuestra sola discreción si determinamos que ha violado estos Términos o se ha involucrado en actividades ilegales o inapropiadas en la Aplicación.
              </p>
            </div>
            <div className="text_div">
              <h1>13. Enlaces de Terceros</h1>
              <p>
                La Aplicación puede contener enlaces a sitios web o servicios de terceros que no son propiedad o controlados por nosotros. No somos responsables del contenido, políticas de privacidad, o prácticas de ningún sitio web de terceros. Reconoce que el uso de sitios web de terceros es bajo su propio riesgo.
              </p>
            </div>
            <div className="text_div">
              <h1>14. Ley Aplicable</h1>
              <p>
                Estos Términos se rigen e interpretan de acuerdo con las leyes del Reino Unido. Cualquier disputa que surja de o en relación con estos Términos estará sujeta a la jurisdicción exclusiva de los tribunales del Reino Unido.
              </p>
            </div>
            <div className="text_div">
              <h1>15. Cambios en los Términos</h1>
              <p>
                Nos reservamos el derecho de modificar o actualizar estos Términos en cualquier momento. Notificaremos a los usuarios sobre cualquier cambio significativo por correo electrónico o a través de la Aplicación. Su uso continuo de la Aplicación después de tales modificaciones constituye su aceptación de los nuevos Términos.
              </p>
            </div>
            <div className="text_div">
              <h1>16. Contáctenos</h1>
              <p>
                Si tiene alguna pregunta o inquietud sobre estos Términos, contáctenos a través de nuestro formulario de contacto en <a href="https://turkafrica.app/#Contact">https://turkafrica.app/#Contact.</a>
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="sub_title_heading">
              <h6>Updated on January 2nd, 2025</h6>
            </div>
            <div className="text_div">
              <p>
                Terms of Use ("Terms") govern your use of the
                TürkAfrica B2B App ("the App"), operated by United
                Entertainment & Media Limited ("we", "our," or "us"). By
                accessing or using the App, you agree to comply with and be
                bound by these Terms. If you do not agree with any part of
                these Terms, you must not use the App.
              </p>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                TürkAfrica B2B App is a business-to-business networking
                and trade platform designed to connect businesses and
                entrepreneurs from Africa and Türkiye. The App provides
                users with access to networking opportunities, free messaging,
                voice calls, video conferencing (premium feature), business
                matchmaking services, and directories of businesses and
                investors.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Eligibility </h1>
              <p>
                The App is intended for users who are at least 18 years old
                and have the legal capacity to enter into binding contracts
                under the laws of the United Kingdom. By using the App, you
                confirm that you meet these eligibility requirements.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Registration & Account</h1>
              <p>
                To access certain features of the App, you may be required to
                register and create an account. You agree to:
                <br />
                • Provide accurate and up-to-date information during
                registration. <br />
                • Maintain the confidentiality of your account credentials.
                <br /> • Be responsible for all activities that occur under your
                account.
                <br /> • Notify us immediately of any unauthorized use of your
                account.
                <br />We reserve the right to terminate or suspend your account at
                any time if we determine that you have violated these Terms.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Services Provided</h1>
              <p>
                <span>• Free B2B Chat Messaging: </span> All registered users
                can use the free messaging service to communicate with other
                businesses. <br />
                <span>• Voice Calls & Video Conferences: </span> Available to
                premium users, these features facilitate seamless communication
                for business discussions and negotiations.
                <br /> <span>• Business Matchmaking: </span> The App offers
                algorithms to match users with relevant business contacts,
                suppliers, or investors based on user preferences and industry.{" "}
                <br />
                <span>• Database Access: </span>
                Users have access to business directories and investor databases
                to explore new markets and opportunities.
                <br />
                <span>• And More.</span>
              </p>
            </div>
            <div className="text_div">
              <h1>5. User Responsibilities</h1>
              <p>
                When using the App, you agree to:
                <ui>
                  <li>Use the App for lawful purposes only.</li>
                  <li>
                    Refrain from posting or sharing illegal, defamatory, or
                    harmful content.
                  </li>
                  <li>
                    Respect other users and refrain from engaging in harassment,
                    abuse, or inappropriate behavior.
                  </li>
                  <li>
                    Comply with all applicable laws and regulations when using
                    the App, including export control laws, trade sanctions, and
                    privacy regulations.
                  </li>
                </ui>
                You acknowledge that you are solely responsible for the content
                and information you share on the App.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Prohibited Activities</h1>
              <p>
                You agree not to:
                <ul>
                  <li>
                    Use the App to engage in fraudulent, illegal, or deceptive
                    activities.
                  </li>
                  <li>
                    Transmit viruses, malware, or other harmful software that
                    may damage the App or its users.
                  </li>
                  <li>Attempt to hack, disrupt, or reverse-engineer the App</li>
                  <li>
                    Misuse the App to send unsolicited commercial communications
                    (spam).
                  </li>
                  <li>
                    Impersonate other users or misrepresent your affiliation
                    with a business or individual.
                  </li>
                </ul>
              </p>
            </div>
            <div className="text_div">
              <h1>7. Premium Features & Payment</h1>
              <p>
                While basic services such as chat messaging are free, certain
                features (e.g., voice calls, video conferencing) require a
                premium subscription. Details of premium features and pricing
                are available on the App. Payments for premium services are
                processed through secure third-party providers, and you agree to
                provide valid payment details for such transactions.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Intellectual Property</h1>
              <p>
                All intellectual property rights in the App, including but not
                limited to logos, content, design, and software, are owned by or
                licensed to us. You may not reproduce, distribute, or modify any
                content without our written consent. <br />
                You retain ownership of any content you post on the App but
                grant us a worldwide, nonexclusive, royalty-free license to use,
                display, and distribute the content as part of the services
                provided by the App
              </p>
            </div>

            <div className="text_div">
              <h1>9. Privacy & Data Protection</h1>
              <p>
                We take your privacy seriously. By using the App, you agree to
                the collection and use of your data as outlined in our{" "}
                <a href="http://https://turkafrica.app/PrivacyPolicyandCookies">
                  Privacy Policy
                </a>{" "}
                . We comply with applicable data protection laws, including the
                General Data Protection Regulation (GDPR).
              </p>
            </div>


            <div className="text_div">
              <h1>10.  Verification Policy for Businesses</h1>
              <p>
                To maintain a trustworthy platform, all businesses using the
                App must complete a verification process to confirm their
                legitimacy. Verification requires submission of specific
                documents based on the type of business:
              </p>
              <h1>Documents Required:</h1>
              <p><span>• For Formal Businesses:</span>: Certificate of Incorporation,
                Tax Identification Number (TIN) Certificate, or Business
                License/Permit.</p>
              <p><span>•For Informal Businesses:</span> Proof of Trade Membership,
                Local Government Permit, or Personal Identification
                Documents.</p>
              <p><span>•For Entrepreneurs & Sole Proprietors:</span> SelfDeclaration Form, Proof of Revenue Streams, or
                Referral/Endorsement Letter.</p>

              <h1>Verification Process:</h1>
              <p><span>1. Submission:</span> Upload documents via the App under the
                “Verification” section.</p>
              <p><span>2. Review:</span> Our team reviews submissions within 3
                business days.</p>
              <p><span>3. Approval:</span> Verified businesses receive a “Verified”
                badge on their profile, signaling trustworthiness</p>
              <p>For detailed guidelines, visit Verification
                <a href="http://https://turkafrica.app/PrivacyPolicyandCookies">
                  {" "} Policy for  Businesses.
                </a>
              </p>
            </div>
            <div className="text_div">
              <h1>11. Limitation of Liability</h1>
              <p>
                To the extent permitted by law, we are not liable for any
                indirect, incidental, or consequential damages arising from your
                use of the App. This includes, but is not limited to, loss of
                business opportunities, data, profits, or other economic loss.
                We do not guarantee the accuracy or reliability of content
                posted by users, nor do we endorse any businesses or individuals
                using the App
              </p>
            </div>
            <div className="text_div">
              <h1>12. Termination</h1>
              <p>
                We reserve the right to suspend or terminate your account at our
                sole discretion if we determine that you have violated these
                Terms or engaged in any illegal or inappropriate activities on
                the App.
              </p>
            </div>
            <div className="text_div">
              <h1>13. Third-Party Links</h1>
              <p>
                The App may contain links to third-party websites or services
                that are not owned or controlled by us. We are not responsible
                for the content, privacy policies, or practices of any
                third-party websites. You acknowledge that your use of
                third-party websites is at your own risk.
              </p>
            </div>
            <div className="text_div">
              <h1>14. Governing Law</h1>
              <p>
                These Terms are governed by and construed in accordance with the
                laws of the United Kingdom. Any disputes arising from or in
                connection with these Terms shall be subject to the exclusive
                jurisdiction of the courts of the United Kingdom.
              </p>
            </div>
            <div className="text_div">
              <h1>15. Changes to the Terms</h1>
              <p>
                We reserve the right to modify or update these Terms at any
                time. We will notify users of any material changes via email or
                through the App. Your continued use of the App after such
                modifications constitutes acceptance of the new Terms.
              </p>
            </div>
            <div className="text_div">
              <h1>16. Contact Us</h1>
              <p>
                If you have any questions or concerns about these Terms, please
                contact us at via our contact form at{" "}
                <a href="https://turkafrica.app/#Contact">
                  https://turkafrica.app/#Contact.
                </a>
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
