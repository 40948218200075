import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./VPolicy.css"


const Index = () =>
{
    const [lang, setlang] = useState();
    console.log("🚀 ~ Index ~ lang:", lang);
    const pathname = useLocation();
    const getname = pathname?.state?.pathname?.pathname;
    useEffect(() =>
    {
        setlang(getname);
    }, [pathname]);
    const Navigation = useNavigate();

    return (
        <div className="main_div">
            <div className="arrow">
                <button
                    onClick={() =>
                        lang == "/Turkish"
                            ? Navigation("/Turkish")
                            : lang == "/French"
                                ? Navigation("/French")
                                : lang == "/Portuguese"
                                    ? Navigation("/Portuguese")
                                    : lang == "/Swahili"
                                        ? Navigation("/Swahili")
                                        : lang == "/Spanish"
                                            ? Navigation("/Spanish")
                                            : Navigation("/")
                    }
                    className="button-27"
                    role="button">
                    &#8592;
                </button>
            </div>
            <h3 className="head_text">
                {lang == "/Turkish"
                    ? <>
                        İşletmeler İçin Doğrulama Politikası <br />
                        TürkAfrica B2B Uygulamasını Kullanarak</>
                    : lang == "/French"
                        ? <> Politique de Vérification pour les Entreprises <br />
                            Utilisant l'App TurkAfrica B2B</>
                        : lang == "/Portuguese"
                            ? <>  Política de Verificação para Empresas <br />
                                que Usam o App TurkAfrica B2B</>
                            : lang == "/Swahili"
                                ? <>
                                    Sera ya Uhakiki kwa Biashara <br />
                                    Zinazotumia App ya TurkAfrica B2B</>
                                : lang == "/Spanish"
                                    ? <>   Política de Verificación para Empresas <br />
                                        que Usan la App TurkAfrica B2B</>
                                    : <>
                                        Verification Policy for Businesses <br />  Using the TurkAfrica B2B App
                                    </>
                }
            </h3>
            <div className="contacnt_div">
                {lang == "/Turkish" ? (
                    <>
                        <div className="sub_title_heading">
                            <h6>TürkAfrica B2B Uygulamasını Kullanan İşletmeler İçin Yönergeler ve Doğrulama Politikası</h6>
                            <h6>2 Ocak 2025'te güncellendi</h6>
                        </div>
                        <div className="text_div">
                            <h1>Doğrulamanın Önemi Neden</h1>
                            <p>
                                TürkAfrica B2B'de, güven ve şeffaflık platformumuzun temelidir. İşletmelerin bağlantı kurup ticaret yapabilmeleri için güvenli ve güvenilir bir ortam oluşturmak amacıyla, kullanıcıların meşruiyetlerini doğrulamalarını istiyoruz. Bu süreç:
                            </p>
                            <ul className="Unorder_list_VP">
                                <li>1. Gerçek işletmelerin bir ağını sağlayarak dolandırıcılığı ve sahte varlıkları önler.</li>
                                <li>2. Platform kullanıcıları arasında güveni artırır, uzun vadeli ortaklıkların gelişmesini teşvik eder.</li>
                                <li>3. Uluslararası iş standartlarına uyum sağlar, sorunsuz küresel ticareti mümkün kılar.</li>
                                <li>4. İşletmelerin potansiyel müşteriler ve yatırımcılar için güvenilirliklerini artırmalarına destek olur.</li>
                            </ul>

                            <p>Doğrulama sürecini tamamlayarak, işletmenizin meşruiyetini gösterir ve TürkAfrica B2B platformundaki özel avantajlardan yararlanmaya başlarsınız.</p>

                        </div>
                        <div className="text_div">
                            <h1>Doğrulama Politikası</h1>
                            <p>
                                Platformumuzda yer almak için işletmelerin işletme kategorilerine bağlı olarak belgeler sunmaları gerekir:
                                <span> Resmi İşletmeler, Gayri Resmi İşletmeler</span> veya <span>
                                    Girişimciler & Bireysel İş Sahipleri.
                                </span>
                                Doğrulama süreci, kategoriye bağlı olarak yalnızca
                                <span>
                                    2 ile 3 belge gerektirir.
                                </span>
                            </p>
                        </div>
                        <div className="text_div">
                            <h1>Gerekli Belgeler</h1>
                            <h1>Resmi İşletmeler İçin:</h1>
                            <p>
                                Devlet otoriteleri ile kayıtlı işletmeler aşağıdakilerden iki (2) tanesini sağlamalıdır:
                            </p>

                            <ul className="Unorder_list_VP">
                                <li>  <span>
                                    1. Kuruluş Sertifikası –
                                </span>
                                    İlgili otoritelerle resmi iş kaydının kanıtı</li>
                                <li><span>2. Vergi Kimlik Numarası (TIN) Sertifikası –
                                </span> Vergi uyumu doğrulaması.</li>
                                <li><span>3. İşletme Lisansı veya İzin –</span> Sektöre özel veya
                                    genel işletme lisansı.</li>
                                <li> <span>4. Kayıtlı Adres Kanıtı –</span>
                                    İşletme adına son döneme ait fatura veya
                                    kira sözleşmesi</li>
                            </ul>

                            <h1>Gayri Resmi İşletmeler İçin:</h1>
                            <p>Küçük ölçekli veya kayıtsız işletmeler aşağıdakilerden iki (2)
                                tanesini sağlamalıdır:</p>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Ticaret Üyeliği Kanıtı –</span>Yerel bir ticaret derneği veya kooperatif
                                    topluluğundan alınmış sertifika veya mektup.</li>
                                <li><span>2. Yerel Yönetim İzni –</span> Belediyeler tarafından verilen küçük ölçekli lisanslar.</li>
                                <li><span>3. Kişisel Kimlik Belgeleri –</span>Sahibin Ulusal Kimlik,
                                    pasaport veya sürücü belgesi.</li>
                                <li><span>4. Fiziksel İşletme Kanıtı –</span> İş yerinin veya günlük faaliyetlerin
                                    fotoğrafları veya videoları</li>
                            </ul>


                        </div>
                        <div className="text_div">
                            <h1>Girişimciler & Bireysel İş Sahipleri İçin:</h1>
                            <p>
                                Bireysel işletmeciler veya tek tüccarlar aşağıdakilerden iki (2) tanesini sağlamalıdır:
                            </p>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Kendi Beyan Formu –</span> TürkAfrica B2B tarafından sağlanan bir beyan formu, iş aktivitelerini açıklayan</li>
                                <li><span>2. Gelir Kaynakları Kanıtı –</span> Faturalar, makbuzlar veya
                                    dijital satış kayıtları</li>
                                <li><span>3. Referans veya Onay Mektubu –</span> Güvenilir bir yerel
                                    otoriteden, topluluk liderinden veya iş ortağından.</li>
                                <li><span>4. Banka Hesap Dökümleri veya Mobil Para Kayıtları –</span>İş operasyonlarıyla ilgili finansal aktivite.</li>
                            </ul>
                        </div>
                        <div className="text_div">
                            <h1>Belgelerin Sunulması Nasıl Yapılır</h1>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Güvenli Yükleme:</span>Belgeler, TürkAfrica B2B Uygulaması altında "Doğrulama" bölümünden yüklenebilir.</li>
                                <li><span>2. Okunaklılık Sağlayın:</span> Taranan veya fotoğraflanmış belgeler net ve okunabilir olmalıdır.</li>
                                <li><span>3. Yönergeleri Takip Edin:</span> Belgelerin işletme kategoriniz için belirtilen kriterleri karşıladığından emin olun</li>
                            </ul>
                        </div>
                        <div className="text_div">
                            <h1>Doğrulama Süreci</h1>
                            <ul className="Unorder_list_VP">
                                <li><span>1. İlk İnceleme:</span>
                                    <br />
                                    ◦ Yüklenen belgeler doğrulama ekibimiz tarafından incelenecek.
                                </li>
                                <li><span>2. Onay veya Takip:</span>
                                    <br />
                                    ◦ Belgeler standartlarımıza uygunsa, doğrulama 3 iş günü içinde onaylanır.
                                    <br />
                                    ◦ Uyuşmazlık durumunda, ek bilgi sağlamanız için sizinle iletişime geçilecektir.
                                </li>
                                <li><span>3. Son Onay</span>
                                    <br />
                                    ◦ Onaylandıktan sonra, işletme profilinizde "Doğrulandı" rozeti görünecek, diğer kullanıcılara güvenilirlik sinyali gönderir.
                                </li>

                            </ul>
                        </div>
                        <div className="text_div">
                            <h1>Sıkça Sorulan Sorular (SSS)</h1>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Gerekli belgelerden bazılarına sahip değilsem ne olur?</span>
                                    <br />◦ Bazı işletmelerin belirli belgelere sahip olmayabileceğini anlıyoruz. Alternatif doğrulama yöntemleri konusunda rehberlik için destek ekibimizle iletişime geçin {" "}
                                    <a href="https://turkafrica.app/#Contact">
                                        https://turkafrica.app/#Contact.
                                    </a></li>
                                <li><span>2. Bilgilerimin güvenli olduğundan nasıl emin olabilirim?</span>
                                    <br />◦ Tüm veri ve belgeler, küresel veri koruma standartlarına uygun olarak güvenli bir şekilde saklanır.</li>
                                <li><span>3. Doğrulamam reddedilirse ne olur?</span>
                                    <br />◦ Sorunu ele almak için ayrıntılı geri bildirim alacaksınız ve yeniden gönderebilirsiniz.</li>
                            </ul>
                            <p> Doğrulamanızı tamamlayarak, TürkAfrica B2B'de güvenilir, profesyonel ve gelişen bir işletme topluluğuna katkıda bulunursunuz. Güvenilir ticaret ilişkilerini teşvik etmeye yönelik işbirliğinizi ve taahhüdünüzü takdir ediyoruz.</p>
                        </div>

                    </>

                ) : lang == "/French" ? (
                    <>
                        <div className="sub_title_heading">
                            <h6>Directives et Politique de Vérification pour les Entreprises Utilisant l'Application TurkAfrica B2B</h6>
                            <h6>Mis à jour le 2 janvier 2025</h6>
                        </div>
                        <div className="text_div">
                            <h1>Pourquoi la Vérification est Importante</h1>
                            <p>
                                Chez TurkAfrica B2B, la confiance et la transparence sont au cœur de notre plateforme. Pour créer un environnement sûr et crédible où les entreprises peuvent se connecter et commercer, nous exigeons que les utilisateurs vérifient leur légitimité. Ce processus :
                            </p>
                            <ul className="Unorder_list_VP">
                                <li>1. Assure un réseau d'entreprises authentiques, prévenant la fraude et les entités fictives.</li>
                                <li>2. Renforce la confiance entre les utilisateurs de la plateforme, favorisant des partenariats à long terme.</li>
                                <li>3. Se conforme aux normes commerciales internationales, permettant un commerce mondial sans heurts.</li>
                                <li>4. Aide les entreprises à construire leur crédibilité pour les clients potentiels et les investisseurs.</li>
                            </ul>

                            <p>En complétant le processus de vérification, votre entreprise démontre sa légitimité et accède à des avantages exclusifs sur la plateforme TurkAfrica B2B.</p>

                        </div>
                        <div className="text_div">
                            <h1>Politique de Vérification</h1>
                            <p>
                                Pour participer sur notre plateforme, les entreprises doivent fournir des documents basés sur leur catégorie opérationnelle :
                                <span> Entreprises Formelles, Entreprises Informelles</span>, ou <span>
                                    Entrepreneurs & Propriétaires Uniques.
                                </span>
                                Le processus de vérification nécessite seulement
                                <span>
                                    2 à 3 documents,
                                </span>
                                selon la catégorie.
                            </p>
                        </div>
                        <div className="text_div">
                            <h1>Documents Requis</h1>
                            <h1>Pour les Entreprises Formelles :</h1>
                            <p>
                                Les entreprises enregistrées auprès des autorités gouvernementales doivent fournir deux (2) des documents suivants :
                            </p>

                            <ul className="Unorder_list_VP">
                                <li>  <span>
                                    1. Certificat d'Incorporation –
                                </span>
                                    Preuve d'enregistrement officiel de l'entreprise auprès des autorités pertinentes</li>
                                <li><span>2. Certificat de Numéro d'Identification Fiscale (TIN) –
                                </span> Vérification de la conformité fiscale.</li>
                                <li><span>3. Licence Commerciale ou Permis –</span> Licence spécifique à l'industrie ou
                                    permis d'exploitation général.</li>
                                <li> <span>4. Preuve de l'Adresse Enregistrée –</span>
                                    Facture de services récente ou
                                    contrat de location au nom de l'entreprise</li>
                            </ul>

                            <h1>Pour les Entreprises Informelles :</h1>
                            <p>Les petites entreprises ou entreprises non enregistrées doivent fournir deux (2)
                                des documents suivants :</p>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Preuve d'Adhésion Commerciale –</span> Certificat ou lettre d'une
                                    association commerciale locale reconnue ou société coopérative.</li>
                                <li><span>2. Permis du Gouvernement Local –</span> Licences à petite échelle délivrées
                                    par les municipalités.</li>
                                <li><span>3. Documents d'Identification Personnelle –</span>ID national,
                                    passeport ou permis de conduire du propriétaire.</li>
                                <li><span>4. Preuve d'Opération Physique –</span>Photos ou vidéos des
                                    locaux commerciaux ou des activités quotidiennes</li>
                            </ul>


                        </div>
                        <div className="text_div">
                            <h1>Pour les Entrepreneurs & Propriétaires Uniques :</h1>
                            <p>
                                Les opérateurs individuels ou les commerçants uniques doivent fournir deux (2) des
                                documents suivants :
                            </p>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Formulaire de Déclaration Personnelle –</span> Un modèle de déclaration
                                    fourni par TurkAfrica B2B, décrivant leurs activités commerciales</li>
                                <li><span>2. Preuve des Flux de Revenus –</span> Factures, reçus, ou
                                    registres de ventes numériques</li>
                                <li><span>3. Lettre de Recommandation ou d'Endossement –</span> D'une autorité locale de confiance,
                                    leader communautaire, ou partenaire commercial.</li>
                                <li><span>4. Relevés Bancaires ou Enregistrements de Monnaie Mobile –</span>Activité financière liée aux opérations commerciales.</li>
                            </ul>
                        </div>
                        <div className="text_div">
                            <h1>Comment Soumettre les Documents</h1>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Téléchargez Sécurisé:</span>Les documents peuvent être téléchargés via l'application
                                    TurkAfrica B2B dans la section « Vérification ».</li>
                                <li><span>2. Assurez la Lisibilité:</span> Les documents numérisés ou photographiés
                                    doivent être clairs et lisibles.</li>
                                <li><span>3. Suivez les Directives:</span> Assurez-vous que les documents correspondent aux
                                    critères spécifiés pour votre catégorie d'entreprise</li>
                            </ul>
                        </div>
                        <div className="text_div">
                            <h1>Processus de Vérification</h1>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Révision Initiale:</span>
                                    <br />
                                    ◦ Les documents téléchargés seront examinés par notre équipe de vérification.
                                </li>
                                <li><span>2. Approbation ou Suivi:</span>
                                    <br />
                                    ◦ Si les documents répondent à nos normes, la vérification sera approuvée dans un délai de 3 jours ouvrables.
                                    <br />
                                    ◦ En cas de discordances, vous serez contacté pour fournir des informations supplémentaires.
                                </li>
                                <li><span>3. Confirmation Finale</span>
                                    <br />
                                    ◦ Une fois approuvé, votre profil d'entreprise affichera un badge « Vérifié », signalant la fiabilité aux autres utilisateurs.
                                </li>

                            </ul>
                        </div>
                        <div className="text_div">
                            <h1>Foire Aux Questions (FAQ)</h1>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Que faire si je n'ai pas certains des documents requis ?</span>
                                    <br />◦ Nous comprenons que certaines entreprises peuvent manquer de certains documents. Contactez notre équipe de support pour obtenir des conseils sur les méthodes de vérification alternatives {" "}
                                    <a href="https://turkafrica.app/#Contact">
                                        https://turkafrica.app/#Contact.
                                    </a></li>
                                <li><span>2. Comment puis-je m'assurer que mes informations sont sécurisées ?</span>
                                    <br />◦ Toutes les données et documents sont stockés de manière sécurisée,
                                    conformément aux normes mondiales de protection des données.</li>
                                <li><span>3. Que se passe-t-il si ma vérification est rejetée ?</span>
                                    <br />◦ Vous recevrez des commentaires détaillés pour résoudre le
                                    problème et pourrez soumettre de nouveau.</li>
                            </ul>
                            <p>En complétant votre vérification, vous contribuez à une communauté d'affaires de confiance,
                                professionnelle et prospère sur TurkAfrica B2B. Nous apprécions votre coopération et votre engagement à
                                favoriser des relations commerciales fiables.</p>
                        </div>

                    </>

                ) : lang == "/Portuguese" ? (
                    <>
                        <div className="sub_title_heading">
                            <h6>Diretrizes e Política de Verificação para Empresas que Utilizam o App TurkAfrica B2B</h6>
                            <h6>Atualizado em 2 de janeiro de 2025</h6>
                        </div>
                        <div className="text_div">
                            <h1>Por Que a Verificação é Importante</h1>
                            <p>
                                Na TurkAfrica B2B, confiança e transparência são fundamentais para nossa plataforma. Para criar um ambiente seguro e credível para as empresas se conectarem e negociarem, exigimos que os usuários verifiquem sua legitimidade. Este processo:
                            </p>
                            <ul className="Unorder_list_VP">
                                <li>1. Assegura uma rede de empresas autênticas, prevenindo fraudes e entidades falsas.</li>
                                <li>2. Aumenta a confiança entre os usuários da plataforma, fomentando parcerias de longo prazo.</li>
                                <li>3. Está em conformidade com os padrões de negócios internacionais, permitindo um comércio global sem problemas.</li>
                                <li>4. Apoia as empresas na construção de credibilidade para clientes e investidores potenciais.</li>
                            </ul>

                            <p>Ao completar o processo de verificação, sua empresa demonstra sua legitimidade e ganha acesso a benefícios exclusivos na plataforma TurkAfrica B2B.</p>

                        </div>
                        <div className="text_div">
                            <h1>Política de Verificação</h1>
                            <p>
                                Para participar em nossa plataforma, as empresas devem fornecer documentos com base em sua categoria operacional:
                                <span> Empresas Formais, Empresas Informais</span>, ou <span>
                                    Empreendedores & Proprietários Individuais.
                                </span>
                                O processo de verificação requer apenas
                                <span>
                                    2 a 3 documentos,
                                </span>
                                dependendo da categoria.
                            </p>
                        </div>
                        <div className="text_div">
                            <h1>Documentos Necessários</h1>
                            <h1>Para Empresas Formais:</h1>
                            <p>
                                Empresas registradas junto às autoridades governamentais devem fornecer dois (2) dos seguintes documentos:
                            </p>

                            <ul className="Unorder_list_VP">
                                <li>  <span>
                                    1. Certificado de Incorporação –
                                </span>
                                    Prova de registro oficial do negócio com as autoridades relevantes</li>
                                <li><span>2. Certificado de Número de Identificação Fiscal (TIN) –
                                </span> Verificação de conformidade fiscal.</li>
                                <li><span>3. Licença ou Permissão Comercial –</span> Licença específica do setor ou
                                    permissão geral de operação.</li>
                                <li> <span>4. Prova de Endereço Registrado –</span>
                                    Conta de utilidade recente ou
                                    contrato de arrendamento em nome do negócio</li>
                            </ul>

                            <h1>Para Empresas Informais:</h1>
                            <p>Empresas de pequena escala ou não registradas devem fornecer dois (2) dos seguintes documentos:</p>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Prova de Membro de Comércio –</span> Certificado ou carta de uma
                                    associação comercial local reconhecida ou sociedade cooperativa.</li>
                                <li><span>2. Permissão do Governo Local –</span> Licenças de pequena escala emitidas
                                    pelas municipalidades.</li>
                                <li><span>3. Documentos de Identificação Pessoal –</span> ID nacional,
                                    passaporte ou carteira de motorista do proprietário.</li>
                                <li><span>4. Prova de Operação Física –</span> Fotos ou vídeos das
                                    instalações comerciais ou atividades diárias</li>
                            </ul>


                        </div>
                        <div className="text_div">
                            <h1>Para Empreendedores & Proprietários Individuais:</h1>
                            <p>
                                Operadores individuais ou comerciantes únicos devem fornecer dois (2) dos
                                seguintes documentos:
                            </p>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Formulário de Autodeclaração –</span> Um modelo de declaração
                                    fornecido pela TurkAfrica B2B, descrevendo suas atividades comerciais</li>
                                <li><span>2. Prova de Fluxos de Receita –</span> Faturas, recibos ou
                                    registros de vendas digitais</li>
                                <li><span>3. Carta de Referência ou Endosso –</span> De uma autoridade local confiável,
                                    líder comunitário ou parceiro de negócios.</li>
                                <li><span>4. Extratos Bancários ou Registros de Dinheiro Móvel –</span> Atividade financeira relacionada às operações comerciais.</li>
                            </ul>
                        </div>
                        <div className="text_div">
                            <h1>Como Submeter os Documentos</h1>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Carregar de Forma Segura:</span> Os documentos podem ser carregados através do
                                    app TurkAfrica B2B na seção "Verificação".</li>
                                <li><span>2. Garantir Legibilidade:</span> Documentos digitalizados ou fotografados
                                    devem ser claros e legíveis.</li>
                                <li><span>3. Seguir as Diretrizes:</span> Garantir que os documentos correspondam aos
                                    critérios especificados para a sua categoria empresarial</li>
                            </ul>
                        </div>
                        <div className="text_div">
                            <h1>Processo de Verificação</h1>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Revisão Inicial:</span>
                                    <br />
                                    ◦ Os documentos carregados passarão por uma revisão pela nossa equipe de verificação.
                                </li>
                                <li><span>2. Aprovação ou Acompanhamento:</span>
                                    <br />
                                    ◦ Se os documentos atenderem aos nossos padrões, a verificação será aprovada dentro de 3 dias úteis.
                                    <br />
                                    ◦ Em caso de discrepâncias, você será contatado para fornecer informações adicionais.
                                </li>
                                <li><span>3. Confirmação Final</span>
                                    <br />
                                    ◦ Após aprovação, seu perfil empresarial exibirá um distintivo "Verificado", sinalizando confiabilidade para outros usuários.
                                </li>

                            </ul>
                        </div>
                        <div className="text_div">
                            <h1>Perguntas Frequentes (FAQ)</h1>
                            <ul className="Unorder_list_VP">
                                <li><span>1. E se eu não tiver alguns dos documentos necessários?</span>
                                    <br />◦ Entendemos que algumas empresas podem não ter certos documentos. Contate nossa equipe de suporte para orientação sobre métodos alternativos de verificação {" "}
                                    <a href="https://turkafrica.app/#Contact">
                                        https://turkafrica.app/#Contact.
                                    </a></li>
                                <li><span>2. Como posso garantir que minhas informações estão seguras?</span>
                                    <br />◦ Todos os dados e documentos são armazenados de forma segura,
                                    cumprindo com os padrões globais de proteção de dados.</li>
                                <li><span>3. O que acontece se minha verificação for rejeitada?</span>
                                    <br />◦ Você receberá um feedback detalhado para resolver o
                                    problema e poderá reenviar.</li>
                            </ul>
                            <p>Ao completar sua verificação, você contribui para uma comunidade de negócios confiável,
                                profissional e próspera na TurkAfrica B2B. Agradecemos sua cooperação e compromisso em
                                fomentar relações comerciais confiáveis.</p>
                        </div>

                    </>

                ) : lang == "/Swahili" ? (
                    <>
                        <div className="sub_title_heading">
                            <h6>Mwongozo na Sera ya Uthibitisho kwa Biashara Zinazotumia App ya TurkAfrica B2B</h6>
                            <h6>Imesasishwa tarehe 2 Januari 2025</h6>
                        </div>
                        <div className="text_div">
                            <h1>Kwa Nini Uthibitisho ni Muhimu</h1>
                            <p>
                                Kwenye TurkAfrica B2B, uaminifu na uwazi ni msingi wa jukwaa letu. Ili kuunda mazingira salama na yenye kuaminika kwa biashara kuungana na kufanya biashara, tunahitaji watumiaji kuthibitisha uhalali wao. Mchakato huu:
                            </p>
                            <ul className="Unorder_list_VP">
                                <li>1. Uhakikisha mtandao wa biashara halisi, ukiepuka udanganyifu na taasisi bandia.</li>
                                <li>2. Huongeza uaminifu kati ya watumiaji wa jukwaa, kukuza ushirikiano wa muda mrefu.</li>
                                <li>3. Unazingatia viwango vya biashara vya kimataifa, kuruhusu biashara ya kimataifa bila usumbufu.</li>
                                <li>4. Inasaidia biashara kujenga uaminifu kwa wateja watarajiwa na wawekezaji.</li>
                            </ul>

                            <p>Kwa kukamilisha mchakato wa uthibitisho, biashara yako inaonyesha uhalali wake na kupata faida za kipekee kwenye jukwaa la TurkAfrica B2B.</p>

                        </div>
                        <div className="text_div">
                            <h1>Sera ya Uthibitisho</h1>
                            <p>
                                Ili kushiriki kwenye jukwaa letu, biashara lazima zitoe nyaraka kulingana na kategoria yao ya uendeshaji:
                                <span> Biashara Rasmi, Biashara Zisizo Rasmi</span>, au <span>
                                    Wajasiriamali & Wamiliki Binafsi.
                                </span>
                                Mchakato wa uthibitisho unahitaji tu
                                <span>
                                    nyaraka 2 hadi 3,
                                </span>
                                kulingana na kategoria.
                            </p>
                        </div>
                        <div className="text_div">
                            <h1>Nyaraka Zinazohitajika</h1>
                            <h1>Kwa Biashara Rasmi:</h1>
                            <p>
                                Biashara zilizosajiliwa na mamlaka za serikali zinapaswa kutoa mbili (2) kati ya zifuatazo:
                            </p>

                            <ul className="Unorder_list_VP">
                                <li>  <span>
                                    1. Cheti cha Usajili –
                                </span>
                                    Uthibitisho wa usajili rasmi wa biashara na mamlaka husika</li>
                                <li><span>2. Cheti cha Nambari ya Utambulisho wa Kodi (TIN) –
                                </span> Uthibitisho wa kufuata sheria za kodi.</li>
                                <li><span>3. Leseni ya Biashara au Kibali –</span> Leseni mahususi ya sekta au
                                    kibali cha uendeshaji kwa ujumla.</li>
                                <li> <span>4. Uthibitisho wa Anwani Ilizosajiliwa –</span>
                                    Ankara ya huduma za hivi karibuni au
                                    mkataba wa upangaji kwa jina la biashara</li>
                            </ul>

                            <h1>Kwa Biashara Zisizo Rasmi:</h1>
                            <p>Biashara ndogo ndogo au zisizosajiliwa zinapaswa kutoa mbili (2)
                                kati ya zifuatazo:</p>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Uthibitisho wa Uanachama wa Biashara –</span> Cheti au barua kutoka
                                    kwa chama cha biashara cha ndani kinachotambulika au jumuiya ya ushirika.</li>
                                <li><span>2. Kibali cha Serikali ya Mitaa –</span> Leseni za kiwango cha chini zilizotolewa
                                    na manispaa.</li>
                                <li><span>3. Nyaraka za Utambulisho Binafsi –</span> Kitambulisho cha kitaifa,
                                    pasipoti, au leseni ya udereva ya mmiliki.</li>
                                <li><span>4. Uthibitisho wa Uendeshaji wa Kimwili –</span>Picha au video za
                                    majengo ya biashara au shughuli za kila siku</li>
                            </ul>


                        </div>
                        <div className="text_div">
                            <h1>Kwa Wajasiriamali & Wamiliki Binafsi:</h1>
                            <p>
                                Waendeshaji binafsi au wafanyabiashara pekee wanapaswa kutoa mbili (2) kati ya
                                nyaraka zifuatazo:
                            </p>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Fomu ya Kujitangaza –</span> Kiolezo cha tamko
                                    kilichotolewa na TurkAfrica B2B, kikielezea shughuli zao za biashara</li>
                                <li><span>2. Uthibitisho wa Vyanzo vya Mapato –</span> Ankara, risiti, au
                                    kumbukumbu za mauzo ya kidijitali</li>
                                <li><span>3. Barua ya Rejea au Uthibitisho –</span> Kutoka kwa mamlaka ya ndani inayoaminika,
                                    kiongozi wa jamii, au mshirika wa biashara.</li>
                                <li><span>4. Taarifa za Benki au Kumbukumbu za Pesa za Mkononi –</span> Shughuli za kifedha zinazohusiana na operesheni za biashara.</li>
                            </ul>
                        </div>
                        <div className="text_div">
                            <h1>Jinsi ya Kuwasilisha Nyaraka</h1>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Pakia kwa Usalama:</span> Nyaraka zinaweza kupakiwa kupitia
                                    app ya TurkAfrica B2B chini ya sehemu ya "Uthibitisho".</li>
                                <li><span>2. Hakikisha Inasomeka:</span> Nyaraka zilizoscaniwa au kupigwa picha
                                    zinapaswa kuwa wazi na zinasomeka.</li>
                                <li><span>3. Fuata Mwongozo:</span> Hakikisha nyaraka zinakidhi vigezo
                                    vilivyotajwa kwa kategoria yako ya biashara</li>
                            </ul>
                        </div>
                        <div className="text_div">
                            <h1>Mchakato wa Uthibitisho</h1>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Mapitio ya Awali:</span>
                                    <br />
                                    ◦ Nyaraka zilizopakiwa zitakaguliwa na timu yetu ya uthibitisho.
                                </li>
                                <li><span>2. Idhini au Ufuatiliaji:</span>
                                    <br />
                                    ◦ Ikiwa nyaraka zinakidhi viwango vyetu, uthibitisho utaidhinishwa ndani ya siku 3 za kazi.
                                    <br />
                                    ◦ Ikiwa kuna tofauti, utaombwa kutoa maelezo zaidi.
                                </li>
                                <li><span>3. Thibitisho la Mwisho</span>
                                    <br />
                                    ◦ Baada ya idhini, wasifu wa biashara yako utaonyesha beji ya "Imethibitishwa", ikionyesha kuaminika kwa watumiaji wengine.
                                </li>

                            </ul>
                        </div>
                        <div className="text_div">
                            <h1>Maswali Yanayoulizwa Mara kwa Mara (FAQ)</h1>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Nifanye nini ikiwa sina baadhi ya nyaraka zinazohitajika?</span>
                                    <br />◦ Tunaelewa kuwa baadhi ya biashara zinaweza kukosa nyaraka fulani. Wasiliana na timu yetu ya usaidizi kwa mwongozo kuhusu mbinu mbadala za uthibitisho {" "}
                                    <a href="https://turkafrica.app/#Contact">
                                        https://turkafrica.app/#Contact.
                                    </a></li>
                                <li><span>2. Ninawezaje kuhakikisha kuwa taarifa zangu ziko salama?</span>
                                    <br />◦ Data zote na nyaraka zinahifadhiwa kwa usalama,
                                    zikifuata viwango vya kimataifa vya ulinzi wa data.</li>
                                <li><span>3. Nini kitatokea ikiwa uthibitisho wangu utakataliwa?</span>
                                    <br />◦ Utapokea maoni ya kina ili kushughulikia
                                    suala hilo na unaweza kuwasilisha tena.</li>
                            </ul>
                            <p>Kwa kukamilisha uthibitisho wako, unachangia kwenye jumuiya ya biashara inayoaminika,
                                yenye ufanisi na inayostawi kwenye TurkAfrica B2B. Tunathamini ushirikiano wako na kujitolea katika
                                kukuza uhusiano wa biashara unaotegemewa.</p>
                        </div>

                    </>

                ) : lang == "/Spanish" ? (
                    <>
                        <div className="sub_title_heading">
                            <h6>Directrices y Política de Verificación para Empresas que Usan la Aplicación TurkAfrica B2B</h6>
                            <h6>Actualizado el 2 de enero de 2025</h6>
                        </div>
                        <div className="text_div">
                            <h1>Por Qué es Importante la Verificación</h1>
                            <p>
                                En TurkAfrica B2B, la confianza y la transparencia son el núcleo de nuestra plataforma. Para crear un ambiente seguro y creíble para que las empresas se conecten y comercien, requerimos que los usuarios verifiquen su legitimidad. Este proceso:
                            </p>
                            <ul className="Unorder_list_VP">
                                <li>1. Asegura una red de negocios genuinos, previniendo el fraude y entidades falsas.</li>
                                <li>2. Mejora la confianza entre los usuarios de la plataforma, fomentando asociaciones a largo plazo.</li>
                                <li>3. Cumple con los estándares de negocios internacionales, habilitando el comercio global sin problemas.</li>
                                <li>4. Apoya a las empresas en construir credibilidad para clientes potenciales e inversores.</li>
                            </ul>

                            <p>Al completar el proceso de verificación, su empresa demuestra su legitimidad y gana acceso a beneficios exclusivos en la plataforma TurkAfrica B2B.</p>

                        </div>
                        <div className="text_div">
                            <h1>Política de Verificación</h1>
                            <p>
                                Para participar en nuestra plataforma, las empresas deben proporcionar documentos basados en su categoría operacional:
                                <span> Empresas Formales, Empresas Informales, </span> o <span>
                                    Emprendedores y Propietarios Únicos.
                                </span>
                                El proceso de verificación requiere solo
                                <span>
                                    2 a 3 documentos,
                                </span>
                                dependiendo de la categoría.
                            </p>
                        </div>
                        <div className="text_div">
                            <h1>Documentos Requeridos</h1>
                            <h1>Para Empresas Formales:</h1>
                            <p>
                                Las empresas registradas con autoridades gubernamentales deben proporcionar dos (2) de los siguientes:
                            </p>


                            <ul className="Unorder_list_VP">
                                <li>  <span>
                                    1. Certificado de Incorporación –
                                </span>
                                    Prueba de registro oficial de negocios con autoridades relevantes</li>
                                <li><span>2. Certificado de Número de Identificación Fiscal (NIF) –
                                </span> Verificación de cumplimiento fiscal.</li>
                                <li><span>3. Licencia Comercial o Permiso –</span> Licencia específica de la industria o general para operar.</li>
                                <li> <span>4. Prueba de Dirección Registrada –</span>
                                    Factura reciente de servicios públicos o acuerdo de arrendamiento a nombre del negocio</li>
                            </ul>

                            <h1>Para Empresas Informales:</h1>
                            <p>Las empresas a pequeña escala o no registradas deben proporcionar dos (2) de los siguientes:</p>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Prueba de Membresía Comercial –</span>Certificado o carta de una asociación comercial local o sociedad cooperativa reconocida.</li>
                                <li><span>2. Permiso del Gobierno Local –</span> Licencias a pequeña escala emitidas por municipalidades.</li>
                                <li><span>3. Documentos de Identificación Personal –</span>ID nacional, pasaporte o licencia de conducir del propietario.</li>
                                <li><span>4. Prueba de Operación Física –</span>Fotos o videos de las instalaciones comerciales o actividades diarias</li>
                            </ul>


                        </div>
                        <div className="text_div">
                            <h1>Para Emprendedores y Propietarios Únicos:</h1>
                            <p>
                                Los operadores individuales o comerciantes únicos deben proporcionar dos (2) de los siguientes:
                            </p>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Formulario de Autodeclaración –</span> Una plantilla proporcionada por TurkAfrica B2B, que describe sus actividades comerciales</li>
                                <li><span>2. Prueba de Flujos de Ingresos –</span> Facturas, recibos o registros de ventas digitales</li>
                                <li><span>3. Carta de Referencia o Aval –</span> De una autoridad local confiable, líder comunitario o socio comercial.</li>
                                <li><span>4. Estados de Cuenta Bancarios o Registros de Dinero Móvil –</span>Actividad financiera relacionada con operaciones comerciales.</li>
                            </ul>
                        </div>
                        <div className="text_div">
                            <h1>Cómo Enviar Documentos</h1>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Subir de Forma Segura:</span>Los documentos se pueden subir a través de la App de TurkAfrica B2B en la sección “Verificación”.</li>
                                <li><span>2. Asegurar Legibilidad:</span> Los documentos escaneados o fotografiados deben ser claros y legibles.</li>
                                <li><span>3. Seguir Directrices:</span> Asegúrese de que los documentos cumplan con los criterios especificados para su categoría empresarial</li>
                            </ul>
                        </div>
                        <div className="text_div">
                            <h1>Proceso de Verificación</h1>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Revisión Inicial:</span>
                                    <br />
                                    ◦ Los documentos subidos serán revisados por nuestro equipo de verificación.
                                </li>
                                <li><span>2. Aprobación o Seguimiento:</span>
                                    <br />
                                    ◦ Si los documentos cumplen con nuestros estándares, la verificación será aprobada dentro de 3 días hábiles.
                                    <br />
                                    ◦ En caso de discrepancias, se le contactará para proporcionar información adicional.
                                </li>
                                <li><span>3. Confirmación Final</span>
                                    <br />
                                    ◦ Una vez aprobado, su perfil de empresa mostrará una insignia “Verificado”, señalando confiabilidad a otros usuarios.
                                </li>

                            </ul>
                        </div>
                        <div className="text_div">
                            <h1>Preguntas Frecuentes (FAQs)</h1>
                            <ul className="Unorder_list_VP">
                                <li><span>1. ¿Qué pasa si no tengo algunos de los documentos requeridos?</span>
                                    <br />◦ Entendemos que algunas empresas pueden carecer de ciertos documentos. Contacte a nuestro equipo de soporte para obtener orientación sobre métodos de verificación alternativos
                                    <a href="https://turkafrica.app/#Contact">
                                        https://turkafrica.app/#Contact.
                                    </a></li>
                                <li><span>2. ¿Cómo aseguro que mi información sea segura?</span>
                                    <br />◦ Todos los datos y documentos se almacenan de manera segura, cumpliendo con los estándares globales de protección de datos.</li>
                                <li><span>3. ¿Qué sucede si mi verificación es rechazada?</span>
                                    <br />◦ Recibirá comentarios detallados para abordar el problema y puede volver a enviar.</li>
                            </ul>
                            <p> al completar su verificación, contribuye a una comunidad empresarial confiable, profesional y próspera en TurkAfrica B2B. Apreciamos su cooperación y compromiso para fomentar relaciones comerciales fiables.</p>
                        </div>

                    </>

                ) : (
                    <>
                        <div className="sub_title_heading">
                            <h6>Guidelines and Verification Policy for Businesses Using
                                the TurkAfrica B2B App</h6>
                            <h6>Updated on January 2nd, 2025</h6>
                        </div>
                        <div className="text_div">
                            <h1>Why Verification is Important</h1>
                            <p>
                                At TurkAfrica B2B, trust and transparency are at the core of
                                our platform. To create a safe and credible environment for
                                businesses to connect and trade, we require users to verify
                                their legitimacy. This process:
                            </p>
                            <ul className="Unorder_list_VP">
                                <li>1. Ensures a network of genuine businesses, preventing
                                    fraud and fake entities.</li>
                                <li>2. Enhances trust between platform users, fostering longterm partnerships.</li>
                                <li>3. Complies with international business standards, enabling
                                    seamless global trade.</li>
                                <li>4. Supports businesses in building credibility for potential
                                    clients and investors.</li>
                            </ul>

                            <p>By completing the verification process, your business
                                demonstrates its legitimacy and gains access to exclusive
                                benefits on the TurkAfrica B2B platform.</p>

                        </div>
                        <div className="text_div">
                            <h1>Verification Policy</h1>
                            <p>
                                To participate on our platform, businesses must provide
                                documents based on their operational category:
                                <span>{" "}Formal Businesses, Informal Businesses, </span> or <span>
                                    Entrepreneurs & Sole Proprietors.
                                </span>
                                The verification process requires only
                                <span>
                                    {" "}2 to 3  documents,
                                </span>
                                depending on the category.
                            </p>
                        </div>
                        <div className="text_div">
                            <h1>Documents Required</h1>
                            <h1>For Formal Businesses:</h1>
                            <p>
                                Businesses registered with government authorities must
                                provide two (2) of the following:
                            </p>


                            <ul className="Unorder_list_VP">
                                <li>  <span>
                                    1. Certificate of Incorporation –
                                </span>
                                    Proof of official business
                                    registration with relevant authorities</li>
                                <li><span>2. Tax Identification Number (TIN) Certificate –
                                </span> Verification of tax compliance.</li>
                                <li><span>3.Business License or Permit –</span> Industry-specific or
                                    general operating license.</li>
                                <li> <span>4. Proof of Registered Address –</span>
                                    Recent utility bill or
                                    tenancy agreement in the business name</li>
                            </ul>

                            <h1>For Informal Businesses:</h1>
                            <p>Small-scale or unregistered businesses must provide two (2)
                                of the following:</p>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Proof of Trade Membership –</span>Certificate or letter from
                                    a recognized local trade association or cooperative
                                    society.</li>
                                <li><span>2. Local Government Permit –</span> Small-scale licenses issued
                                    by municipalities.</li>
                                <li><span>3. Personal Identification Documents –</span>National ID,
                                    passport, or driver’s license of the owner.</li>
                                <li><span>4. Proof of Physical Operation –</span>Photos or videos of the
                                    business premises or daily activities</li>
                            </ul>


                        </div>
                        <div className="text_div">
                            <h1>For Entrepreneurs & Sole Proprietors:</h1>
                            <p>
                                Individual operators or sole traders must provide two (2) of
                                the following:
                            </p>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Self-Declaration Form –</span> A declaration template
                                    provided by TurkAfrica B2B, outlining their business
                                    activities</li>
                                <li><span>2. Proof of Revenue Streams –</span> Invoices, receipts, or
                                    digital sales records</li>
                                <li><span>3. Referral or Endorsement Letter –</span> From a trusted local
                                    authority, community leader, or business partner.</li>
                                <li><span>4. Bank Statements or Mobile Money Records –</span>Financial activity related to business operations.</li>
                            </ul>
                        </div>
                        <div className="text_div">
                            <h1>How to Submit Documents</h1>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Upload Securely:</span>Documents can be uploaded via the
                                    TurkAfrica B2B App under the “Verification” section.</li>
                                <li><span>2. Ensure Legibility:</span> Scanned or photographed documents
                                    must be clear and readable.</li>
                                <li><span>3. Follow Guidelines:</span> Ensure the documents match the
                                    criteria specified for your business category</li>
                            </ul>
                        </div>
                        <div className="text_div">
                            <h1>Verification Process</h1>
                            <ul className="Unorder_list_VP">
                                <li><span>1. Initial Review:</span>
                                    <br />
                                    ◦ Uploaded documents will undergo a review by our
                                    verification team.
                                </li>
                                <li><span>2. Approval or Follow-Up:</span>
                                    <br />
                                    ◦ If documents meet our standards, verification will
                                    be approved within 3 business days.
                                    <br />
                                    ◦ In case of discrepancies, you will be contacted to
                                    provide additional information.
                                </li>
                                <li><span>3. Final Confirmation</span>
                                    <br />
                                    ◦ Upon approval, your Business profile will display a
                                    “Verified” badge, signaling trustworthiness to other
                                    users.
                                </li>

                            </ul>
                        </div>
                        <div className="text_div">
                            <h1>Frequently Asked Questions (FAQs)</h1>
                            <ul className="Unorder_list_VP">
                                <li><span>1. What if I don’t have some of the required
                                    documents?</span>
                                    <br />◦ We understand some businesses may lack certain
                                    documents. Contact our support team for guidance
                                    on alternative verification methods {" "}
                                    <a href="https://turkafrica.app/#Contact">
                                        https://turkafrica.app/#Contact.
                                    </a></li>
                                <li><span>2. How do I ensure my information is secure?</span>
                                    <br />◦ All data and documents are stored securely,
                                    complying with global data protection standards.</li>
                                <li><span>3. What happens if my verification is rejected?</span>
                                    <br />◦ You will receive detailed feedback to address the
                                    issue and can re-submit.</li>
                            </ul>
                            <p> completing your verification, you contribute to a trusted,
                                professional, and thriving business community on TurkAfrica
                                B2B. We appreciate your cooperation and commitment to
                                fostering reliable trade relationships.</p>
                        </div>

                    </>
                )}
            </div>
        </div >
    );
};

export default Index;
