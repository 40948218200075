import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Index = () =>
{
  const [lang, setlang] = useState();
  console.log("🚀 ~ Index ~ lang:", lang);
  const pathname = useLocation();
  const getname = pathname?.state?.pathname?.pathname;
  useEffect(() =>
  {
    setlang(getname);
  }, [pathname]);
  const Navigation = useNavigate();
  return (
    <div className="main_div">
      <div className="arrow">
        <button
          onClick={() =>
            lang == "/Turkish"
              ? Navigation("/Turkish")
              : lang == "/French"
                ? Navigation("/French")
                : lang == "/Portuguese"
                  ? Navigation("/Portuguese")
                  : lang == "/Swahili"
                    ? Navigation("/Swahili")
                    : lang == "/Spanish"
                      ? Navigation("/Spanish")
                      : Navigation("/")
          }
          className="button-27"
          role="button">
          &#8592;
        </button>
      </div>
      <h3 className="head_text">
        {lang == "/Turkish"
          ? "TürkAfrica.Market Güvenlik ve Emniyet"
          : lang == "/French"
            ? "Sécurité et Protection chez TürkAfrica.Market"
            : lang == "/Portuguese"
              ? "Segurança e Proteção na TürkAfrica.Market"
              : lang == "/Swahili"
                ? "Usalama na Ulinzi wa TürkAfrica.Market"
                : lang == "/Spanish"
                  ? "TürkAfrica.Market Seguridad y Protección"
                  : "TürkAfrica.Market Safety & Security"}
      </h3>
      <div className="contacnt_div">
        {lang == "/Turkish" ? (
          <>
            <div className="sub_title_heading">
              <h6>2 Ocak 2025 tarihinde güncellendi</h6>
            </div>
            <div className="text_div">
              <h1>1. Giriş</h1>
              <p>
                TürkAfrica.Market'ta, kullanıcılarımızın ve verilerinin
                güvenliğini sağlamak en üst önceliğimizdir. Tüm B2B ve B2C
                etkileşimlerimiz için güvenli ve güvenilir bir ortam sağlamaya
                kendimizi adamış durumdayız.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Ürün Güvenliği</h1>
              <p>
                Kalite Güvencesi: Tüm satıcıları ve ürünleri, yüksek kalite
                standartlarını karşıladıklarından emin olmak için titizlikle
                denetliyoruz. <br />
                Yönetmeliklere Uyum: Platformumuzdaki tüm ürünler, ilgili
                İngiltere ve uluslararası güvenlik yönetmeliklerine uygundur.{" "}
                <br />
                Kullanıcı Raporlama Sistemi: Kullanıcılar, ürünle ilgili
                güvenlik endişelerini hemen gözden geçirmek üzere
                bildirebilirler.
              </p>
            </div>
            <div className="text_div">
              <h1>3. İşlem Güvenliği</h1>
              <p>
                Güvenli Ödeme İşleme: Finansal işlemlerin güvenliğini sağlamak
                için şifreli ödeme kapılarını kullanıyoruz. <br />
                Sahtekarlık Önleme: Platformumuz, sahtekarlık faaliyetlerini
                tespit etmek ve önlemek için gelişmiş algoritmalar kullanır.{" "}
                <br />
                Kullanıcı Doğrulama: İşletmeler ve tüketiciler için doğrulama
                süreçlerini uygulayarak işlem bütünlüğünü artırıyoruz.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Veri Güvenliği</h1>
              <p>
                Veri Koruma: Kişisel ve iş verileri, endüstri standardı
                şifreleme ve güvenlik protokolleri kullanılarak korunmaktadır.{" "}
                <br />
                Düzenli Güvenlik Denetimleri: Potansiyel zafiyetleri belirleyip
                düzeltmek için platformumuz düzenli güvenlik denetimlerinden
                geçmektedir. <br />
                UKullanıcı Kontrolü: Kullanıcılar, kişisel verileri üzerinde,
                İngiltere GDPR'ına uygun olarak erişim ve silme haklarına
                sahiptirler.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Kullanıcı Eğitimi</h1>
              <p>
                Güvenlik Yönergeleri: Kullanıcılara, platformumuzda işlem
                yapmanın ve etkileşimde bulunmanın güvenli yolları hakkında
                yönergeler sağlıyoruz. <br />
                Farkındalık Kampanyaları: Kullanıcıları güvenlik en iyi
                uygulamaları ve olası dolandırıcılıklar hakkında bilgilendirmek
                için düzenli farkındalık kampanyaları yürütülmektedir.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Acil Durum Yanıtı</h1>
              <p>
                Olay Yanıt Ekibi: Güvenlik olaylarına ve acil durumlara hızlı
                bir şekilde yanıt vermek için adanmış bir ekibimiz var. <br />
                Kullanıcı Desteği: Kullanıcılar, herhangi bir güvenlik endişesi
                veya olayı bildirmek için 7/24 destek alabilirler.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Sürekli İyileştirme</h1>
              <p>
                Geribildirim Mekanizması: Güvenlik ve emniyet tedbirlerimizin
                sürekli iyileştirilmesi için kullanıcı geri bildirimleri hayati
                önem taşır. <br />
                Tehditlere Uyum: Siber tehditlere ve endüstri standartlarına
                yanıt olarak güvenlik önlemlerimizi düzenli olarak
                güncelliyoruz.
              </p>
            </div>
            <div className="text_div">
              <h1>8. İletişim ve Raporlama</h1>
              <p>
                İletişim Bilgileri: Herhangi bir güvenlik endişesi için
                kullanıcılar, açılış sayfasındaki 'İletişim' bölümünden bize
                ulaşabilirler. <br /> Anonim raporlama: Açık iletişimi teşvik
                etmek için güvenlik veya güvenlik endişelerinin anonim olarak
                raporlanması için bir seçenek sunuyoruz.
              </p>
            </div>
          </>
        ) : lang == "/French" ? (
          <>
            <div className="sub_title_heading">
              <h6> Mis à jour le 2 janvier 2025</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                Chez TürkAfrica.Market, assurer la sécurité de nos utilisateurs
                et de leurs données est une priorité absolue. Nous nous
                engageons à fournir un environnement sûr et fiable pour toutes
                nos interactions B2B et B2C.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Sécurité des Produits</h1>
              <p>
                Assurance Qualité : Nous évaluons rigoureusement tous les
                vendeurs et produits pour garantir qu'ils répondent à des normes
                de haute qualité. <br />
                Conformité aux Réglementations : Tous les produits sur notre
                plateforme sont conformes aux réglementations de sécurité
                applicables au Royaume-Uni et à l'international. <br />
                Système de Signalement par les Utilisateurs : Les utilisateurs
                peuvent signaler toute préoccupation liée à la sécurité des
                produits pour un examen immédiat.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Sécurité Transactionnelle</h1>
              <p>
                Traitement Sécurisé des Paiements : Nous utilisons des
                passerelles de paiement cryptées pour assurer la sécurité des
                transactions financières. <br />
                Prévention de la Fraude : Notre plateforme emploie des
                algorithmes avancés pour détecter et prévenir les activités
                frauduleuses. <br />
                Vérification des Utilisateurs : Nous mettons en œuvre des
                processus de vérification pour les entreprises et les
                consommateurs afin d'améliorer l'intégrité transactionnelle.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Sécurité des Données</h1>
              <p>
                Protection des Données : Les données personnelles et
                professionnelles sont protégées en utilisant un cryptage et des
                protocoles de sécurité standard de l'industrie. <br />
                Audits de Sécurité Réguliers : Notre plateforme subit des audits
                de sécurité réguliers pour identifier et corriger les
                vulnérabilités potentielles. <br />
                Contrôle par l'Utilisateur : Les utilisateurs ont le contrôle de
                leurs données personnelles, y compris les droits d'accès et de
                suppression conformément au RGPD du Royaume-Uni.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Éducation des Utilisateurs</h1>
              <p>
                Directives de Sécurité : Nous fournissons aux utilisateurs des
                directives sur la manière de réaliser des transactions en toute
                sécurité et d'interagir sur notre plateforme.
                <br />
                Awareness Campaigns: Regular awareness campaigns are conducted
                to educate users about security best practices and potential
                scams.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Réponse d'Urgence</h1>
              <p>
                Équipe d'Intervention en Cas d'Incident : Nous disposons d'une
                équipe dédiée pour répondre rapidement aux incidents de sécurité
                et aux urgences. <br />
                Support Utilisateur : Un support est disponible 24/7 pour que
                les utilisateurs signalent toute préoccupation ou incident de
                sécurité.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Amélioration Continue</h1>
              <p>
                Mécanisme de Retour d'Information : Les retours des utilisateurs
                sont essentiels pour l'amélioration continue de nos mesures de
                sécurité et de protection. <br />
                Adaptation aux Menaces : Nous mettons régulièrement à jour nos
                mesures de sécurité en réponse aux menaces cybernétiques
                évolutives et aux normes de l'industrie.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Contact et Signalement</h1>
              <p>
                Informations de contact : Pour toute préoccupation en matière de
                sécurité, les utilisateurs peuvent nous contacter via la section
                "Contact" sur la page d'accueil. <br /> Rapport anonyme : Nous
                offrons une option de rapport anonyme pour les préoccupations de
                sécurité afin d'encourager une communication ouverte.
              </p>
            </div>
          </>
        ) : lang == "/Portuguese" ? (
          <>
            <div className="sub_title_heading">
              <h6>Atualizado em 2 de janeiro de 2025</h6>
            </div>
            <div className="text_div">
              <h1>1. Introdução</h1>
              <p>
                Na TürkAfrica.Market, garantir a segurança dos nossos usuários e
                de seus dados é uma prioridade máxima. Estamos comprometidos em
                fornecer um ambiente seguro e confiável para todas as nossas
                interações B2B e B2C.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Segurança do Produto</h1>
              <p>
                Garantia de Qualidade: Avaliamos rigorosamente todos os
                fornecedores e produtos para garantir que eles atendam aos altos
                padrões de qualidade <br />
                Conformidade com Regulamentos: Todos os produtos em nossa
                plataforma cumprem com as regulamentações de segurança
                aplicáveis do Reino Unido e internacionais. <br />
                Sistema de Relatórios do Usuário: Os usuários podem reportar
                qualquer preocupação relacionada à segurança do produto para
                análise imediata.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Segurança nas Transações</h1>
              <p>
                Processamento de Pagamento Seguro: Utilizamos gateways de
                pagamento criptografados para garantir a segurança das
                transações financeiras. <br />
                Prevenção de Fraudes: Nossa plataforma emprega algoritmos
                avançados para detectar e prevenir atividades fraudulentas.{" "}
                <br />
                Verificação de Usuário: Implementamos processos de verificação
                para empresas e consumidores para aumentar a integridade das
                transações.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Segurança dos Dados</h1>
              <p>
                Proteção de Dados: Dados pessoais e empresariais são protegidos
                usando criptografia padrão da indústria e protocolos de
                segurança. <br />
                Auditorias de Segurança Regulares: Nossa plataforma passa por
                auditorias de segurança regulares para identificar e corrigir
                possíveis vulnerabilidades. <br />
                Controle do Usuário: Os usuários têm controle sobre seus dados
                pessoais, incluindo direitos de acesso e exclusão em
                conformidade com o GDPR do Reino Unido.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Educação do Usuário</h1>
              <p>
                Diretrizes de Segurança: Fornecemos aos usuários diretrizes
                sobre como realizar transações com segurança e interagir em
                nossa plataforma. <br />
                Campanhas de Conscientização: Realizamos campanhas regulares de
                conscientização para educar os usuários sobre as melhores
                práticas de segurança e possíveis golpes.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Resposta a Emergências</h1>
              <p>
                Equipe de Resposta a Incidentes: Temos uma equipe dedicada para
                responder prontamente a incidentes de segurança e emergências.{" "}
                <br />
                Suporte ao Usuário: Suporte 24/7 está disponível para os
                usuários relatarem quaisquer preocupações ou incidentes de
                segurança.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Melhoria Contínua</h1>
              <p>
                Mecanismo de Feedback: O feedback do usuário é vital para a
                melhoria contínua de nossas medidas de segurança e proteção.{" "}
                <br />
                Adaptação a Ameaças: Atualizamos regularmente nossas medidas de
                segurança em resposta a ameaças cibernéticas em evolução e
                padrões da indústria.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Contato e Denúncias</h1>
              <p>
                Informações de Contato: Para quaisquer preocupações com
                segurança, os usuários podem nos contatar através da seção
                "Contato" na página de destino. <br /> Relato Anônimo:
                Oferecemos uma opção para relato anônimo de preocupações com
                segurança ou segurança para encorajar a comunicação aberta.
              </p>
            </div>
          </>
        ) : lang == "/Swahili" ? (
          <>
            <div className="sub_title_heading">
              <h6>Imesasishwa tarehe 2 Januari 2025</h6>
            </div>
            <div className="text_div">
              <h1>1. Utangulizi</h1>
              <p>
                Katika TürkAfrica.Market, kuhakikisha usalama na ulinzi wa
                watumiaji wetu na data zao ni kipaumbele cha juu. Tunajitolea
                kutoa mazingira salama na ya kuaminika kwa ajili ya mwingiliano
                wetu wote wa B2B na B2C.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Usalama wa Bidhaa</h1>
              <p>
                Uhakikisho wa Ubora: Tunachunguza kwa makini wauzaji na bidhaa
                zote kuhakikisha zinakidhi viwango vya juu vya ubora. <br />
                Kufuata Kanuni: Bidhaa zote kwenye jukwaa letu zinazingatia
                kanuni za usalama za Uingereza na kimataifa.
                <br />
                Mfumo wa Kuripoti wa Watumiaji: Watumiaji wanaweza kuripoti
                wasiwasi wowote kuhusiana na usalama wa bidhaa kwa ukaguzi wa
                haraka.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Usalama wa Miamala</h1>
              <p>
                Uchakataji Salama wa Malipo: Tunatumia malango ya malipo
                yaliyosimbwa ili kuhakikisha usalama wa miamala ya kifedha.{" "}
                <br />
                Kuzuia Udanganyifu: Jukwaa letu linatumia algoriti za kisasa
                kutambua na kuzuia shughuli za udanganyifu. <br /> Uhakiki wa
                Watumiaji: Tunatekeleza mchakato wa uhakiki kwa biashara na
                watumiaji ili kuongeza uadilifu wa miamala.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Usalama wa Data</h1>
              <p>
                Ulinzi wa Data: Data binafsi na ya biashara inalindwa kwa
                kutumia usimbaji fiche na itifaki za usalama za kiwango cha
                sekta. <br />
                Ukaguzi wa Usalama wa Mara kwa Mara: Jukwaa letu linapitia
                ukaguzi wa usalama mara kwa mara ili kutambua na kurekebisha
                udhaifu wowote unaoweza kutokea. <br />
                Udhibiti wa Mtumiaji: Watumiaji wana udhibiti juu ya data yao
                binafsi, ikiwa ni pamoja na haki za ufikiaji na kufuta kulingana
                na GDPR ya Uingereza.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Elimu kwa Mtumiaji</h1>
              <p>
                Miongozo ya Usalama: Tunatoa miongozo kwa watumiaji jinsi ya
                kufanya miamala kwa usalama na kuingiliana kwenye jukwaa letu.{" "}
                <br />
                Kampeni za Ufahamu: Kampeni za mara kwa mara za ufahamu
                zinafanyika ili kuelimisha watumiaji kuhusu mazoea bora ya
                usalama na utapeli unaowezekana.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Majibu ya Dharura</h1>
              <p>
                Timu ya Majibu ya Matukio: Tuna timu iliyojitolea kujibu matukio
                ya usalama na dharura kwa haraka. <br /> Usaidizi kwa Mtumiaji:
                Usaidizi wa saa 24/7 unapatikana kwa watumiaji kuripoti wasiwasi
                wowote wa usalama au matukio.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Kuboresha kwa Kuendelea</h1>
              <p>
                Mfumo wa Maoni: Maoni ya watumiaji ni muhimu kwa kuboresha
                endelevu ya hatua zetu za usalama na ulinzi. <br /> Kukabiliana
                na Vitisho: Tunasasisha mara kwa mara hatua zetu za usalama
                kujibu vitisho vinavyobadilika vya mtandao na viwango vya sekta.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Mawasiliano na Kuripoti</h1>
              <p>
                Taarifa za Mawasiliano: Kwa wasiwasi wowote wa usalama,
                watumiaji wanaweza kuwasiliana nasi kupitia sehemu ya
                "Mawasiliano" kwenye ukurasa wa kutua. <br /> Ripoti Bila Kutaja
                Jina: Tunatoa chaguo la kuripoti wasiwasi wa usalama au usalama
                kwa njia ya kutojulikana ili kuhamasisha mawasiliano wazi.
              </p>
            </div>
          </>
        ) : lang == "/Spanish" ? (
          <>
            <div className="sub_title_heading">
              <h6>Actualizado el 2 de enero de 2025</h6>
            </div>
            <div className="text_div">
              <h1>1. Introducción</h1>
              <p>
                En TürkAfrica.Market, garantizar la seguridad y protección de
                nuestros usuarios y sus datos es una prioridad máxima. Estamos
                comprometidos a proporcionar un entorno seguro y confiable para
                todas nuestras interacciones B2B y B2C.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Seguridad del Producto</h1>
              <p>
                Aseguramiento de la Calidad: Evaluamos rigurosamente a todos los
                proveedores y productos para asegurar que cumplan con altos
                estándares de calidad. <br />
                Cumplimiento con Regulaciones: Todos los productos en nuestra
                plataforma cumplen con las regulaciones de seguridad aplicables
                del Reino Unido e internacionales. <br />
                Sistema de Reporte de Usuarios: Los usuarios pueden reportar
                cualquier preocupación relacionada con la seguridad del producto
                para una revisión inmediata.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Seguridad Transaccional</h1>
              <p>
                Procesamiento de Pagos Seguro: Utilizamos pasarelas de pago
                encriptadas para garantizar la seguridad de las transacciones
                financieras. <br />
                Prevención de Fraudes: Nuestra plataforma emplea algoritmos
                avanzados para detectar y prevenir actividades fraudulentas.{" "}
                <br />
                Verificación de Usuarios: Implementamos procesos de verificación
                para empresas y consumidores para mejorar la integridad
                transaccional.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Seguridad de Datos</h1>
              <p>
                Protección de Datos: Los datos personales y empresariales están
                protegidos utilizando encriptación y protocolos de seguridad
                estándar en la industria. <br />
                Auditorías de Seguridad Regulares: Nuestra plataforma se somete
                a auditorías de seguridad regulares para identificar y
                rectificar vulnerabilidades potenciales. <br />
                Control por Parte del Usuario: Los usuarios tienen control sobre
                sus datos personales, incluyendo derechos de acceso y
                eliminación en línea con el GDPR del Reino Unido.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Educación del Usuario</h1>
              <p>
                Guías de Seguridad: Proporcionamos a los usuarios guías sobre
                cómo realizar transacciones de manera segura e interactuar en
                nuestra plataforma. <br />
                Campañas de Concientización: Se realizan campañas regulares de
                concientización para educar a los usuarios sobre las mejores
                prácticas de seguridad y posibles estafas.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Respuesta de Emergencia</h1>
              <p>
                Equipo de Respuesta a Incidentes: Contamos con un equipo
                dedicado para responder a incidentes de seguridad y emergencias
                de manera rápida <br />
                Soporte al Usuario: Soporte disponible 24/7 para que los
                usuarios reporten cualquier preocupación o incidente de
                seguridad.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Mejora Continua</h1>
              <p>
                Mecanismo de Retroalimentación: La retroalimentación de los
                usuarios es vital para la mejora continua de nuestras medidas de
                seguridad y protección. <br />
                Adaptación a Amenazas: Actualizamos regularmente nuestras
                medidas de seguridad en respuesta a amenazas cibernéticas en
                evolución y estándares de la industria.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Contacto y Reporte</h1>
              <p>
                Información de contacto: Para cualquier preocupación sobre
                seguridad, los usuarios pueden contactarnos a través de la
                sección "Contacto" en la página de aterrizaje. <br /> Reporte
                anónimo: Proporcionamos una opción para la denuncia anónima de
                preocupaciones de seguridad para fomentar la comunicación
                abierta.
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="sub_title_heading">
              <h6>Updated on January 2nd, 2025</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                At TürkAfrica.Market, ensuring the safety and security of our
                users and their data is a top priority. We are committed to
                providing a secure and trustworthy environment for all our B2B
                and B2C interactions.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Product Safety</h1>
              <p>
                Quality Assurance: We rigorously vet all vendors and products to
                ensure they meet high-quality standards. <br />
                Compliance with Regulations: All products on our platform comply
                with applicable UK and international safety regulations. <br />
                User Reporting System: Users can report any product-related
                safety concerns for immediate review.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Transactional Security</h1>
              <p>
                Secure Payment Processing: We use encrypted payment gateways to
                ensure the security of financial transactions. <br />
                Fraud Prevention: Our platform employs advanced algorithms to
                detect and prevent fraudulent activities. <br />
                User Verification: We implement verification processes for
                businesses and consumers to enhance transactional integrity.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Data Security</h1>
              <p>
                Data Protection: Personal and business data is protected using
                industry-standard encryption and security protocols. <br />
                Regular Security Audits: Our platform undergoes regular security
                audits to identify and rectify potential vulnerabilities. <br />
                User Control: Users have control over their personal data,
                including access and deletion rights in line with the UK GDPR.
              </p>
            </div>
            <div className="text_div">
              <h1>5. User Education</h1>
              <p>
                Safety Guidelines: We provide users with guidelines on how to
                safely conduct transactions and interact on our platform. <br />
                Awareness Campaigns: Regular awareness campaigns are conducted
                to educate users about security best practices and potential
                scams.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Emergency Response</h1>
              <p>
                Incident Response Team: We have a dedicated team to respond to
                security incidents and emergencies promptly. <br />
                User Support: 24/7 support is available for users to report any
                security concerns or incidents.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Continuous Improvement</h1>
              <p>
                Feedback Mechanism: User feedback is vital for continual
                improvement of our safety and security measures. <br />
                Adapting to Threats: We regularly update our security measures
                in response to evolving cyber threats and industry standards.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Contact and Reporting</h1>
              <p>
                Contact Information: For any safety or security concerns, users
                can contact us through the 'Contact' section on the landing
                page.. <br /> Anonymous reporting: We provide an option for
                anonymous reporting of safety or security concerns to encourage
                open communication
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
