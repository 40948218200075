import React from "react";
import "./Acad_1.css";

const Index = () => {
  return (
    <div className="main_Acad_sec1">
      <h1>Welcome To TurkAfrica Business Academy</h1>
      <p>Learn Business. Master Trade. Achieve Success.™</p>
    </div>
  );
};

export default Index;
