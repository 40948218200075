import { Route, Routes } from "react-router-dom";
import "./App.css";
import LandingPage from "./pages/LandingPage/index.jsx";
import Thankyou from "./pages/thankyou/index.jsx";
import LandingpageTA from "./pages/LandingPageTA/index.jsx";
import LandingpageFR from "./pages/LandingPageFR/index.jsx";
import LandingpagePT from "./pages/LandingPagePT/index.jsx";
import LandingpageSP from "./pages/LandingPageSP/index.jsx";
import LandingpageSW from "./pages/LandingPageSW/index.jsx";
import Trems from "./pages/Trems/index.jsx";
import Privacy from "./pages/PrivacyPolicy/index.jsx";
import SafetySecurity from "./pages/Safety&Security/index.jsx";
import Guidelines from "./pages/Guidelines/index.jsx";
import Support from "./pages/Support/index.jsx";
import Blog from "./pages/Blog/index.jsx";
import Academy from "./pages/Academy/index.jsx";
import BlogDetails from "./pages/BlogDetails/index.jsx";
import Academydetails from "./pages/Academydetails/index.jsx";
import ScrollToTop from "./components/ScrollTop.jsx";
import Login from "./pages/Login/index.jsx";
import GlobalContextProvider from "./Globalcontext.jsx";
import { Downloadmodal, LogoutModel } from "./components/UI/Modals/index.jsx";
import useFirebaseToken from "./comman/token/useFirebaseToken.jsx";
import VerificationPolicy from "./pages/Verification-Policy/index.jsx"
function App() {
  const token = useFirebaseToken();
  return (
    <GlobalContextProvider>
      <LogoutModel />
      <Downloadmodal />
      <div className="App">
        <ScrollToTop />
        <Routes>
          <Route path="/Login" element={<Login />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/Turkish" element={<LandingpageTA />} />
          <Route path="/French" element={<LandingpageFR />} />
          <Route path="/Portuguese" element={<LandingpagePT />} />
          <Route path="/Spanish" element={<LandingpageSP />} />
          <Route path="/Swahili" element={<LandingpageSW />} />
          <Route path="/Thankyou" element={<Thankyou />} />
          <Route path="/TermsofUse" element={<Trems />} />
          <Route path="/PrivacyPolicyandCookies" element={<Privacy />} />
          <Route path="/SafetySecurity" element={<SafetySecurity />} />
          <Route path="/VerificationPolicy" element={<VerificationPolicy />} />
          <Route path="/Guidelines" element={<Guidelines />} />
          <Route path="/Support" element={<Support />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/blogdetails/:blogId" element={<BlogDetails />} />
          <Route path="/Academy" element={<Academy />} />
          <Route path="/Academydetails/:blogId" element={<Academydetails />} />
        </Routes>
      </div>
    </GlobalContextProvider>
  );
}
// <span>&#174;</span>

export default App;
