import React from "react";
import "./Blog_main.css";
import Blog1 from "./Blog_1/index";
import Blog2 from "./Blog_2/index";
import Navbar from "../../components/UI/Navbar/navbar";
import Footer from "../../components/UI/Footer";

const Index = () =>
{
  return (
    <div style={{ width: "100%" }}>
      <Navbar />
      <Blog1 />
      <Blog2 />
      <Footer />
    </div>
  );
};

export default Index;
